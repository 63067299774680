<template>
  <div class="mall">
    <div v-for="(item, index) of mallList"
         @click="notOpen(item)"
         :key="index"
         class="mall_li">
      <img class="li_img"
           :src="item.img" />
      <!-- <span class="good">{{$t(item.good) }}</span>
      <span>{{ $t(item.text) }}</span> -->
    </div>
    <div class="more_game">
      <img src="@/assets/img/more_game.png" alt="">
      <span>{{ $t('lang.swap400') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mallList: [
        { img: require('@/assets/img/hash_game.png'), good: 'lang.swap387', text: 'lang.swap389', router: 'https://pro.good-swap.pro',type:'hash' },
       
      ],
    };
  },
  methods: {
    notOpen (item) {
       if(item.type =='hash'){
        window.location.href = item.router;

      }
    },
  },
};
</script>

<style lang="less" scoped>
.more_game{
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img{
    width:70px;
// height: 40px;
  }
  span{
    font-size: 16px;
font-weight: 400;
letter-spacing: 0px;
line-height: 72px;
color: rgba(153, 153, 153, 1);
  }
}
.mall {
background: #fff;
min-height: 100vh;
  padding: 20px 16px;
  .mall_li {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #666666;
    border-radius: 20px;
    border: 2px solid #2893e582;
    background: #ffffffff;
    padding: 20px 10px;
    margin-bottom: 20px;
    .li_img {
      width: 100%;
      margin-bottom: 16px;
    }
    .good {
      font-size: 20px;
      font-weight: bold;
      color: #222222;
      margin-bottom: 6px;
    }
  }
}
</style>