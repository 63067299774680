import request from '../utils/request';

export default {
    //获取钱包信息
    communityWallet(address) {
        return request.request({
            url: '/community/wallet/info',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    },

    //标记入账
    communityEntryMark(data,address){
        return request.request({
            url: '/community/entry-mark',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data:data
        })
    }
}