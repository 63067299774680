import phoLpAbi from '../config/abi/gdtLp.json'
import Web3 from 'web3'
import store from '../store';
//存入
function stake(myAddress, amount, transactionsDetail) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x2B593e1719Afae2D7fd43aDFC25FAa0460114BF5');
        crossingOkContractInstance.methods.deposit(0,amount).send({ from: myAddress })
            .on('transactionHash', res => {
                transactionsDetail['hash'] = res
                store.commit('SETTRANSACTIONS', transactionsDetail);
            }).on('confirmation', res => {
                resolve(res)
            }).on('error', res => {
                reject(res)
            })
    })
}


//取出
function withdraw(myaddress, amount, transactionsDetail) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x2B593e1719Afae2D7fd43aDFC25FAa0460114BF5');
        crossingOkContractInstance.methods.withdraw(0,amount).send({ from: myaddress }).on('transactionHash', res => {
            transactionsDetail['hash'] = res
            store.commit('SETTRANSACTIONS', transactionsDetail);
        }).on('confirmation', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}

//奖励数量
function earned(myAddress) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x2B593e1719Afae2D7fd43aDFC25FAa0460114BF5');
        crossingOkContractInstance.methods.pendingSwan(0,myAddress).call().then(res => {
            resolve(res)
        })
    })
}

//领取奖励
function claimReward(myAddress,transactionsDetail) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x2B593e1719Afae2D7fd43aDFC25FAa0460114BF5');
        crossingOkContractInstance.methods.harvest(0).send({ from: myAddress }).on('transactionHash', res => {
            transactionsDetail['hash'] = res
            store.commit('SETTRANSACTIONS', transactionsDetail);
        }).on('confirmation', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}

//锁仓到期时间戳
function lockData(myAddress) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x2B593e1719Afae2D7fd43aDFC25FAa0460114BF5');
        crossingOkContractInstance.methods.lockData(myAddress).call().then(res => {
            resolve(res)
        })
    })
}

//获取当前质押数量
function balance(myaddress){
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x2B593e1719Afae2D7fd43aDFC25FAa0460114BF5');
        crossingOkContractInstance.methods.userInfo(0,myaddress).call().then(res => {
            resolve(res.amount)
        })
    })
}
//存入
  function  stakePOR  (myAddress, amount, transactionsDetail)  {
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x94ec9B11ECb2188520b4Ae84d9a4F775288a58af');
        const gas = await crossingOkContractInstance.methods.deposit(0,amount).estimateGas({from:myAddress})
        crossingOkContractInstance.methods.deposit(0,amount).send({ from: myAddress,gas,  gasPrice:parseInt(store.state.gasPrice) })
            .on('transactionHash', res => {
                transactionsDetail['hash'] = res
                store.commit('SETTRANSACTIONS', transactionsDetail);
            }).on('confirmation', res => {
                resolve(res)
            }).on('error', res => {
                reject(res)
            })
    })
}


//取出
function withdrawPOR(myaddress, amount, transactionsDetail) {
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x94ec9B11ECb2188520b4Ae84d9a4F775288a58af');
        const gas =  await crossingOkContractInstance.methods.withdraw(0,amount).estimateGas({from:myaddress})
        crossingOkContractInstance.methods.withdraw(0,amount).send({ from: myaddress,gas,  gasPrice:parseInt(store.state.gasPrice) })
        .on('transactionHash', res => {
            transactionsDetail['hash'] = res
            store.commit('SETTRANSACTIONS', transactionsDetail);
        }).on('confirmation', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}

//奖励数量
function earnedPOR(myAddress) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x94ec9B11ECb2188520b4Ae84d9a4F775288a58af');
        crossingOkContractInstance.methods.pendingSwan(0,myAddress).call().then(res => {
            resolve(res)
        })
    })
}

//领取奖励
function claimRewardPOR(myAddress,transactionsDetail) {
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x94ec9B11ECb2188520b4Ae84d9a4F775288a58af');
        const gas = await crossingOkContractInstance.methods.harvest(0).estimateGas({from:myAddress})
        crossingOkContractInstance.methods.harvest(0).send({ from: myAddress,gas,  gasPrice:parseInt(store.state.gasPrice) })
        .on('transactionHash', res => {
            transactionsDetail['hash'] = res
            store.commit('SETTRANSACTIONS', transactionsDetail);
        }).on('confirmation', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}

//锁仓到期时间戳
function lockDataPOR(myAddress) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x94ec9B11ECb2188520b4Ae84d9a4F775288a58af');
        crossingOkContractInstance.methods.lockData(myAddress).call().then(res => {
            resolve(res)
        })
    })
}

//获取当前质押数量
function balancePOR(myaddress){
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x94ec9B11ECb2188520b4Ae84d9a4F775288a58af');
        crossingOkContractInstance.methods.userInfo(0,myaddress).call().then(res => {
            resolve(res.amount)
        })
    })
}
export default {
    stake,
    withdraw,
    earned,
    claimReward,
    lockData,
    balance,
    stakePOR,
    withdrawPOR,
    earnedPOR,
    claimRewardPOR,
    lockDataPOR,
    balancePOR
}