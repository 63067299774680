<template>
  <div style="padding: 0 16px;">
    <!-- <div class="choice">
      <div v-for="(item, index) of choiceList"
           :key="index"
           @click="choiceSwitch(index)"
           :class="choiceIndex == index ? 'special' : ''">
        <span>{{ $t(item) }}</span>
      </div>
    </div> -->
    <!-- 进行中 -->

    <div >
      <!-- VGT -->
      <div class="mining">
        <div class="mining_head">
          <div class="head_left">
            <img class="head_img"
                 src="@/assets/img/logo.jpg" />
            <img class="special_img"
                 src="@/assets/img/VGT.png" />
            <span style="margin-left: 10px">VGT-PHO</span>
          </div>
          <div class="head_right2">
            <img src="@/assets/tokenlogo/polygon.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap142') }}</span>
              </div>
              <div>
                <span>{{ vgtData.profit }}</span>
              </div>
            </div>
            <div class="button">
              <van-button type="primary"
                          class="confirmBtn"
                          @click="vgtHarvest">
                <span>{{ $t('lang.swap66') }}</span>
              </van-button>
            </div>
          </div>
          <div class="earned"
               v-if="!vgtData.allowanceToRouter">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap143') }}</span>
              </div>
              <div>
                <span>{{ vgtData.myNumber }}</span>
              </div>
            </div>
            <div class="button">
              <img class="button_img"
                   src="@/assets/img/reduce.png"
                   @click="selectVgt(false)" />

              <img class="button_img"
                   src="@/assets/img/plus.png"
                   @click="selectVgt(true)" />
            </div>
          </div>

          <!-- 授权 -->
          <div class="staked"
               v-else>
            <div class="staked_title">
              <span>{{ $t('lang.swap143') }}</span>
            </div>
            <div>
              <van-button type="primary"
                          class="confirmBtn"
                          loading-text="Waiting..."
                          @click="approveVgt"
                          :loading="vgtData.showApprove">
                <span>{{ $t('lang.swap14') }}</span>
              </van-button>
            </div>
          </div>
        </div>
      </div>
      <!-- QJF -->
      <div class="mining">
        <div class="mining_head">
          <div class="head_left">
            <img class="head_img"
                 src="@/assets/img/logo.jpg" />
            <img class="special_img"
                 src="@/assets/tokenlogo/QJF.jpg" />
            <span style="margin-left: 10px">QJF-PHO</span>
          </div>
          <div class="head_right2">
            <img src="@/assets/tokenlogo/polygon.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap142') }}</span>
              </div>
              <div>
                <span>{{ qjfData.profit }}</span>
              </div>
            </div>
            <div class="button">
              <van-button type="primary"
                          class="confirmBtn"
                          @click="qjfHarvest">
                <span>{{ $t('lang.swap66') }}</span>
              </van-button>
            </div>
          </div>
          <div class="earned"
               v-if="!qjfData.allowanceToRouter">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap143') }}</span>
              </div>
              <div>
                <span>{{ qjfData.myNumber }}</span>
              </div>
            </div>
            <div class="button">
              <img class="button_img"
                   src="@/assets/img/reduce.png"
                   @click="selectqjf(false)" />

              <img class="button_img"
                   src="@/assets/img/plus.png"
                   @click="selectqjf(true)" />
            </div>
          </div>

          <!-- 授权 -->
          <div class="staked"
               v-else>
            <div class="staked_title">
              <span>{{ $t('lang.swap143') }}</span>
            </div>
            <div>
              <van-button type="primary"
                          class="confirmBtn"
                          loading-text="Waiting..."
                          @click="approveqjf"
                          :loading="qjfData.showApprove">
                <span>{{ $t('lang.swap14') }}</span>
              </van-button>
            </div>
          </div>
        </div>
      </div>
 <!-- GET -->
 <div class="mining">
        <div class="mining_head">
          <div class="head_left">
            <img class="head_img"
                 src="@/assets/img/logo.jpg" />
            <img class="special_img"
                 src="@/assets/img/GET.png" />
            <span style="margin-left: 10px">GET-PHO</span>
          </div>
          <div class="head_right2">
            <img src="@/assets/tokenlogo/polygon.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap142') }}</span>
              </div>
              <div>
                <span>{{ getData.profit }}</span>
              </div>
            </div>
            <div class="button">
              <van-button type="primary"
                          class="confirmBtn"
                          @click="getHarvest">
                <span>{{ $t('lang.swap66') }}</span>
              </van-button>
            </div>
          </div>
          <div class="earned"
               v-if="!getData.allowanceToRouter">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap143') }}</span>
              </div>
              <div>
                <span>{{ getData.myNumber }}</span>
              </div>
            </div>
            <div class="button">
              <img class="button_img"
                   src="@/assets/img/reduce.png"
                   @click="selectget(false)" />

              <img class="button_img"
                   src="@/assets/img/plus.png"
                   @click="selectget(true)" />
            </div>
          </div>

          <!-- 授权 -->
          <div class="staked"
               v-else>
            <div class="staked_title">
              <span>{{ $t('lang.swap143') }}</span>
            </div>
            <div>
              <van-button type="primary"
                          class="confirmBtn"
                          loading-text="Waiting..."
                          @click="approveget"
                          :loading="getData.showApprove">
                <span>{{ $t('lang.swap14') }}</span>
              </van-button>
            </div>
          </div>
        </div>
      </div>

      <!-- GDT -->
      <div class="mining">
        <div class="mining_head">
          <div class="head_left">
            <img class="head_img"
                 src="@/assets/img/logo.jpg" />
            <img class="special_img"
                 src="@/assets/img/GDT.png" />
            <span style="margin-left: 10px">GDT-PHO</span>
          </div>
          <div class="head_right2">
            <img src="@/assets/tokenlogo/polygon.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap142') }}</span>
              </div>
              <div>
                <span>{{ gdtData.profit }}</span>
              </div>
            </div>
            <div class="button">
              <van-button type="primary"
                          class="confirmBtn"
                          @click="gdtHarvest">
                <span>{{ $t('lang.swap66') }}</span>
              </van-button>
            </div>
          </div>
          <div class="earned"
               v-if="!gdtData.allowanceToRouter">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap143') }}</span>
              </div>
              <div>
                <span>{{ gdtData.myNumber }}</span>
              </div>
            </div>
            <div class="button">
              <img class="button_img"
                   src="@/assets/img/reduce.png"
                   @click="selectGdt(false)" />

              <img class="button_img"
                   src="@/assets/img/plus.png"
                   @click="selectGdt(true)" />
            </div>
          </div>

          <!-- 授权 -->
          <div class="staked"
               v-else>
            <div class="staked_title">
              <span>{{ $t('lang.swap143') }}</span>
            </div>
            <div>
              <van-button type="primary"
                          class="confirmBtn"
                          loading-text="Waiting..."
                          @click="approveGdt"
                          :loading="gdtData.showApprove">
                <span>{{ $t('lang.swap14') }}</span>
              </van-button>
            </div>
          </div>
        </div>
      </div>
      <!-- PHO-USDT -->
      <div class="mining"
           v-for="(item, index) of MiningData"
           :key="index">
        <div class="mining_head">
          <div class="head_left">
            <img class="head_img"
                 src="@/assets/img/logo.jpg" />
            <img class="special_img"
                 src="@/assets/img/usdt-new.png" />
            <span>PHO-USDT</span>
          </div>
          <div class="head_right2">
            <img src="@/assets/tokenlogo/polygon.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap142') }}</span>
              </div>
              <div>
                <span>{{ significantDigits((item.profit * 1).toFixed(6)) }}</span>
              </div>
            </div>
            <div class="button">
              <van-button type="primary"
                          class="confirmBtn"
                          @click="harvest(index)">
                <span>{{ $t('lang.swap66') }}</span>
              </van-button>
            </div>
          </div>
          <div class="earned"
               v-if="!item.allowanceToRouter">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap143') }}</span>
              </div>
              <div>
                <span>{{ significantDigits((item.myNumber * 1).toFixed(6)) }}</span>
              </div>
            </div>
            <div class="button">
              <img class="button_img"
                   src="@/assets/img/reduce.png"
                   @click="selectV2(false)" />

              <img class="button_img"
                   src="@/assets/img/plus.png"
                   @click="selectV2(true)" />
            </div>
          </div>

          <!-- 授权 -->
          <div class="staked"
               v-else>
            <div class="staked_title">
              <span>{{ $t('lang.swap143') }}</span>
            </div>
            <div>
              <van-button type="primary"
                          class="confirmBtn"
                          @click="confirmRemovalV2"
                          loading-text="Waiting..."
                          :loading="item.showApprove">
                <span>{{ $t('lang.swap14') }}</span>
              </van-button>
            </div>
            <!-- <span>当前质押{{MiningData.myNumber / Math.pow(10,18)}}</span> -->
          </div>

          <!-- <div class="tvl">
            <div>
              <span>{{ $t('lang.swap144') }}</span>
            </div>
            <div class="tvl_value">
              <span>{{ item.userTvl }}</span>
              <img class="money_img"
                   src="@/assets/img/money.png" />
            </div>
          </div>
          <div class="tvl">
            <div>
              <span>{{ $t('lang.swap220') }}</span>
            </div>
            <div class="tvl_value">
              <span>{{ item.OtherTVL }}</span>
              <img class="money_img"
                   src="@/assets/img/money.png" />
            </div>
          </div>
          <div class="tvl">
            <div>
              <span>{{ $t('lang.swap221') }}</span>
            </div>
            <div class="tvl_value">
              <span>{{ item.maxTVL }}</span>
              <img class="money_img"
                   src="@/assets/img/money.png" />
            </div>
          </div>
          <div class="tvl">
            <div>
              <span>{{ $t('lang.swap233') }}</span>
            </div>
            <div class="tvl_value">
              <span>{{ item.power }}</span>
            </div>
          </div> -->

          <div class="specific_data">
            <div class="specific">
              <span>{{ $t('lang.swap147') }}</span>
              <div>
                <span class="special">{{ ((item.allReward * 1) / Math.pow(10, 18)).toFixed(6) }}</span>
                <span class="company">PHO</span>
              </div>
            </div>
            <div class="specific">
              <span>{{ $t('lang.swap148') }}</span>
              <div>
                <span class="special">{{ ((item.todayReward * 1) / Math.pow(10, 18)).toFixed(6) }}</span>
                <span class="company">PHO</span>
              </div>
            </div>
            <!-- <div class="specific">
              <span>{{ $t('lang.swap149') }}</span>
              <div>
                <span class="special">{{item.pledge_num}}</span>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <!-- PHO NEW -->
      <!-- <div class="mining">
        <div class="mining_head">
          <div class="head_left">
            <img class="head_img"
                 src="@/assets/img/logo.jpg" />
            <span style="margin-left: 10px">PHO</span>
            <div style="color: red; font-size: 12px;margin-left: 10px;">NEW</div>
          </div>
          <div class="head_right2">
            <img src="@/assets/tokenlogo/polygon.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap142') }}</span>
              </div>
              <div>
                <span>{{ phoDataNew.profit }}</span>
              </div>
            </div>
            <div class="button">
              <van-button type="primary"
                          class="confirmBtn"
                          @click="phoHarvestNew">
                <span>{{ $t('lang.swap66') }}</span>
              </van-button>
            </div>
          </div>
          <div class="earned"
               v-if="!phoDataNew.allowanceToRouter">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap143') }}</span>
              </div>
              <div>
                <span>{{ phoDataNew.myNumber }}</span>
              </div>
            </div>
            <div class="button">
              <img class="button_img"
                   src="@/assets/img/reduce.png"
                   @click="selectPhoNew(false)" />

              <img class="button_img"
                   src="@/assets/img/plus.png"
                   @click="selectPhoNew(true)" />
            </div>
          </div>

        
          <div class="staked"
               v-else>
            <div class="staked_title">
              <span>{{ $t('lang.swap143') }}</span>
            </div>
            <div>
              <van-button type="primary"
                          class="confirmBtn"
                          loading-text="Waiting..."
                          @click="approvePhoNew"
                          :loading="phoDataNew.showApprove">
                <span>{{ $t('lang.swap14') }}</span>
              </van-button>
            </div>
         
          </div>
        </div>

        <div class="down"
             v-if="phoDataNew.myNumber != 0">
          <span>{{ $t('lang.swap226') }}:</span>
          <van-count-down :time="runTime" />
        </div>
      </div> -->
      <!-- PHO Old -->
      <div class="mining">
        <div class="mining_head">
          <div class="head_left">
            <img class="head_img"
                 src="@/assets/img/logo.jpg" />
            <span style="margin-left: 10px">PHO</span>
            <!-- <div style="color: red; font-size: 12px;margin-left: 10px;">Old</div> -->
          </div>
          <div class="head_right2">
            <img src="@/assets/tokenlogo/polygon.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap142') }}</span>
              </div>
              <div>
                <span>{{ phoData.profit }}</span>
              </div>
            </div>
            <div class="button">
              <van-button type="primary"
                          class="confirmBtn"
                          @click="phoHarvest">
                <span>{{ $t('lang.swap66') }}</span>
              </van-button>
            </div>
          </div>
          <div class="earned"
               v-if="!phoData.allowanceToRouter">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap143') }}</span>
              </div>
              <div>
                <span>{{ phoData.myNumber }}</span>
              </div>
            </div>
            <div class="button">
              <img class="button_img"
                   src="@/assets/img/reduce.png"
                   @click="selectPho(false)" />

              <img class="button_img"
                   src="@/assets/img/plus.png"
                   @click="selectPho(true)" />
            </div>
          </div>

          <!-- 授权 -->
          <div class="staked"
               v-else>
            <div class="staked_title">
              <span>{{ $t('lang.swap143') }}</span>
            </div>
            <div>
              <van-button type="primary"
                          class="confirmBtn"
                          loading-text="Waiting..."
                          @click="approvePho"
                          :loading="phoData.showApprove">
                <span>{{ $t('lang.swap14') }}</span>
              </van-button>
            </div>
            <!-- <span>当前质押{{MiningData.myNumber / Math.pow(10,18)}}</span> -->
          </div>
        </div>

        <div class="down"
             v-if="phoData.myNumber != 0">
          <span>{{ $t('lang.swap226') }}:</span>
          <van-count-down :time="runTime" />
        </div>
      </div>
    </div>

    <!-- 已结束 -->
    <div v-show="choiceIndex == '1'">
      <!-- <div class="mining"
           v-for="(item, index) of hashData"
           :key="index">
        <div class="mining_head">
          <div class="head_left">
            <img class="head_img"
                 src="@/assets/img/logo.jpg" />
            <img class="special_img"
                 src="@/assets/img/usdt-new.png" />
            <span>PHO-USDT OLD</span>
          </div>
          <div class="head_right2">
            <img src="@/assets/tokenlogo/polygon.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap142') }}</span>
              </div>
              <div>
                <span>{{ significantDigits((item.profit * 1).toFixed(6)) }}</span>
              </div>
            </div>
            <div class="button">
              <van-button type="primary"
                          class="confirmBtn"
                          @click="harvestOld(index)">
                <span>{{ $t('lang.swap66') }}</span>
              </van-button>
            </div>
          </div>
          <div class="earned"
               v-if="!item.allowanceToRouter">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap143') }}</span>
              </div>
              <div>
                <span>{{ significantDigits((item.myNumber * 1).toFixed(6)) }}</span>
              </div>
            </div>
            <div class="button">
              <img class="button_img"
                   src="@/assets/img/reduce.png"
                   @click="selectOld(false)" />

              <img class="button_img"
                   src="@/assets/img/errplus.png" />

             
            </div>
          </div>

         
          <div class="staked"
               v-else>
            <div class="staked_title">
              <span>{{ $t('lang.swap143') }}</span>
            </div>
            <div>
              <van-button type="primary"
                          class="confirmBtn"
                          @click="confirmRemoval"
                          loading-text="Waiting..."
                          :loading="item.showApprove">
                <span>{{ $t('lang.swap14') }}</span>
              </van-button>
            </div>
          </div>
         
        </div>
      </div> -->
    </div>

    <van-popup v-model="MiningPopupShow"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirm"
                   @getMyAddress="getBalance"
                   :selectShow="selectShow"
                   :max="max"></MiningPopup>
    </van-popup>

    <van-popup v-model="MiningPopupShowTwo"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirmTwo"
                   @getMyAddress="getBalanceTwo"
                   :selectShow="selectShowTwo"
                   :max="max"
                   currencyCharacter="pho"></MiningPopup>
    </van-popup>

    <van-popup v-model="MiningPopupShowThree"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirmThree"
                   @getMyAddress="getBalanceThree"
                   :selectShow="selectShowThree"
                   :max="max"
                   currencyCharacter="GDT-PHO LP"></MiningPopup>
    </van-popup>

    <van-popup v-model="MiningPopupShowFour"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirmFour"
                   @getMyAddress="getBalanceFour"
                   :selectShow="selectShowFour"
                   :max="max"
                   currencyCharacter="VGT-PHO LP"></MiningPopup>
    </van-popup>
    <van-popup v-model="MiningPopupShowFive"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirmFive"
                   @getMyAddress="getBalanceFive"
                   :selectShow="selectShowFive"
                   :max="max"
                   currencyCharacter="QJF-PHO LP"></MiningPopup>
    </van-popup>
    <van-popup v-model="MiningPopupShowSix"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirmSix"
                   @getMyAddress="getBalanceSix"
                   :selectShow="selectShowSix"
                   :max="max"
                   currencyCharacter="GET-PHO LP"></MiningPopup>
    </van-popup>
  </div>
 
</template>


<script>

import MiningPopup from '@/views/index/MiningPopup.vue';
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import lp from '../../methods/lp.js';
import lpOld from '../../methods/lpOld.js';
import phoLp from '../../methods/phoLp.js';
import { coin, market } from '../../api/index';
import { significantDigits, parseAmount, formatAmount } from '@/utils/format.js';
import gdtLp from '../../methods/gdtLp.js';
import vgtLp from '../../methods/vgtLp.js';
import qjfLp from '../../methods/qjfLp.js';
import getLp from '../../methods/getLp.js';

export default {
  data () {
    return {
      initLoading:true,
      MiningData: [
        {
          confirmBtnShow: false, //是否授权
          showApprove: false, //是否授权
          allowanceToRouter: false,
          myNumber: 0,
          profit: 0,
          balance: 0, //
          max: 0,
          allReward: 0,
          todayReward: 0,
          userTvl: 0,
          maxTVL: 0,
          OtherTVL: 0,
          power: 0,
          pledge_num: 0,
        },
      ],

      gdtData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },

      vgtData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
      qjfData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
      getData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
      phoData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
      phoDataNew: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
      hashData: [
        {
          confirmBtnShow: false, //是否授权
          showApprove: false, //是否授权
          allowanceToRouter: false,
          myNumber: 0,
          profit: 0,
          balance: 0, //
          max: 0,
          allReward: 0,
          todayReward: 0,
          userTvl: 0,
          maxTVL: 0,
          OtherTVL: 0,
          power: 0,
          pledge_num: 0,
        },
      ],

      selectShow: false, //true:质押,false:赎回
      selectShowTwo: false, //true:质押,false:赎回
      selectShowThree: false,
      selectShowFour: false,
      selectShowFive:false,
      selectShowSix:false,
      MiningPopupShow: false,
      MiningPopupShowTwo: false,
      MiningPopupShowThree: false,
      MiningPopupShowFour: false,
      MiningPopupShowFive:false,
      MiningPopupShowSix:false,
      max: 0,
      choiceList: ['lang.swap191', 'lang.swap192'],
      choiceIndex: '0',
      runTime: 0,
      runTimeNew:0,
    };
  },
  async mounted(){
    // this.initLoading =false
  },
  async created () {
    
    if (this.account) {
      this.handleApproveBtnShow();
      this.getBalanceOf();
      this.getDeposit();
      this.getReward();
      // this.getpoolReward();
      // this.getlockData();
    }
   
  },

  methods: {
    // 是否需要显示v1 v2授权按钮
    async handleApproveBtnShow () {
      if (this.account) {
        const erc20ContractV2 = getErc20Contract('0xAbFeDadf69F6B692f6C1a0Fcb791866eEBddC10e');
        const toV2 = '0x543F7cBeCf2DBC1CF583e4273c429Cb240aB42E1';
        const allowanceV2 = await erc20ContractV2.methods.allowance(this.account, toV2).call();
        if (allowanceV2 == 0) {
          this.MiningData[0].allowanceToRouter = true;
        } else {
          this.MiningData[0].allowanceToRouter = false;
        }

        //获取当前是否授权
        const phoContract = getErc20Contract('0xB3718900e261Abaa78a00995C5A061731A5eB53C'); //获取Pho币种
        const toMining = '0x4414FD6D09Ad7e4371f87e71cd1094F27fC338bb';
        const appBalance = await phoContract.methods.allowance(this.account, toMining).call();
        if (appBalance == 0) {
          this.phoData.allowanceToRouter = true;
        } else {
          this.phoData.allowanceToRouter = false;
        }

        //获取当前是否授权
        const gdtContract = getErc20Contract('0xA3cD27e3352c7B056A0098361abB5c9F3080944B'); //获取Pho币种
        const gdtToMining = '0x94ec9B11ECb2188520b4Ae84d9a4F775288a58af';
        const gdtAppBalance = await gdtContract.methods.allowance(this.account, gdtToMining).call();
        if (gdtAppBalance == 0) {
          this.gdtData.allowanceToRouter = true;
        } else {
          this.gdtData.allowanceToRouter = false;
        }

        //获取当前是否授权
        const vgtContract = getErc20Contract('0x16089a9c6d3bf5e284c2a9aaa109ae55624dc40a'); //获取Pho币种
        const vgtToMining = '0xD30878F77867281Cd14592893A0Ce8F90b326999';
        const vgtAppBalance = await vgtContract.methods.allowance(this.account, vgtToMining).call();
        if (vgtAppBalance == 0) {
          this.vgtData.allowanceToRouter = true;
        } else {
          this.vgtData.allowanceToRouter = false;
        }

        const qjfContract = getErc20Contract('0x968ea702cdaa3f5d8ee21726c9fd11ebfd6b3e27'); //获取Pho币种
        const qjfToMining = '0x9b09465cf7B9a706034DCbb0c2dD7C09b27ae9ac';
        const qjfAppBalance = await qjfContract.methods.allowance(this.account, qjfToMining).call();
        if (qjfAppBalance == 0) {
          this.qjfData.allowanceToRouter = true;
        } else {
          this.qjfData.allowanceToRouter = false;
        }

        const getContract = getErc20Contract('0x51Afd2309c151b8F27214eBcC306da9DA01D4dC7'); //获取Pho币种
        const getToMining = '0x5CfA602BFBAF1588eB3F60d7bE1b8E5405eE9024';
        const getAppBalance = await getContract.methods.allowance(this.account, getToMining).call();
        if (getAppBalance == 0) {
          this.getData.allowanceToRouter = true;
        } else {
          this.getData.allowanceToRouter = false;
        }

        // //获取老的是否有授权
        // const erc20Contract = getErc20Contract('0x7EBee76221949BF10AA49c3B0B004aAa8e3b6D0d');
        // const to = '0x7D89b39F87B94D6177f2af539fFC4eA3b2Dace86';
        // const allowance = await erc20Contract.methods.allowance(this.account, to).call();
        // if (allowance == 0) {
        //   this.hashData[0].allowanceToRouter = true;
        // } else {
        //   this.hashData[0].allowanceToRouter = false;
        // }
      }
    },

    //获取v1 v2的lp余额
    async getBalanceOf () {
      if (this.account) {
        const erc20ContractV2 = getErc20Contract('0xAbFeDadf69F6B692f6C1a0Fcb791866eEBddC10e');
        const balanceV2 = await erc20ContractV2.methods.balanceOf(this.account).call();
        this.MiningData[0].balance = formatAmount(balanceV2, 18);
        console.log(this.MiningData[0].balance,'this.MiningData[0].balance');
        // //获取老币种余额
        // const erc20Contract = getErc20Contract('0x7EBee76221949BF10AA49c3B0B004aAa8e3b6D0d');
        // const balance = await erc20Contract.methods.balanceOf(this.account).call();
        // this.hashData[0].balance = formatAmount(balance, 18);

        const erc20ContractPho = getErc20Contract('0xB3718900e261Abaa78a00995C5A061731A5eB53C');
        const balancePho = await erc20ContractPho.methods.balanceOf(this.account).call();
        this.phoData.balance = formatAmount(balancePho, 18);

        const erc20ContractGdt = getErc20Contract('0xA3cD27e3352c7B056A0098361abB5c9F3080944B');
        const balanceGdt = await erc20ContractGdt.methods.balanceOf(this.account).call();
        this.gdtData.balance = formatAmount(balanceGdt, 18);

        const erc20ContractVgt = getErc20Contract('0x16089a9c6d3bf5e284c2a9aaa109ae55624dc40a');
        const balanceVgt = await erc20ContractVgt.methods.balanceOf(this.account).call();
        this.vgtData.balance = formatAmount(balanceVgt, 18);

        const erc20Contractqjf = getErc20Contract('0x968ea702cdaa3f5d8ee21726c9fd11ebfd6b3e27');
        const balanceqjf = await erc20Contractqjf.methods.balanceOf(this.account).call();
        this.qjfData.balance = formatAmount(balanceqjf, 18);

        const erc20Contractget = getErc20Contract('0x51Afd2309c151b8F27214eBcC306da9DA01D4dC7');
        const balanceget = await erc20Contractget.methods.balanceOf(this.account).call();
        this.getData.balance = formatAmount(balanceget, 18);
      }
    },

    MiningPopupfirm () {
      this.MiningPopupShow = !this.MiningPopupShow;
    },

    MiningPopupfirmTwo () {
      this.MiningPopupShowTwo = !this.MiningPopupShowTwo;
    },

    MiningPopupfirmThree () {
      this.MiningPopupShowThree = !this.MiningPopupShowThree;
    },

    MiningPopupfirmFour () {
      this.MiningPopupShowFour = !this.MiningPopupShowFour;
    },
    MiningPopupfirmFive () {
      this.MiningPopupShowFive = !this.MiningPopupShowFive;
    },
    MiningPopupfirmSix () {
      this.MiningPopupShowSix = !this.MiningPopupShowSix;
    },

    //授权
    async approvePho () {
      this.phoData.showApprove = true;
      const erc20Contract = useErc20Contract('0xB3718900e261Abaa78a00995C5A061731A5eB53C');
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve('0x4414FD6D09Ad7e4371f87e71cd1094F27fC338bb', amo).estimateGas({from:this.account})
      erc20Contract.methods
        .approve('0x4414FD6D09Ad7e4371f87e71cd1094F27fC338bb', amo)
        .send({
          from: this.account,
          gas,
          gasPrice:parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.phoData.showApprove = false;
          this.phoData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.phoData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },

    //授权
    async approveGdt () {
      this.gdtData.showApprove = true;
      const erc20Contract = useErc20Contract('0xA3cD27e3352c7B056A0098361abB5c9F3080944B');
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve('0x94ec9B11ECb2188520b4Ae84d9a4F775288a58af', amo).estimateGas({from:this.account})
      erc20Contract.methods
        .approve('0x94ec9B11ECb2188520b4Ae84d9a4F775288a58af', amo)
        .send({
          from: this.account,
          gas,
          gasPrice:parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.gdtData.showApprove = false;
          this.gdtData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.gdtData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },

    //授权
    async approveVgt () {
      this.vgtData.showApprove = true;
      const erc20Contract = useErc20Contract('0x16089a9c6d3bf5e284c2a9aaa109ae55624dc40a');
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve('0xD30878F77867281Cd14592893A0Ce8F90b326999', amo).estimateGas({from:this.account})
      erc20Contract.methods
        .approve('0xD30878F77867281Cd14592893A0Ce8F90b326999', amo)
        .send({
          from: this.account,
          gas,
          gasPrice:parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.vgtData.showApprove = false;
          this.vgtData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.vgtData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
    async approveqjf () {
      this.qjfData.showApprove = true;
      const erc20Contract = useErc20Contract('0x968ea702cdaa3f5d8ee21726c9fd11ebfd6b3e27');
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve('0x9b09465cf7B9a706034DCbb0c2dD7C09b27ae9ac', amo).estimateGas({from:this.account})
      erc20Contract.methods
        .approve('0x9b09465cf7B9a706034DCbb0c2dD7C09b27ae9ac', amo)
        .send({
          from: this.account,
          gas,
          gasPrice:parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.qjfData.showApprove = false;
          this.qjfData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.qjfData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
    async approveget () {
      this.getData.showApprove = true;
      const erc20Contract = useErc20Contract('0x51Afd2309c151b8F27214eBcC306da9DA01D4dC7');
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve('0x5CfA602BFBAF1588eB3F60d7bE1b8E5405eE9024', amo).estimateGas({from:this.account})
      erc20Contract.methods
        .approve('0x5CfA602BFBAF1588eB3F60d7bE1b8E5405eE9024', amo)
        .send({
          from: this.account,
          gas,
          gasPrice:parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.getData.showApprove = false;
          this.getData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.getData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },


    //v2授权按钮
    async confirmRemovalV2 () {
      this.MiningData[0].showApprove = true;
      const erc20Contract = useErc20Contract('0xAbFeDadf69F6B692f6C1a0Fcb791866eEBddC10e');
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve('0x543F7cBeCf2DBC1CF583e4273c429Cb240aB42E1', amo).estimateGas({from:this.account})
      erc20Contract.methods
        .approve('0x543F7cBeCf2DBC1CF583e4273c429Cb240aB42E1', amo)
        .send({
          from: this.account,
          gas,
          gasPrice:parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.MiningData[0].showApprove = false;
          this.MiningData[0].allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.MiningData[0].showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },

    //获取锁仓时间
    getlockData () {
      phoLp.lockDataPOR(this.account).then(res => {
        console.log(res * 1000 - new Date().getTime());
        this.runTime = res * 1000 - new Date().getTime();
      });
    },
    //老的授权
    async confirmRemoval () {
      this.MiningData[0].showApprove = true;
      const erc20Contract = useErc20Contract('0x7EBee76221949BF10AA49c3B0B004aAa8e3b6D0d');
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve('0x7D89b39F87B94D6177f2af539fFC4eA3b2Dace86', amo).estimateGas({from:this.account})
      erc20Contract.methods
        .approve('0x7D89b39F87B94D6177f2af539fFC4eA3b2Dace86', amo)
        .send({
          from: this.account,
          gas,
          gasPrice:parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.hashData[0].showApprove = false;
          this.hashData[0].allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.hashData[0].showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
    //质押
    getBalanceTwo (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShowTwo = false;
      if (this.selectShowTwo) {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap229')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        phoLp.stakePOR(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
          this.getlockData();
        });
      } else {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap230')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        phoLp.withdrawPOR(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
          this.getlockData();
        });
      }
    },

    getBalanceThree (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShowThree = false;
      if (this.selectShowThree) {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap258')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        gdtLp.stakePOR(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      } else {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap259')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        gdtLp.withdrawPOR(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      }
    },

    getBalanceFour (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShowFour = false;
      if (this.selectShowFour) {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap269')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        vgtLp.stakePOR(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      } else {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap270')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        vgtLp.withdrawPOR(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      }
    },
    getBalanceFive (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShowFive = false;
      if (this.selectShowFive) {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap269')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        qjfLp.stakePOR(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      } else {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap270')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        qjfLp.withdrawPOR(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      }
    },
    getBalanceSix (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShowSix = false;
      if (this.selectShowSix) {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap269')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        getLp.stakePOR(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      } else {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap270')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        getLp.withdrawPOR(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      }
    },

    // v1质押
    getBalance (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShow = false;
      if (this.selectShow) {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap158')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        if (this.choiceIndex == 0) {
          market.starCardUpdate(this.account).then(res => { });
          lp.depositPOR(this.account, 0, amount, transactionsDetail)
            .then(res => {
              this.getDeposit();
              this.getBalanceOf();
              market.starCardUpdate(this.account).then(res => { });
            })
            .catch(e => { });
        } else {
          // lpOld
          //   .deposit(this.account, 0, amount, transactionsDetail)
          //   .then(res => {
          //     this.getDeposit();
          //     this.getAllBalance();
          //   })
          //   .catch(e => { });
        }

        // }
      } else {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap159')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
        if (this.choiceIndex == 0) {
          lp.withdrawPOR(this.account, 0, amount, transactionsDetail)
            .then(res => {
              this.getDeposit();
              this.getBalanceOf();
            })
            .catch(e => { });
        } else {
          // lpOld
          //   .withdraw(this.account, 0, amount, transactionsDetail)
          //   .then(res => {
          //     this.getDeposit();
          //     this.getAllBalance();
          //   })
          //   .catch(e => { });
        }
      }
    },

    //v1 v2收割
    harvest (i) {
      if (this.MiningData[0].profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };
      lp.harvestPOR(this.account, 0, transactionsDetail)
        .then(res => {
          this.getReward();
        })
        .catch(e => { });
    },

    phoHarvest () {
      if (this.phoData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      phoLp.claimRewardPOR(this.account, transactionsDetail).then(res => {
        this.getReward();
      });
    },
    gdtHarvest () {
      if (this.gdtData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      gdtLp.claimRewardPOR(this.account, transactionsDetail).then(res => {
        this.getReward();
      });
    },
    vgtHarvest () {
      if (this.vgtData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      vgtLp.claimRewardPOR(this.account, transactionsDetail).then(res => {
        this.getReward();
      });
    },
    qjfHarvest () {
      if (this.qjfData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      qjfLp.claimRewardPOR(this.account, transactionsDetail).then(res => {
        this.getReward();
      });
    },
    getHarvest () {
      if (this.getData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      getLp.claimRewardPOR(this.account, transactionsDetail).then(res => {
        this.getReward();
      });
    },

    harvestOld (i) {
      if (this.hashData[0].profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };
      lpOld
        .harvest(this.account, 0, transactionsDetail)
        .then(res => {
          this.getReward();
        })
        .catch(e => { });
    },

    //v1 v2获取质押数量
    getDeposit () {
      lp.userinfoPOR(this.account, '0').then(res => {
        this.MiningData[0].myNumber = res.amount / Math.pow(10, 18);
      });

      // lpOld.userinfo(this.account, '0').then(res => {
      //   this.hashData[0].myNumber = res.amount / Math.pow(10, 18);
      // });

      phoLp.balancePOR(this.account).then(res => {
        this.phoData.myNumber = (res * 1) / Math.pow(10, 18);
      });

      gdtLp.balancePOR(this.account).then(res => {
        this.gdtData.myNumber = (res * 1) / Math.pow(10, 18);
      });

      vgtLp.balancePOR(this.account).then(res => {
        this.vgtData.myNumber = (res * 1) / Math.pow(10, 18);
      });
      qjfLp.balancePOR(this.account).then(res => {
        this.qjfData.myNumber = (res * 1) / Math.pow(10, 18);
      });
      getLp.balancePOR(this.account).then(res => {
        this.getData.myNumber = (res * 1) / Math.pow(10, 18);
      });
    },

    //v1 v2获取收益
    getReward () {
      lp.pendingRewardPOR('0', this.account).then(res => {
        this.MiningData[0].profit = res / Math.pow(10, 18);
      });

      // lpOld.pendingReward('0', this.account).then(res => {
      //   this.hashData[0].myNumber = res.amount / Math.pow(10, 18);
      // });

      phoLp.earnedPOR(this.account).then(res => {
        this.phoData.profit = res / Math.pow(10, 18);
      });

      gdtLp.earnedPOR(this.account).then(res => {
        this.gdtData.profit = res / Math.pow(10, 18);
      });

      vgtLp.earnedPOR(this.account).then(res => {
        this.vgtData.profit = res / Math.pow(10, 18);
      });
      qjfLp.earnedPOR(this.account).then(res => {
        this.qjfData.profit = res / Math.pow(10, 18);
      });
      getLp.earnedPOR(this.account).then(res => {
        this.getData.profit = res / Math.pow(10, 18);
      });
    },

    //v1 v2激励
    getpoolReward () {
      lp.poolRewardPOR().then(res => {
        this.MiningData[0].allReward = res[0];
        this.MiningData[0].todayReward = res[1];
      });

      // lpOld.pendingReward(0, this.account).then(res => {
      //   if(res){
      //     this.hashData[0].allReward = res[0];
      //   this.hashData[0].todayReward = res[1];
      //   }

      // });
    },

    selectV2 (state) {
      this.selectShow = state;
      if (state) {
        this.max = this.MiningData[0].balance;
      } else {
        this.max = this.MiningData[0].myNumber;
      }
      this.MiningPopupfirm();
    },
    //质押
    selectPho (state) {
      this.selectShowTwo = state;
      if (state) {
        this.max = this.phoData.balance;
      } else {
        this.max = this.phoData.myNumber;
      }
      this.MiningPopupfirmTwo();
    },

    selectGdt (state) {
      this.selectShowThree = state;
      if (state) {
        this.max = this.gdtData.balance;
      } else {
        this.max = this.gdtData.myNumber;
      }
      this.MiningPopupfirmThree();
    },

    selectVgt (state) {
      this.selectShowFour = state;
      if (state) {
        this.max = this.vgtData.balance;
      } else {
        this.max = this.vgtData.myNumber;
      }
      this.MiningPopupfirmFour();
    },
    selectqjf (state) {
      this.selectShowFive = state;
      if (state) {
        this.max = this.qjfData.balance;
      } else {
        this.max = this.qjfData.myNumber;
      }
      this.MiningPopupfirmFive();
    },
    selectget (state) {
      this.selectShowSix = state;
      if (state) {
        this.max = this.getData.balance;
      } else {
        this.max = this.getData.myNumber;
      }
      this.MiningPopupfirmSix();
    },

    selectOld (state) {
      this.selectShow = state;
      if (state) {
        this.max = this.hashData[0].balance;
      } else {
        this.max = this.hashData[0].myNumber;
      }
      this.MiningPopupfirm();
    },

    choiceSwitch (i) {
      // if (i == 1) return this.$toast(this.$t('lang.swap65'));

      this.choiceIndex = i;
    },

    significantDigits,
  },

  components: {
    MiningPopup,
  },
  watch: {
    account () {
      //获取账号
      if (this.account) {
        this.handleApproveBtnShow();
        this.getBalanceOf();
        this.getDeposit();
        this.getReward();
        this.getpoolReward();
        this.getlockData();
      }
    },
  },
  computed: {
    ...mapState(['account','gasPrice']),
  },
};
</script>

<style lang="less" scoped>
.choice {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
  border-radius: 6px;
  margin-top: 20px;
  div {
    flex: 1;
    font-size: 10px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #666;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 6px 6px 2px 2px;
    padding: 4px 0;
    cursor: pointer;
    .small_triangle {
    }
  }
  .special {
    color: #333;
    // box-shadow: 0px 3px 3px 1px rgba(255, 210, 69, 0.3);
    border: 1px solid #3399ff;
  }
}
.mining {
  background: #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 0 0 10px;
  overflow: hidden;
  margin: 10px 0 20px;
  .mining_head {
    display: flex;
    justify-content: space-between;
    // box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.3);
    padding: 20px 14px 0px;
    .head_right2{
      padding: 5px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    
        border: 1px solid #D4D4D4;
      img{
        border-radius: 50%;
        width: 34px;
        height: 34px;

      }
    }
    .head_left {
      display: flex;
      // align-items: center;
      font-size: 18px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      padding: 10px 0;
      .head_img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        transform: translate(0%, -10%);
      }
      .special_img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        transform: translate(-40%, 10%);
      }
    }
    .head_right {
      display: flex;
      padding-top: 10px;
      .exhibition {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        color: #666666;
        margin-right: 40px;
        .value {
          color: #333333;
          margin-top: 6px;
        }
      }
    }
  }
  .content {
    padding: 4px 24px 14px;
    .earned {
      display: flex;
      justify-content: space-between;
      background: rgba(241,244,249, 1);
      border-radius: 10px;
      padding: 20px;
      margin-top: 20px;
      .earned_left {
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        .earned_title {
          font-size: 12px;
          color: #666666;
          margin-bottom: 10px;
        }
      }
      .button {
        display: flex;
        align-items: flex-end;
        .confirmBtn {
          height: auto;
          font-size: 16px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          border-radius: 16px;
          padding: 4px 20px;
        }
        .button_img {
          width: 26px;
          height: 26px;
          margin-left: 10px;
        }
      }
    }
    .staked {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #f8f7f7;
      border-radius: 10px;
      padding: 10px 20px;
      margin-top: 20px;
      .staked_title {
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .confirmBtn {
        height: auto;
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        border-radius: 16px;
        padding: 4px 59px;
        margin-top: 20px;
      }
    }
    .tvl {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-family: Segoe UI-Bold, Segoe UI;
      font-weight: bold;
      color: #333333;
      background: #ffffff;
      border: 1px solid #3399ff;
      // box-shadow: 0px 1px 2px 1px #3399ff;
      border-radius: 10px;
      padding: 6px 12px;
      margin-top: 12px;
      div {
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
      .money_img {
        width: 16px;
        height: 16px;
      }
    }

    .specific_data {
      background: #ffffff;
      border-radius: 10px;
      margin-top: 16px;
      .specific {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;
        font-family: Segoe UI-Bold, Segoe UI;
        font-weight: bold;
        color: #333333;
        margin-top: 10px;
        .special {
          color: #3399ff;
        }
        .company {
          margin-left: 6px;
        }
      }
    }
  }
  .down {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 20px;
  }
}
</style>