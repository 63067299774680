const HOSTURLOBJECT = window.location
let configURL = {
	http_url:'',
	initveUrl:''
}

if(HOSTURLOBJECT.hostname == 'localhost'){
	//onedex.club
	//onedex.darw.club
	 configURL = {
		  http_url:"https://good-swap.pro/api/v1",
		  initveUrl:"https://good-swap.pro"
	}
}else{
	configURL = {
		  http_url: HOSTURLOBJECT.origin +'/api/v1',
		  initveUrl:HOSTURLOBJECT.origin
	}
	
}
console.log('configURL',HOSTURLOBJECT);

export default configURL