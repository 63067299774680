<template>
    <div class="invitation">
     
      
    
      <div class="yaoq">
        <p class="y_title">我的邀请码</p>
       
          
  
  <div class="y_line1">{{ userInfo.parent_id >0 ? userInfo.invite_code : '--' }}</div>
  <div class="y_button copys" @click="copy"
    :data-clipboard-text="userInfo.invite_code">
    复制链接码</div>

     
      </div>
      <div class="jilu">
       
        <p class="j_title">我的邀请人</p>
        <div class="j_text" @click="popupfunopen" v-if="userInfo.parent_id == 0">请填写推荐人邀请码</div>
        <div class="j_text" v-else>{{ recommender }}</div>

         <div class="suanli_box">
            <span>最大算力区</span>
            <span>{{ zoneCpData.max_zone_cp }}</span>
         </div>
         <div class="suanli_box">
            <span>其他算力区</span>
            <span>{{ zoneCpData.other_zone_cp }}</span>
         </div>
        <div class="j_sosuo">
          <span class="s_span">{{ $t('lang.swap63') }}</span>
          <div class="vant">
            <van-icon name="search" class="v_icon" />
            <input type="text" v-model="souoInput" class="van-field2" @input="sosuofun"
              placeholder="搜索">
          </div>
  
        </div>
        <div class="y_cont">
          <div class="c_wlist" v-if="chelist.length == 0">
            <img src="@/assets/homelogo/no_drop.png" alt="">
            <p>暂未记录~</p>
          </div>
          <template v-else>

         
             <div class="list_box">
              <div class="c_li" v-for="(item,index) in chelist" :key="index">
                <div>
                  {{ item.substring(0, 7) + '*****' + item.substring(item.length - 7, item.length)}}
                </div>
               
              </div>
            </div>
  
  
  
          </template>
  
        </div>
      </div>
      <van-popup v-model="popupshow" round>
      <div class="popup">
        <div class="p_main">
          <p class="m_title">填写邀请码</p>
          <van-field type="text" v-model="fromInput" input-align="left" placeholder="" class="van-field" />
          <div class="m_button" @click="postucbin">确定</div>
       
        </div>

      </div>
    </van-popup>
     
    </div>
  </template>
  
  <script>

  import Clipboard from 'clipboard';
  import { mapState } from 'vuex';
import { invite,coin } from '../../api';
import Web3 from 'web3';
  export default {
    data() {
      return {
        loading1: false,
        finished1: false,
        popupshow:false,
        page: 1,
        chelist: [],
        souoInput:'',
        fromInput:'',
        userInfo:{},
        zoneCpData:{},
        recommender:''
      };
    },
  
    created() {
      this.init()
    },
    watch: {
      account() {
        this.init();
        // this.finished = false;
      },
      lang() {
        this.init();
      }
    },
    computed: {
      ...mapState(['account', 'lang']),
    
    },
  
    methods: {
      init() {
        this.chelist= []
        this.popupshow  = false
        this.page =1 
         this.getAccount()
         this.inviteZoneCp()
         this.getdata()
         this.usercenterrecommender()
      },
   
      copy() {
        if(this.userInfo.parent_id == 0) return this.$toast('请绑定推荐人');
          var clipboard = new Clipboard('.copys');
          clipboard.on('success', e => {
            this.$toast(this.$t('lang.swap300'));
            clipboard.destroy(); // 释放内存
          });
          clipboard.on('error', e => {
            this.$toast(this.$t('lang.swap301'));
            clipboard.destroy(); // 释放内存
          });
        
      },
      onLoad1() {
        // 异步更新数据
    
      },

      getAccount(){
        let data ={
          address:this.account
        }
          coin.getAccount(data,this.account).then(res=>{
                if(res.code == 200){
                  this.userInfo = res.data.user_info
                }
          })
      },
      inviteZoneCp(){
        invite.inviteZoneCp(this.account).then(res=>{
            if(res.code == 200){
              this.zoneCpData = res.data
            }
        })
      },
      usercenterrecommender(){    
        invite.usercenterrecommender(this.account).then(res=>{
              if(res.code == 200){
                this.recommender = res.data.recommender
              }
        })     
      },
      async postucbin(){
        this.popupshow = false
        let web3 = new Web3(window.ethereum);
        const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('User Bind'), this.account)
          let data ={
            invite_code:this.fromInput,
            signature:sign
          }
        invite.userBind(data,this.account).then(res=>{
              this.$toast(res.msg);
              if(res.code == 200){
                this.init()
              }
        })
      },
      popupfunopen(){
         this.popupshow = true
      },
      sosuofun(){

      },
      getdata() {
  
        invite.inviteChildrenList(this.account).then(res => {
             if(res.code == 200){
              this.chelist = res.data
             }
        })
  
  
  
      },
      
    },
    components: {
      
    },
  
  };
  </script>
  
  <style lang="less" scoped>
  .popup {

.p_main {
  width: 288px;

  border-radius: 10px;
  padding: 10px;
  background: #ffffffff;

  .m_button {
    width: 228px;
    height: 48px;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
    text-align: center;
    line-height: 48px;
    background: rgba(42, 130, 228, 1);
  }

  .m_title {
    color: #2f272aff;
    font-size: 20px;
    font-weight: 700;
    font-family: "PingFang";
    text-align: center;
  }

  span {
    color: #000000ff;
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
    float: left;
    margin-bottom: 20px;
  }
}
}
  .suanli_box{
    margin: 25px 0;
    padding: 17px 14px;
    border-radius: 8px;

border: 1px solid rgba(240, 240, 240, 1);
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
font-size: 16px;
color: rgba(0, 0, 0, 1);
  }
  
  .list_box {
    margin-bottom: 5rem;
    overflow-y: scroll;
    height: 300px;
  }
  
  .list_title {
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(51, 51, 51, 1);
  
    div {
      width: 30%;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 20.27px;
      color: #fff;
    }
  }
  
  .invitation {
    padding-top: 20px;
    width: 100%;
    text-align: center;
    background: rgba(248, 250, 252, 1);
  
   
    .yaoq {
      width: 90%;
      margin: 0 auto;
     
      padding: 20px;
      border-radius: 20px;
      opacity: 1;
      background: rgba(255, 255, 255, 1);
     
      text-align: center;
  
      .y_line1 {
        width: 100%;
        height: 52px;
        border-radius: 110px;
        text-align: center;
        line-height: 52px;
        color: rgba(34, 34, 34, 1);
        background: rgba(240, 240, 240, 1);
      }
  
      .y_title {
        color: rgba(34, 34, 34, 1);
        font-size: 20px;
        font-weight: 700;
        font-family: "PingFang";
      }
  
      .y_button {
        margin-top: 30px;
        width: 100%;
        height: 52px;
        border-radius: 110px;
        background: rgba(42, 130, 228, 1);
       
        text-align: center;
        line-height: 52px;
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        font-weight: 700;
        font-family: "PingFang";
      }
    }
  
    .jilu {
      width: 100%;
  
      border-radius: 20px;
      margin-top: 20px;
      background: rgba(255, 255, 255, 1);
      padding: 21px;
  
      .j_title {
        text-align: left;
        color: rgba(34, 34, 34, 1);
        font-size: 18px;
        font-weight: 700;
        font-family: "PingFang";
      }
  
      .j_text {
        width: 100%;
        height: 59px;
        border-radius: 110px;
        background: rgba(240, 240, 240, 1);
        
        color: rgba(34, 34, 34, 1);
        font-size: 14px;
        font-weight: 700;
        font-family: "PingFang";
        text-align: center;
        line-height: 59px;
      }
  
      .j_sosuo {
        width: 100%;
  
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
  
        .s_span {
          color: rgba(34, 34, 34, 1);
          font-size: 18px;
          font-weight: 700;
          font-family: "PingFang";
        }
      }
    }
  }
  
  .van-field {
    width: 228px;
    margin: 0 auto;
  
    height: 48px;
    border-radius: 4px;
    font-weight: bold;
    background: rgba(240, 240, 240, 1) !important;
    padding: 14px !important;
  
    /deep/ .van-field__control {
      font-size: 14px;
      color: #000;
    }
  }
  
  .vant {
    width: 206px;
    position: relative;
    background: rgba(240, 240, 240, 1);
    border-radius: 20px;
    height: 34px;
  
    .v_icon {
      position: absolute;
      left: 15px;
      top: 11px;
  
    }
  
    .van_sosuo {
      float: right;
      padding: 0;
      color: #fff;
      width: 206px;
      height: 38px;
  
      border: none;
      background: none;
      box-shadow: 0 0 12px 0 #080a1b1a;
    }
  }
  
  .y_cont {
    width: 100%;
    margin-top: 30px;
  
    .c_li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      height: 43px;
      color: #999999ff;
      font-size: 14px;
      font-weight: 700;
      font-family: "PingFang";
      padding: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      div {
        width: 30%;
        text-align: center;
      }
    }
  }
  
 
  

  
  .van-field2 {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 !important;
    padding-top: 5px !important;
    color: #000;
    width: 170px;
    height: 34px;
  
    border: none;
    background: none !important;
  
  
    /deep/ .van-field__control {
      font-size: 14px;
      color: #fff;
    }
  }
  
  .c_wlist {
    width: 100%;
    height: 219px;
    border-radius: 20px;
    opacity: 1;
  
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    img {
        width: 247px;
        height: 173px;
    }
  
    p {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      font-family: "PingFang";
    }
  }</style>