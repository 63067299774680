export const OKTlist =[
    {
        "chainId": 66,
        "address": "0xC6894a787A41271271662d9553AF627B8A0717B7",
        "name": "Phoenix Community Token",
        "symbol": "PHO",
        "decimals": 18,
        "logoURI": require('@/assets/img/logo.jpg')
      },
      {
        "chainId": 66,
        "address": "0x7d528254D11ef5992A71563604eAD0F075aBef34",
        "name": "GDT",
        "symbol": "GDT",
        "decimals": 18,
        "logoURI": require('@/assets/img/GDT.png')
      },
      {
        "chainId": 66,
        "address": "0xc6245a853ed5a0bcd56f3fd4124406db0ff6cfcc",
        "name": "Hua Jiao Token",
        "symbol": "HJT",
        "decimals": 18,
        "logoURI": require('@/assets/img/HJT.png')
      },
     
      {
        "chainId": 66,
        "address": "0x72cbb489db48c6bb3f274717277d6275144a3a69",
        "name": "very good token",
        "symbol": "VGT",
        "decimals": 18,
        "logoURI": require('@/assets/img/VGT.png')
      },
      {
        "chainId": 66,
        "address": "0x382bb369d343125bfb2117af9c149795c6c65c50",
        "name": "USDT",
        "symbol": "USDT",
        "decimals": 18,
        "logoURI": require('@/assets/img/usdt-new.png')
      },
      {
        "chainId": 66,
        "address": "0xd8b712f0de6ea101f588712ac5cb0d791ca17fba",
        "name": "Fly Financial Token",
        "symbol": "FFT",
        "decimals": 18,
        "logoURI": require('@/assets/img/FFT.png')
      },
      {
        "chainId": 66,
        "address": "0x301809ed9b6699870ed7fcaca1de9d901ad2686d",
        "name": "ADD UP Token",
        "symbol": "ADD",
        "decimals": 18,
        "logoURI": require('@/assets/img/ADD.png')
      },
      {
        "chainId": 66,
        "address": "0xF643b64657D02D9b015B79A513C2cCD3E2Bcd09c",
        "name": "MCS Token",
        "symbol": "MCS",
        "decimals": 18,
        "logoURI": require('@/assets/img/MCS.png')
      },
      {
        "chainId": 66,
        "address": "0x3F8969Be2FC0770dCc174968e4B4ff146E0ACDaF",
        "name": "FILK",
        "symbol": "FILK",
        "decimals": 18,
        "logoURI": require('@/assets/img/FIL.png')
      },
      {
        "chainId": 66,
        "address": "0xdf54b6c6195ea4d948d03bfd818d365cf175cfc2",
        "name": "OKB",
        "symbol": "OKB",
        "decimals": 18,
        "logoURI": require('@/assets/img/OKB.png')
      },
      {
        "chainId": 66,
        "address": "0x54e4622DC504176b3BB432dCCAf504569699a7fF",
        "name": "BTCK",
        "symbol": "BTCK",
        "decimals": 18,
        "logoURI": require('@/assets/img/BTC.png')
      },
      {
        "chainId": 66,
        "address": "0xef71ca2ee68f45b9ad6f72fbdb33d707b872315c",
        "name": "ETHK",
        "symbol": "ETHK",
        "decimals": 18,
        "logoURI": require('@/assets/img/ETH.png')
      },

      {
        "chainId": 66,
        "address": "0x271d3FCE66062152352d145f590BFba927B3667e",
        "name": "BIT EARTH",
        "symbol": "BTEH",
        "decimals": 18,
        "logoURI": require('@/assets/img/BTEH.png')
      },
      {
        "chainId": 66,
        "address": "0x0AB45C2A37C024c59987A58201BFbF78eC850089",
        "name": "MMDa",
        "symbol": "MMDa",
        "decimals": 18,
        "logoURI": require('@/assets/img/MMBA.jpg')
      },
      {
        "chainId": 66,
        "address": "0xA43619D96bC97C104309ec2781e7f050BBcfE923",
        "name": "RuralDog",
        "symbol": "RuralDog",
        "decimals": 18,
        "logoURI": require('@/assets/img/RDOG.jpg')
      },
      {
        "chainId": 66,
        "address": "0x3d2480bf09DE01f51abE1824e6a052fdA3355668",
        "name": "qilin token",
        "symbol": "QLB",
        "decimals": 18,
        "logoURI": require('@/assets/img/QLB.png')
      },
      {
        "chainId": 66,
        "address": "0x87Cc37bA0Ff45B5d2FE29f80687Dd46161715a5d",
        "name": "WJH",
        "symbol": "WJH",
        "decimals": 18,
        "logoURI": require('@/assets/tokenlogo/WJH.jpg')
      },
      {
        "chainId": 66,
        "address": "0x12A5d8b1fDd536F7cb66939fe992EE4EF0C1988c",
        "name": "WWW",
        "symbol": "WWW",
        "decimals": 18,
        "logoURI": require('@/assets/tokenlogo/WWW.png')
      },
      {
        "chainId": 66,
        "address": "0xe1191b59c258162E7daF8171CaEc85C79f5909A4",
        "name": "QJF",
        "symbol": "QJF",
        "decimals": 18,
        "logoURI": require('@/assets/tokenlogo/QJF.jpg')
      },
]


export const Polygonlist =[
  {
    "chainId": 137,
    "address": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    "name": "(PoS) Tether USD",
    "symbol": "USDT",
    "decimals": 6,
    "logoURI": require('@/assets/tokenlogo/USDT.png')
  },
  {
    "chainId": 137,
    "address": "0xb3718900e261abaa78a00995c5a061731a5eb53c",
    "name": "PHO",
    "symbol": "PHO",
    "decimals": 18,
    "logoURI": require('@/assets/img/logo.jpg')
  },
  {
    "chainId": 66,
    "address": "0x20a8b1dbd7b7130ed20bc883514911466189bd10",
    "name": "QJF",
    "symbol": "QJF",
    "decimals": 18,
    "logoURI": require('@/assets/tokenlogo/QJF.jpg')
  },
  {
    "chainId": 137,
    "address": "0xc5fe7631ca1dc999fffa530b8f883fe1cad38bd2",
    "name": "very good token",
    "symbol": "VGT",
    "decimals": 18,
    "logoURI": require('@/assets/img/VGT.png')
  },
  {
    "chainId": 137,
    "address": "0x47b3c2ba7f69879e5d081df62aee3cad4282b1b0",
    "name": "GDT",
    "symbol": "GDT",
    "decimals": 18,
    "logoURI": require('@/assets/img/GDT.png')
  },
  {
    "chainId": 137,
    "address": "0x5caf56f1b86759f9a0895cb46eb7db000809380a",
    "name": "GET",
    "symbol": "GET",
    "decimals": 18,
    "logoURI": require('@/assets/img/GET.png')
  },
]

export const BNBlist = [
  {
      "chainId": 56,
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "name": "USDT",
      "symbol": "USDT",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/USDT.png')
    },
    {
      "chainId": 56,
      "address": "0x8743047938C875269b7605Ce9329466A95816580",
      "name": "D6",
      "symbol": "D6",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/D6.png')
    },
    {
      "chainId": 56,
      "address": "0x86cb49f8Bc766E883Db3da18d213770205314101",
      "name": "PHO",
      "symbol": "PHO",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo.jpg')
    },
    {
      "chainId": 56,
      "address": "0xF8657A5386E853498719bCc02d13eDa484D1091e",
      "name": "GDT",
      "symbol": "GDT",
      "decimals": 18,
      "logoURI": require('@/assets/img/GDT.png')
    }, {
      "chainId": 56,
      "address": "0x2C7d51F436Eed1f23cCe4b2071269F860486f6A1",
      "name": "VGT",
      "symbol": "VGT",
      "decimals": 18,
      "logoURI": require('@/assets/img/VGT.png')
    }, {
      "chainId": 56,
      "address": "0xeAD78e7004B0d59635e46cBe80c321BAED78f742",
      "name": "GET",
      "symbol": "GET",
      "decimals": 18,
      "logoURI": require('@/assets/img/GET.png')
    }
]

export const KADlist =[
  {
    "chainId": 879,
    "address": "0x448bCE7d787104000873e2C0fFd2fF5121556f16",
    "name": "USDT",
    "symbol": "USDT",
    "decimals": 18,
    "logoURI": require('@/assets/tokenlogo/USDT.png')
  },
]

