import store from "@/store";
// import addresses from '@/config/constants/contracts.js';
// const addresses = {
//   jackRouter: '0xcd4f01b62480926cc4614ea44fb1f1e5f449485e',
//   jackFactory: '0x7d72a61f5829b3cb271e6ff4c126f22bd7ffe166',
//   weth: '0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15',
// };
const addresses = store.state.netWorkConfig.swapConfig
export const getAddress = address => {
  return address;
};

export const getJackFactoryAddress = () => {
  return getAddress(addresses.jackFactory);
};

export const getJackRouterAddress = () => {
  return getAddress(addresses.jackRouter);
};

export const getWethAddress = () => {
  return getAddress(addresses.weth);
};
