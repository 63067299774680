<template>
  <div class="index">
    <div class="banner" >
      <div>交易就来 GoodSwap</div>
      <div>高效率 多收益</div>
      <div class="img">
        <div class="img_abs" style="top: 20px; color: rgba(0, 0, 0, 1);">GoodSwap</div>
       <div class="img_abs">全新上线</div>
      </div>
     
    </div>
    <div class="functional_region">
      <div class="region">
        <div v-for="(item,index) of functionOption"
             :key="index"
             @click="switchovers(index)"
             :class="index ==regionIndex?'special':''">
          <span>{{$t(item.name)}}</span>
        </div>
      </div>
    </div>

    <div class="box">
      <router-view />
    </div>
  </div>
</template>

<script>
import { basic } from '../../api/index';
export default {
  data () {
    return {
      // 功能列表
      functionOption: [{ name: 'lang.swap77' }, { name: 'lang.swap78' },{ name: '跨桥链' }],
      regionIndex: '0',
    };
  },
  created () {
    this.getBasicList();
    this.regionIndex = this.$route.name == 'Swap' ? '0' : '1';
  },
  mounted () {

  },
  methods: {
    switchovers (index) {
      this.regionIndex = index;
      if (this.regionIndex == '0') {
        this.$router.push({ name: 'Swap' });
      } else {
        this.$router.push({ name: 'LiquidityIndex' });
      }
    },

    getBasicList () {

      let data = {
        page: 1,
        page_size: 20,
      };
      basic.getNoticeList(data, '0x3D12Bd39bB936a73575ea97dFbf308b08b84e76B', "zh-cn").then(res => {
        if (res.code === 200) {
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: rgba(248, 250, 252, 1);
}
.banner{
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  &>div{
    color: rgba(34, 34, 34, 1);
    font-weight: bold;
  }
  &>div:nth-child(1){
    font-size: 24px;
    margin-bottom: 5px;
  }
  &>div:nth-child(2){
    font-size: 18px;
    margin-bottom: 19px;
  }
  .img{
    position: relative;
    width: 100%;
    background: url('~@/assets/img/swap_banner.png') no-repeat;
    background-size: contain !important;
    height: 98px;
    .img_abs{
      left: 18px;
      bottom: 16px;
      position: absolute;
   
font-size: 20px;
color: rgba(60, 114, 241, 1);
    }
  }
 }

.box {
  flex: 1;
}
.functional_region {
  padding: 20px 20px 0;

  .region {
    display: flex;
    width: 100%;
    line-height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: rgba(158, 156, 163, 1);
    font-weight: 600;
    padding: 3px;
    border: 1px solid rgba(60, 114, 241, 1);
    border-radius: 34px;
    // margin: 20px 0;
    margin-bottom: 20px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      // width: 192rpx;
      padding: 16px 0;
    }
    .special {
      // width: 50%;
      color: #fff;
      background: #356ef1ff;
      // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
      border-radius: 34px;
    }
  }
}
/deep/ .router {
  background: #000 !important;
}
</style>