<template>
  <div class="good">
    <div class="content">
      <div class="search-box">
        <span>{{ $t('lang.swap353') }}</span>
        <van-field class="input"
                   v-model="ticketId"
                   :placeholder="$t('lang.swap354')">
          <template #button>
            <van-button size="small"
                        type="primary"
                        @click="getMarketList">搜索</van-button>
          </template>
        </van-field>
      </div>
      <div class="name-box">
        <span>{{ $t('lang.swap355') }}</span>
        <span>{{ $t('lang.swap113') }}</span>
        <span class="middle">{{ $t('lang.swap356') }}</span>
        <span class="keep">{{ $t('lang.swap357') }}</span>
      </div>
      <div class="list">
        <!-- :style="{'height': pageHeight+'px'}" -->
        <!-- <van-list v-model="loading"
                  :finished="finished"
                  finished-text=""
                  @load="getMarketList"
                  :immediate-check="false"> -->
        <div v-for="(item, index) of marketList"
             :key="index"
             class="li">
          <div>{{ item.id }}</div>
          <div>{{ item.pay_gdt }}GDT</div>
          <div class="middle">{{ item.join_num }}/10</div>
          <div class="spell-button">
            <span @click="showClick(2, item)"
                  class="keep spell">{{ $t('lang.swap358') }}</span>
          </div>
        </div>
        <!-- </van-list> -->
      </div>

      <div class="jump-box">
        <div @click="showClick(0)"
             class="jump-li">
          <img src="@/assets/img/cooperate_img.png"
               class="cooperate_img" />
          <span>{{ $t('lang.swap359') }}</span>
        </div>
        <div @click="jump('GoodBill')"
             class="jump-li">
          <img src="@/assets/img/order_img.png"
               class="cooperate_img" />
          <span>{{ $t('lang.swap351') }}</span>
        </div>
        <div @click="jump('GoodCenter')"
             class="jump-li">
          <img src="@/assets/img/personal_img.png"
               class="cooperate_img" />
          <span>{{ $t('lang.swap360') }}</span>
        </div>
      </div>
    </div>

    <van-popup v-model="show"
               round
               position="bottom">
      <div class="cooperate-box">
        <!-- 发团 -->
        <div v-show="showIndex == 0">
          
          <div class="cancel">
            <img @click="close"
                 class="cancel-img"
                 src="@/assets/img/cancel.png"
                 mode="widthFix" />
          </div>
          <div class="mission">
            <img class="mission_img"
                 src="@/assets/img/mission_img.png" />
          </div>
          <div class="hint-text">
            <span>{{ $t('lang.swap361') }}</span>
          </div>
          <van-button class="cooperate-button"
                      type="primary"
                      loading-text="Waiting..."
                      @click="initiate"
                      :loading="showWithDraw">{{ $t('lang.swap359') }}</van-button>
          <div class="hold-text">
            <span>{{ $t('lang.swap362', { quantity: ticket_num }) }}</span>
            <span @click="showClick(1)"
                  style="color: #2a82e4">{{ $t('lang.swap363') }}</span>
          </div>
        </div>

        <!-- 兑换GDT -->
        <div v-show="showIndex == 1">
          <div class="caption">{{ $t('lang.swap365') }}</div>
          <van-stepper v-model="value"
                       min="0"
                       :max="ticket_num" />
          <div class="caption">{{ $t('lang.swap77') }}GDT</div>
          <div class="amount-input">
            <van-field class="input"
                       v-model="search"
                       placeholder=""
                       readonly />
            <span class="overage">{{ $t('lang.swap364') }}{{ gdbData.usable }}GDT</span>
          </div>
          <!-- <div class="caption">{{ $t('lang.swap365') }}</div> -->
          <!-- <div class="amount-input">
            <van-field class="input"
                       v-model="search"
                       placeholder="" />
            <span class="overage">{{ $t('lang.swap366',{ quantity: 3 }) }}</span>
          </div> -->
          <van-button class="cooperate-button"
                      type="primary"
                      loading-text="Waiting..."
                      @click="excharge"
                      :loading="showWithDraw">{{ $t('lang.swap309') }}</van-button>
          <div class="hold">{{ $t('lang.swap383', { quantity: ticket_num }) }}</div>
        </div>

        <!-- 拼团GDT -->
        <div v-show="showIndex == 2">
          <div class="caption">{{ $t('lang.swap358') }}GDT</div>
          <div class="amount-input">
            <!-- <van-field class="input"
                       v-model="groupGdt"
                       placeholder="" /> -->
            <span class="input">{{ group.pay_gdt }}</span>
            <span class="overage">{{ $t('lang.swap364') }}{{ gdtData.usable }}GDT</span>
          </div>
          <div class="caption">{{ $t('lang.swap367') }}VGT</div>
          <div class="amount-input">
            <!-- <van-field class="input"
                       v-model="groupVgt"
                       placeholder="" /> -->
            <span class="input">{{ group.pay_vgt }}</span>
            <span class="overage">{{ $t('lang.swap364') }}{{ vgtData.usable }}VGT</span>
          </div>
          <van-button @click="postJoin"
                      class="cooperate-button"
                      type="primary"
                      loading-text="Waiting..."
                      :loading="showWithDraw">{{ $t('lang.swap309') }}</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { goodApi, rewardApi } from '@/api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
  data () {
    return {
      show: false,
      showIndex: 0,
      marketList: [],
      showWithDraw: false,
      gdbData: {}, //GDB数据
      gdtData: {}, //GDT数据
      vgtData: {}, //VGT数据
      group: {},
      // groupGdt: '',//拼团GDT
      // groupVgt: '',//拼团消耗VGT
      shop_ticket_num: 0, //商品券数量
      ticket_num: 0, //团长票数量
      value: 1, //兑换数量
      ticketId: undefined,
      page: 1,
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
      pageHeight: '',
    };
  },
  methods: {
    init () {
      if (this.account) {
        this.getMarketList();
        this.getWallet();
        this.getSummary();
      }
    },
    //获取余额
    getWallet () {
      rewardApi.getWallet({ coin_key: 'GDT' }, this.account).then(res => {
        this.gdtData = res.data;
      });
      rewardApi.getWallet({ coin_key: 'VGT' }, this.account).then(res => {
        this.vgtData = res.data;
      });
      rewardApi.getWallet({ coin_key: 'GDB' }, this.account).then(res => {
        this.gdbData = res.data;
      });
    },
    getMarketList () {
      let data = {
        ticket_id: this.ticketId,
      };
      goodApi.getMarketList(data, this.account).then(res => {
        if (res.code == 200) {
          // console.log(res);
          this.marketList = res.data.list
          // this.marketList = this.marketList.concat(res.data.list);
          // this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          // this.page++;
          // this.loading = false;
          // if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
        // this.marketList = res.data.list;
      });
    },

    //获取团长卷余额
    getSummary () {
      goodApi.goodSummary(this.account).then(res => {
        this.ticket_num = res.data.ticket_num;
      });
    },
    //兑换
    excharge () {
      if (this.value == 0) return this.$toast(this.$t('lang.swap390'));
      this.showWithDraw = true;
      let web3 = new Web3(window.ethereum);
      web3.eth.personal.sign(web3.utils.fromUtf8('GG Exchange GDB'), this.account, (err, res) => {
        let da = {
          amount: this.value,
          signature: res,
        };
        this.showWithDraw = true;
        goodApi
          .postExchangeGdb(da, this.account)
          .then(res => {
            this.showWithDraw = false;
            if (res.code == 200) {
              this.$toast(this.$t('lang.swap340'));
              this.init();
              this.show = false;
            } else {
              this.$toast(res.msg);
            }
          })
          .catch(e => {
            this.showWithDraw = false;
          });
      });
    },
    //发团
    initiate () {
      if (this.gdtData.usable == 0 || this.gdtData.usable < this.group.pay_gdt) return this.$toast('GDT' + this.$t('lang.swap392'));
      if (this.ticket_num < 1) return this.$toast(this.$t('lang.swap384'));
      this.showWithDraw = true;
      let web3 = new Web3(window.ethereum);
      web3.eth.personal.sign(web3.utils.fromUtf8('GG Initiate'), this.account, (err, res) => {
        let da = {
          ticket_id: 0,
          signature: res,
        };
        this.showWithDraw = true;
        goodApi
          .postInitiate(da, this.account)
          .then(res => {
            this.showWithDraw = false;
            if (res.code == 200) {
              this.$toast(this.$t('lang.swap385'));
              this.init();
              this.show = false;
            } else {
              this.$toast(res.msg);
            }
          })
          .catch(e => {
            this.showWithDraw = false;
          });
      });
    },
    // 拼团
    postJoin () {
      // if (this.groupGdt == '' || this.groupGdt == 0) return
      // if (this.groupVgt == '' || this.groupVgt == 0) return
      if (this.gdtData.usable == 0 || this.gdtData.usable < this.group.pay_gdt) return this.$toast('GDT' + this.$t('lang.swap392'));
      if (this.vgtData.usable == 0 || this.vgtData.usable < this.group.pay_vgt) return this.$toast('VGT' + this.$t('lang.swap392'));
      this.showWithDraw = true;
      let web3 = new Web3(window.ethereum);
      web3.eth.personal.sign(web3.utils.fromUtf8('GG Join'), this.account, (err, res) => {
        let da = {
          ticket_id: this.group.id,
          signature: res,
        };
        this.showWithDraw = true;
        goodApi
          .postJoin(da, this.account)
          .then(res => {
            this.showWithDraw = false;
            if (res.code == 200) {
              this.$toast(this.$t('lang.swap386'));
              this.init();
              this.show = false;
            } else {
              this.$toast(res.msg);
            }
          })
          .catch(e => {
            this.showWithDraw = false;
          });
      });
    },
    showClick (i, item) {
      if (item) {
        this.group = item;
      }
      this.showIndex = i;
      this.show = true;
    },
    close () {
      this.show = false;
    },
    jump (name) {
      this.$router.push({ name: name });
    },
  },
  watch: {
    account () {
      //获取账号
      this.init();
      // this.getMarketList();
    },
  },
  computed: {
    ...mapState(['account']),
    search () {
      return this.value * 100;
    },
  },
  created () {
    this.pageHeight = document.documentElement.clientHeight - 360;
    this.init();
  },
};
</script>

<style lang="less" scoped>
.good {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 20px;
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #ffffff;
    border-radius: 30px 30px 0 0;
    padding: 22px;
    .search-box {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      font-size: 20px;
      font-weight: bold;
      color: #000;
      .input {
        flex: 1;
        border-radius: 20px;
        background: #f1f4f9ff;
        padding: 4px 8px !important;
        margin-left: 40px;
        /deep/ .van-field__control {
          font-size: 12px;
        }
      }
    }
    .name-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #38383898;
      margin-top: 20px;
      span {
        width: 22%;
      }
    }
    .middle {
      text-align: center;
    }
    .keep {
      text-align: right;
    }
    .list {
      // overflow: auto;
      // &::-webkit-scrollbar {
      //   display: none; /* ChromeSafari */
      // }
      flex: 1;
     min-height: 300px;
      .li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        margin-top: 20px;
        div {
          width: 22%;
        }
        .spell-button {
          display: flex;
          justify-content: flex-end;
          .spell {
            font-size: 12px;
            color: #ffffff;
            border-radius: 16px;
            background: #356ef1ff;
            padding: 6px 16px;
            transform: scale(0.9, 0.9);
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
        }
      }
    }

    .jump-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 16px;
      background: #f1f4f9;
      padding: 20px;
      margin-top: 40px;
      .jump-li {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        .cooperate_img {
          display: block;
          width: 44px;
          height: 44px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .cooperate-box {
    padding: 20px 20px;
    .cancel {
      display: flex;
      justify-content: flex-end;
      .cancel-img {
        display: block;
        width: 14px;
        height: 14px;
      }
    }
    .mission {
      display: flex;
      justify-content: center;
      margin: 16px 0 0;
      .mission_img {
        display: block;
        width: 200px;
        height: 180px;
      }
    }
    .hint-text {
      font-size: 14px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 48px;
    }
    .cooperate-button {
      width: 100%;
      height: auto;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      border-radius: 16px;
      background: #356ef1;
      padding: 20px 0;
    }
    .hold-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: bold;
      color: #666666;
      margin-top: 20px;
    }

    .caption {
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 20px;
    }
    /deep/ .van-stepper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .van-stepper__input {
        flex: 1;
        height: 50px;
        font-size: 16px;
        font-weight: bold;
        background: #fff;
        border: 1px solid #e5e6ef;
        border-radius: 10px;
        margin: 0 10px;
      }
      .van-stepper__minus {
        width: 50px;
        height: 50px;
        background: #e0e0e0;
        border-radius: 10px;
        &::before {
          height: 4px;
          background: #a7a6a7;
        }
      }
      .van-stepper__plus {
        width: 50px;
        height: 50px;
        background: #3f88f7;
        border-radius: 10px;
        &::before {
          height: 4px;
          background: #fff;
        }
        &::after {
          width: 4px;
          background: #fff;
        }
      }
    }
    .amount-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      border: 1px solid #e2e8f3ff;
      padding: 12px;
      margin-bottom: 30px;

      .input {
        flex: 1;
        font-size: 14px;
        font-weight: bold;
        color: #222222;

        /deep/ .van-field__control {
          font-size: 14px;
          font-weight: bold;
          color: #222222;
        }
      }
      .overage {
        font-size: 14px;
        color: #999999;
        border-left: 1px solid #eeedf3;
        padding-left: 14px;
        margin-left: 10px;
      }
    }
    .hold {
      font-size: 14px;
      color: #999999;
      text-align: center;
      margin-top: 10px;
    }
  }
}
</style>