
import swanAbi from '../config/abi/swan.json'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
function inviter(address) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(swanAbi, '0x25BF35B089DAcB352eadB802a685207d31E23a9F');
        crossingOkContractInstance.methods.inviter(address).call().then(res => {
            resolve(res)
        })
    })
}

export default {
    inviter
}

