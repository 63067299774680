<template>
    <div class="index">
        <div class="functional_region">
            <div class="region">
                <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
                    :class="index == regionIndex ? 'special' : ''">
                    <span>{{ $t(item.name) }}</span>
                </div>
            </div>
            <div style="font-size: 12px; color: red;" v-show="showErrorBoolean">
                *请切换到正确的链上进行操作交易
            </div>
        </div>

        <div class="box">
            <router-view />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            // 功能列表
            functionOption: [{ name: 'OKC' }, { name: 'Polygon' }],
            regionIndex: '0',
            showErrorBoolean: false
        };
    },
    computed: {
        ...mapState(['account', 'netWorkConfig']),
    },
    created() {
           
        this.regionIndex = this.$route.name == 'MiningOKT' ? '0' : '1';
    },
    mounted() {
          this.checkWatchNetwork()
    },
    methods: {
        switchovers(index) {
            this.regionIndex = index;
            if (this.regionIndex == '0') {
                this.$router.push({ name: 'MiningOKT' });
            } else {
                this.$router.push({ name: 'MiningPOR' });
            }
            this.checkWatchNetwork()
        },
        checkWatchNetwork() {
            if (this.netWorkConfig.chainId == "0x42") {
                if (this.regionIndex == '0') {
                      this.showErrorBoolean = false
                } else {
                    this.showErrorBoolean = true
                }
            } else if (this.netWorkConfig.chainId == "0x89") {
                if (this.regionIndex == '1') {
                    this.showErrorBoolean = false
                } else {
                    this.showErrorBoolean = true
                }
            }
        },

    },
};
</script>

<style lang="less" scoped>
.index {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: rgba(248, 250, 252, 1);
}

.box {
    flex: 1;
}

.functional_region {
    padding: 20px 20px 0;

    .region {
        display: flex;
        width: 100%;
        line-height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: rgba(158, 156, 163, 1);
        font-weight: 600;
        border: 1px solid rgba(60, 114, 241, 1);
       padding: 3px;
        border-radius: 34px;
        // margin: 20px 0;
        margin-bottom: 20px;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            // width: 192rpx;
            padding: 16px 0;
        }

        .special {
            // width: 50%;
            color: #fff;
            background: #356ef1ff;
            // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
            border-radius: 34px;
        }
    }
}

/deep/ .router {
    background: #000 !important;
}</style>