<template>
  <div class="invitation">
    <div class="Invitation_link">
      <div>
        <span>{{ $t('lang.swap150') }}:</span>
      </div>
      <div class="Invitation_address">
        <div class="address">
          <span>{{invited}}</span>
        </div>
        <img src="@/assets/img/ranking.png"
             class="ranking"
             @click="copy"
             :data-clipboard-text="this.invitationAddress" />
      </div>
    </div>

    <div class="invitation_lower">
      <div class="computational">
        <div class="power">
          <span>{{ $t('lang.swap151') }}</span>
          <span class="quantity">{{significantDigits((calculationForce.max_zone_cp*1).toFixed(6))}}</span>
        </div>
        <div class="power">
          <span>{{ $t('lang.swap152') }}</span>
          <span class="quantity">{{significantDigits((calculationForce.other_zone_cp*1).toFixed(6))}}</span>
        </div>
      </div>
      <div class="invitation_list">
        <div>
          <span>{{ $t('lang.swap153') }}</span>
        </div>
        <div v-for="(item,index) of invitationRecord"
             :key="index"
             class="li">
          <span>{{ item.substring(0, 7) + '*****' + item.substring(item.length - 7, item.length)}}</span>
        </div>
      </div>
    </div>
    <!-- <van-popup v-model="invitationPopupShow"
               round>
      <InvitationPopup @handleConfirm="handleConfirm"
                       @getMyAddress="getMyAddress"></InvitationPopup>
    </van-popup>-->
  </div>
</template>

<script>
// import InvitationPopup from '@/views/invitation/InvitationPopup.vue';
import swan from '../../methods/swan.js';
import { invite, coin } from '../../api/index';
import { mapState } from 'vuex';
import Clipboard from 'clipboard';
import { significantDigits } from '@/utils/format.js';

export default {
  data () {
    return {
      invitationAddress: '', //邀请地址
      // be_invited: '', //我的邀请人
      invitationRecord: [], //邀请记录
      // invitee: '', //邀请人
      // editable: true, //判断是否修改过
      // invitationPopupShow: false, //弹窗状态
      chainInviteInfo: '',
      calculationForce: { max_zone_cp: '0', other_zone_cp: '0' },
      language: '',
    };
  },

  created () {
    this.init();
  },
  watch: {
    account () {
      this.init();
    },
  },

  methods: {
    init () {
      if (this.account) {
        this.childrenList();
        this.zoneCp();
        this.invitationAccount();
      }
    },
    copy () {
      var clipboard = new Clipboard('.ranking');
      clipboard.on('success', e => {
        this.$toast(this.$t('lang.swap300'));
        clipboard.destroy(); // 释放内存
      });
      clipboard.on('error', e => {
        this.$toast(this.$t('lang.swap301'));
        clipboard.destroy(); // 释放内存
      });
    },
    async childrenList () {
      this.language = await localStorage.getItem('lang');
      if (this.account == '') return;
      invite.inviteChildrenList(this.account, this.language).then(res => {
        if (res.code == 200) {
          this.invitationRecord = res.data;
        }
      });
    },
    // 算力区
    async zoneCp () {
      this.language = await localStorage.getItem('lang');
      if (this.account == '') return;
      invite.inviteZoneCp(this.account, this.language).then(res => {
        if (res.code == 200) {
          this.calculationForce = res.data;
        }
      });
    },
    invitationAccount () {
      let data = {
        address: this.account,
      };
      coin.getAccount(data).then(res => {
        if (res.code == 200) {
          this.invitationAddress = res.data.user_info.invite_link;
        }
      });
    },
    significantDigits,
  },
  components: {
    // InvitationPopup,
  },
  computed: {
    ...mapState(['account']),
    invited () {
      if (this.account) {
        return this.account.substring(0, 7) + '*****' + this.account.substring(this.account.length - 6, this.account.length);
      } else {
        return '';
      }
    },
  },
};
</script>

<style lang="less" scoped>
.invitation {
  // background: #fff;
  border-radius: 16px;
  padding: 20px;
  .Invitation_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 220px;
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    background: url('~@/assets/img/Invitation_link.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 30px;
    .Invitation_address {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .address {
        font-size: 12px;
        color: #ffffff;
        background: linear-gradient(270deg, #5396f9 0%, #408af8 100%);
        border-radius: 16px;
        padding: 4px 22px;
      }
      .ranking {
        width: 20px;
        margin-left: 10px;
      }
    }
  }
  .invitation_lower {
    background: #fff;
    box-shadow: 0px 3px 6px 1px #e4e4e4;
    border-radius: 16px;
    padding: 10px;
    margin-top: 20px;
    .computational {
      display: flex;
      justify-content: space-between;
      .power {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        min-width: 40%;
        max-width: 46%;
        color: #333333;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
        border-radius: 8px;
        padding: 16px 10px 6px 10px;
        .quantity {
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
        span {
          margin-bottom: 10px;
        }
      }
    }
    .invitation_list {
      font-size: 14px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
      border-radius: 8px;
      padding: 14px 20px;
      margin-top: 14px;
      .li {
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
}
</style>