export const lang = {
   swap1: 'Exchange',
   swap2: 'Trade tokens in an instant',
   swap3: 'From',
   swap4: 'Balance: ',
   swap5: 'token',
   swap6: 'MAX',
   swap7: 'Price',
   swap8: 'Slippage Tolerance',
   swap9: 'Connect Wallet',
   swap10: 'Enter an amount',
   swap11: 'insufficient balance',
   swap12: 'Insufficient liquidity for this trade',
   swap13: 'Select a token',
   swap14: 'Approve',
   swap15: 'Wrap',
   swap16: 'Unwrap',
   swap17: 'swap',
   swap18: 'Transaction receipt',
   swap19: 'Waiting for confirmation',
   swap20: 'Will',
   swap21: 'Exchange for',
   swap22: 'Confirm this transaction in your wallet',
   swap23: 'Submitted transaction',
   swap24: 'View in Block Browser',
   swap25: 'Dismiss',
   swap26: 'Transaction rejected',
   swap27: 'Confirm Swap',
   swap28: 'If the output is a valuation, you will get at least {inputOutMin} {symbol}, Or the deal will be withdrawn',
   swap29: 'Enter as valuation, you will sell the most {inputInMax} {symbol}, Or the deal will be withdrawn',
   swap30: 'Minimum received',
   swap31: 'Maximum sales',
   swap32: 'To',
   swap33: 'Manage tokens',
   swap34: 'Import token',
   swap35: 'Import',
   swap36: 'Manage tokens',
   swap37: 'Custom token',
   swap38: 'Clear all',
   swap39: 'Search name or paste address',
   swap40: 'Settings',
   swap41: 'Default transaction speed(GWEI)',
   swap42: 'Standard',
   swap43: 'Fast',
   swap44: 'Instant',
   swap46: 'Enter a vaild slippage percentage',
   swap47: 'Your transaction may fail',
   swap48: 'Your transaction may be frontrun',
   swap49: 'Tx deadline (mins)',
   swap50: 'estimated',
   swap51: 'Disable Multihops',
   swap52: 'Recent Transactions',
   swap53: 'No recent transactions',
   swap54: 'Clear All',
   swap55: 'Not connected',
   swap56: 'Join The Board',
   swap57: 'Donates',
   swap58: 'Rights',
   swap59: 'List Of Board Address',
   swap60: 'Referrals',
   swap61: 'Copy',
   swap62: 'My DAPP Referrer:',
   swap63: 'Referrals Address',
   swap64: 'Something went wrong. Please try again later',
   swap65: 'not open',
   swap66: 'harvest',
   swap67: 'Extraction succeeded',
   swap68: 'Donation in progress, please wait',
   swap69: 'Donated',
   swap70: 'Non-Whitelist',
   swap71: 'Failed',
   swap72: 'Insufficient Balance',
   swap73: 'Waiting',
   swap74: 'privilege grant failed',
   swap75: 'Donation in progress, please wait',
   swap76: 'Failed',
   swap77: 'Swap',
   swap78: 'Liquidity',
   swap79: 'Member',
   swap80: 'Referrals',
   swap81: 'Reward Pool',
   swap82: 'Cross chain bridge',
   swap83: 'Pool',
   swap84: 'Farm',
   swap85: 'Home',
   swap86: 'Markets',
   swap87: 'Swap',
   swap88: 'Trade',
   swap89: 'Earn',
   swap90: 'Confirm',
   swap91: 'Price Impact',
   swap92: 'Minimum received',
   swap93: 'Liquidity Provider Fee',
   swap94: 'Add Liquidity',
   swap95: 'Add liquidity to receive LP tokens',
   swap96: 'Share of Pool',
   swap97: 'Prices and pool share',
   swap98: 'Your Liquidity',
   swap99: 'Remove liquidity to receive tokens back',
   swap100: 'No liquidity found.',
   swap101: 'Dont see a pool you joined?',
   swap102: 'Find other LP tokens',
   swap103: 'Pooled',
   swap104: 'Remove',
   swap105: 'Supply',
   swap106: 'Import Pool',
   swap107: 'Import an existing pool',
   swap108: 'Select a token to find your liquidity.',
   swap109: 'You don’t have liquidity in this pool yet.',
   swap110: 'LP tokens in your wallet',
   swap111: 'To receive',
   swap112: 'and',
   swap113: 'Amount',
   swap114: 'YOU WILL RECEIVE',
   swap115: 'Output is estimated. If the price changes by more than {slippage}% your transaction will revert.',
   swap116: 'Burned',
   swap117: 'Deposited',
   swap118: 'Confirm Supply',
   swap119: 'Rates',
   swap120: 'You are the first liquidity provider.',
   swap121: 'The ratio of tokens you add will set the price of this pool.',
   swap122: 'Once you are happy with the rate click supply to review.',
   swap123: 'Removing ',
   swap124: 'Supplying',
   swap125: 'Confirm supply',
   swap126: 'White Paper',
   swap127: 'Audit',
   swap128: 'Notice',
   swap129: 'Tips',
   swap130: 'PHO 1:1 exchange',
   swap131: 'More',
   swap132: 'Loading',
   swap133: 'Information',
   swap134: 'Donation quota is full',
   swap135: 'MY PHO',
   swap136: 'PHO in Wallet',
   swap137: 'PHO to Harvest',
   swap138: 'PHO Price',
   swap139: 'PHO Total',
   swap140: 'PHO Market capitalization',
   swap141: 'PHO INFO',
   swap142: 'Earned',
   swap143: 'STAKED',
   swap144: 'Personal TVL',
   swap145: 'Community TVL',
   swap146: 'Harvest',
   swap147: 'Total reward ',
   swap148: 'Reward today',
   swap149: 'LP Maket Makers address',
   swap150: 'My invitation link',
   swap151: 'Maximum computing power area',
   swap152: 'Other computing power areas',
   swap153: 'My Friends Referrer',
   swap154: 'Cancel',
   swap155: 'Modify Referrer',
   swap156: 'Invitee cannot be empty',
   swap157: 'Modified Successfully',
   swap158: 'STAKED LP token',
   swap159: 'REDEEM LP token',
   swap160: 'REDEEM',
   swap161: 'Obtain',
   swap162: 'No income at present',
   swap163: 'Route',
   swap164: 'Audit institution',
   swap165: 'Cooperative partner',
   swap166: 'Issue',
   swap167: 'Activity',
   swap168: 'Single pool',
   swap169: 'LP pool',
   swap170: 'Congress',
   swap171: 'Card',
   swap172: 'Blind box',
   swap173: 'Game',
   swap174: 'The total amount allocated by the stake pool',
   swap175: 'Amount to be claimed',
   swap176: 'I have got the amount',
   swap177: 'Extraction',
   swap178: 'Super node',
   swap179: 'Equity card has received share',
   swap180: 'National treasury',
   swap181: 'The accumulated amount of the treasury',
   swap182: 'Amount allocated by the treasury',
   swap183: 'Amount repurchased by the treasury',
   swap184: 'Subgraph Health Indicator',
   swap185: 'goodswap is the first equity tax system defi platform in the whole network based on okexchain. It gradually introduces swap, NFT, gamefi, lend, machine gun pool and other modules. Since its launch, it has provided a wider range of asset appreciation schemes and an unprecedented sense of experience through leading technology and fair and open market concept, which is deeply loved by the majority of users.',
   swap186: 'One-click take out',
   swap187: 'price impact too high',
   swap188: 'FAQ',
   swap189: 'NFT',
   swap190: 'Earn coins',
   swap191: 'In progress',
   swap192: 'Has ended',
   swap193: 'Benefit Card',
   swap193: 'Description',
   swap194: 'Ecological equity cards, limited to 1680 in the world',
   swap195: 'The requirements and benefits of lighting up the equity equity ecological card are as follows',
   swan196: 'One star card: the personal address has 1000usdt liquidity, the team has 1000usdt liquidity, and the income is divided into 0.1% of transaction tax',
   swap197: 'Two star card: the individual has 2000usdt liquidity and the team has 10000 usdt liquidity. The income is divided into 0.1% of transaction tax',
   swap198: 'Three star card: personal 3000usdt liquidity, team 100000 usdt liquidity, set up a studio, and the income is divided into 0.1% of transaction tax',
   swap199: 'Four star card: the individual has 4000usdt liquidity, the team has 1million usdt liquidity, set up a studio, and the income is divided into 0.1% of the transaction tax',
   swap200: 'Five star card: personal 5000usdt liquidity, team 10million usdt liquidity, set up a studio, and the income is divided into 0.1% of transaction tax',
   swap201: 'Note: all the benefits of the equity ecological card are automatically distributed on the chain. The equity card enjoys all the ecological dividends of the exchange. The equity card can be inherited, traded and transferred. If it meets the requirements, it will be automatically upgraded. If it does not meet the requirements, it will be automatically degraded and automatically withdrawn.',
   swap202: 'Benefit Card',
   swap203: 'development roadmap:',
   swap204: 'version 2.0 will be launched in July 2022 ',
   swap205: 'in September, 2022, it will be launched to dig more with coins',
   swap206: 'nft trading platform goes online in December 2022 ',
   swap207: 'goodbay game launched in June 2023 ',
   swap208: 'goodland launched in September 2023 ',
   swap209: 'Goodswap introduction: ',
   swap210: 'sliding point:',
   swap211: 'allocation:',
   swap212: 'buy',
   swap213: 'sell',
   swap214: '0.5% LP dividend ',
   swap215: '0.5% ecological equity card dividend ',
   swap216: '0.25% reflux bottom pool destruction lp',
   swap217: '0.25% ecological construction ',
   swap218: 'No rewards to receive yet',
   swap219: 'Personal TVL',
   swap220: 'Team TVL',
   swap221: 'LP quantity',
   swap222: 'Pho in wallet',
   swap223: 'GDT',
   swap224: 'GDT air drop cumulative reward',
   swap225: 'Accumulative awards for star users',
   swap226: 'No fee countdown',
   swap227: 'Rewards can be withdrawn',
   swap228: 'LP mining reward',
   swap229: 'Pledge pho',
   swap230: 'Redemption of pho',
   swap231: 'target consensus',
   swap232: 'Consensus reached',
   swap233: 'Consensus',
   swap234: 'Consensus',
   swap235: 'Unlocked',
   swap236: 'Available',
   swap237: 'Community governance',
   swap238: 'Alliance consensus',
   swap239: 'Community Alliance',
   swap240: 'Pledge token',
   swap241: 'Pledging, please wait',
   swap242: 'transaction mining',
   swap243: 'New yesterday',
   swap244: 'Cumulative rewards for community whitelist',
   swap245: 'Cumulative rewards of equity star card',
   swap246: 'LP mining cumulative rewards',
   swap247: 'Currently available',
   swap248: 'Current recoverable amount',
   swap249: 'Trading mining',
   swap250: 'My share bonus accumulation',
   swap251: 'My transaction reward accumulation',
   swap252: 'Please enter your transaction hash',
   swap253: 'Check',
   swap254: 'Please enter your hash',
   swap255: 'Your transaction address',
   swap256: 'You will get',
   swap257: 'Only the transactions that buy GDT and create liquidity can be rewarded',
   swap258: 'Pledge gdt-pho',
   swap259: 'Redemption of gdt-pho',
   swap260: 'Total service charge of the whole network',
   swap261: 'Dividends to be claimed',
   swap262: 'GDT joint shareholders',
   swap263: 'intercourse',
   swap264: 'Mall',
   swap265: 'Selected Goods',
   swap266: 'Preferred store',
   swap267: 'Coming Soon',
   swap268: 'VGT joint shareholders',
   swap271: 'Recommended link',
   swap272: 'Please add inviter first',
   swap273: 'detailed',
   swap274: 'Metaverse',
   swap275: 'global',
   swap276: 'Exchange and liquidity',
   swap277: 'It is the first equity tax in the whole network developed based on OKExChain',
   swap278: 'Receive the Defi platform and gradually launch it',
   swap279: 'Machine pool and other plates. Since its launch, it has provided a wider range of asset value-added solutions and unprecedented experience through leading technology and fair and open market concepts, and has been deeply loved by users. ',
   swap280: 'Development roadmap',
   swap281: 'Version 2.0 will be launched in July 2022',
   swap282: 'In September 2022, the currency holding and multi-mining will be opened',
   swap283: 'The NFT trading platform will be launched in December 2022',
   swap284: 'The Goodbaby game will be launched in June 2023',
   swap285: 'Goodlend will be launched in September 2023',
   swap286: 'Learn more',
   swap287: 'You have',
   swap288: 'In',
   swap289: 'Trading encrypted digital currency',
   swap290: 'Staking liquidity and tokens',
   swap291: 'Earn bonus rewards',
   swap292: 'launch pad',
   swap293: 'Authentication',
   swap294: 'Quality encrypted digital assets',
   swap295: 'Understand',
   swap296: 'Artwork',
   swap297: 'Trading market',
   swap298: 'Browse',
   swap299: 'Instant token exchange',
   swap300: 'Replicating Success',
   swap301: 'Copy not supported, this browser does not support automatic copy',
   swap302: 'Cumulative rewards for collaborative innovation',
   swap303: 'Accumulated sharing rewards',
   swap304: 'Accumulated transaction rewards',
   swap305: 'Asset package',
   swap306: 'Trading to earn coins',
   swap307: 'Withdrawable balance',
   swap308: 'Please enter the transaction hash',
   swap309: 'submit',
   swap310: 'Please enter a hash value',
   swap311: 'Successfully recorded',
   swap312: 'Binance',
   swap313: 'Platform',
   swap314: 'PHO zone',
   swap315: 'GDT zone',
   swap316: 'VGT zone',
   swap317: 'PHO star card',
   swap318: 'personal',
   swap319: 'Team',
   swap320: 'in the wallet',
   swap321: 'Select asset package',
   swap322: 'Please select',
   swap323: 'daily rate of return',
   swap324: 'income ratio',
   swap325: 'Total number due',
   swap326: 'Buy',
   swap327: 'Your wallet balance',
   swap328: 'My asset package',
   swap329: 'total income today',
   swap330: 'Today s static income',
   swap331: 'Today s dynamic income',
   swap332: 'accumulated income',
   swap333: 'expiration quantity',
   swap334: 'select',
   swap335: 'Cryptocurrency Zone',
   swap336: 'Purchase successful',
   swap337: 'Exchange quantity',
   swap338: 'Please enter',
   swap339: 'Air drop',
   swap340: 'Redemption successful',
   swap341:'Recharge',
swap342:'Withdraw',
swap343: 'Select Pass',
swap344:'recharge amount',
swap345: 'Please enter the recharge amount',
swap346:'wallet balance',
swap347:'Available balance',
swap348:'withdrawal amount',
swap349: 'Please enter the withdrawal amount',
swap350: 'Select token',
swap351:'bill',
swap352: 'No data yet',
swap353: 'GOOD purchase',
swap354:'Search',
swap355:'number',
swap356:'Number of people',
swap357:'operation',
swap358:'Join group',
swap359:'Send a group',
swap360:'Personal Center',
swap361: 'The tour will consume a leader coupon, which cannot be revoked after the tour is issued, and you will get rich rewards after joining the group successfully',
swap362: 'Have leader coupons: {quantity} pieces',
swap363: 'Redeem Asset Package',
swap364:'Balance',
swap365: 'Exchange and consume leader ticket',
swap366: 'Balance {quantity} pieces',
swap367:'Group consumption',
swap368:'Group number',
swap369:'backpack',
swap370: 'Already have a leader ticket',
swap371: 'Already own a commodity ticket',
swap372: 'The group I participated in',
swap373: 'Role',
swap374: 'Team member',
swap375: 'Joining',
swap376: 'Failed to join the group',
swap377: 'The group was successfully joined',
swap378:'Asset',
swap379:'Hide zero assets',
swap380:'name',
swap381: 'Available',
swap382:'Leader',
swap383: 'You hold {quantity} sheets',
swap384: 'You do not have a leader ticket',
swap385: 'Successfully sent the group',
swap386: 'Team successfully assembled',
swap387: 'Good Buy',
swap388: 'Good Swap',
swap389: 'Team up to win the big prize',
swap390:'Exchange quantity cannot be 0',
swap391:'Recording, please wait',
swap392: 'Insufficient',
swap393: 'Withdrawal succeeded',
swap394:'My team star rating',
swap395:'Order Splicing Times',
swap396:'Next level',
swap397:'Group consumption',
swap398:'Direct exchange without sending a group',     
swap399:'Chain games',
swap400:'More sections are being logged in...',
swap401:'Has been issued',
swap402:'Select network'
};
