import request from '../utils/request';
export default {
	//国会, 国库
	marketCongress (address, language) {
		return request.request({
			url: '/dex/congress-data',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': language
			},
		})
	},

	//获取当前会员信息
	marketMemberInfo (address, language) {
		return request.request({
			url: '/user-center/member-info',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': language
			},
		})
	},
	//获取会员等级
	marketBuyCondition (address, language) {
		return request.request({
			url: '/user-center/buy-condition',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': language
			},
		})
	},

	//权益池 已分配总额
	marketLpData (address, language) {
		return request.request({
			url: '/dex/lp-data',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': language
			},
		})
	},
	//权益池 待领取
	marketMarkertRewards (address, language) {
		return request.request({
			url: '/reward/markert-rewards',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': language
			},
		})
	},
	//权益池 已领取
	marketPartnerRewards (address, language) {
		return request.request({
			url: '/reward/partner-rewards',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': language
			},
		})
	},
	//权益池 取出
	marketRewardOut (data, address, language) {
		return request.request({
			url: '/order/reward-out',
			method: "POST",
			headers: {
				Authorization: address,
				'Show-Language': language
			},
			data
		})
	},
	//权益池 超级合伙人已领取总额
	marketPartnerData (address, language) {
		return request.request({
			url: '/dex/partner-data',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': language
			},
		})
	},
	//权益池 超级合伙人已领取
	marketPartnerRewards (address, language) {
		return request.request({
			url: '/reward/partner-rewards',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': language
			},
		})
	},

	//权益卡登记
	marketStarCardInfo(address, language){
		return request.request({
			url: '/starcard/info',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': language
			},
		})
	},
	
	//更新签名
	starCardUpdate(address){
		return request.request({
			url: '/starcard/update-lp',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},
    
	//获取用户领取的份额
	rewardUserRewards(address,language){
		return request.request({
			url: '/reward/user-rewards',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': language
			},
		})
	},
	//获取用户dgp数据
	gdtInfo(address,language){
		return request.request({
			url: '/gdt/info',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': language
			},
		})
	},
}