<template>
  <div class="bill">
    <div class="content">
      <div class="caption">
        <span>{{ $t('lang.swap351') }}</span>
      </div>
      <div v-if="list.length == 0"
           class="nothing">
        <img src="@/assets/img/nothingBlank.png"
             class="nothing_img">
        <span>{{ $t('lang.swap352') }}</span>
      </div>
      <div v-else
           class="list">
        <van-list v-model="loading"
                  :finished="finished"
                  finished-text=""
                  :immediate-check="false"
                  @load="getBillList">
          <div v-for="(item,index) of list"
               :key="index"
               class="bill-li">
            <div class="li-left">
              <span>{{item.behavior_txt}}</span>
              <span class="while">{{item.created_time}}</span>
            </div>
            <div class="li-right">
              <span>{{item.amount}}{{item.coin_en}}{{item.type_txt}}</span>
              <span class="quantity">{{ $t('lang.swap368') }}：{{item.ticket_id}}</span>
            </div>
          </div>
        </van-list>
      </div>
    </div>

  </div>
</template>

<script>
import { goodApi } from '@/api/index';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      list: [],
      page: 1,
      pageSize: 20, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
      language: '',
    }
  },
  methods: {
    watchLpList () {
      let _this = this;
      window.addEventListener('setItemEvent', function (e) {
        //e.key : 是值发生变化的key
        //例如 e.key==="token";
        //e.newValue : 是可以对应的新值
        if (e.key === 'lang') {
          setTimeout(() => {
            _this.init()
            _this.list = []
          }, 500);

        }
      });
    },
    init () {
      if (this.account) {
        this.getBillList()
      }
    },
    async getBillList () {
      let data = {
        page: this.page, page_size: this.pageSize, type: '-1'
      }
      goodApi.getBillList(data, this.account).then(res => {
        this.list = this.list.concat(res.data.list);
        this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
        this.page++;
        this.loading = false;
        if (this.page > this.totalPage) return (this.finished = true);
      })
    },
  },
  watch: {
    account () {
      //获取账号
      this.init()
    },
  },
  computed: {
    ...mapState(['account']),
  },
  created () {
    this.init();
    this.watchLpList();

  }
}
</script>

<style lang="less" scoped>
.bill {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 20px;
  .content {
    flex: 1;
    background: #ffffff;
    border-radius: 30px 30px 0 0;
    padding: 20px 30px;
  }
  .caption {
    font-size: 18px;
    font-weight: bold;
    color: #222222;
    margin-bottom: 20px;
  }
  .list {
    .bill-li {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ececec;
      padding: 16px 0;
      .li-left {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: bold;
        color: #222222;
        .while {
          font-size: 12px;
          color: #666d8a;
          margin-top: 6px;
        }
      }
      .li-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 12px;
        font-weight: bold;
        color: #356ef1;
        .quantity {
          margin-top: 6px;
        }
      }
    }
  }

  .nothing {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: bold;
    color: #000;

    padding: 60px 0 50px;
    .nothing_img {
      width: 200px;
    }
  }
}
</style>