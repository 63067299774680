import usdtAbi from '../config/abi/erc20.json'
import Web3 from 'web3'
import store from '../store';
//授权
function transfer(myAddress, to, amount, currencyAddress) {
    console.log(myAddress, to, amount, currencyAddress);
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(usdtAbi, currencyAddress);
        const gas = await crossingOkContractInstance.methods.transfer(to, amount).estimateGas({ from: myAddress });
        let hash = ''
        crossingOkContractInstance.methods.transfer(to, amount).send({ from: myAddress, gas: gas })
            .on('transactionHash', res => {
                resolve(res)
            }).on('confirmation', res => {
                
            }).on('error', res => {
                reject(res)
            })
    })
}


export default {
    transfer
}