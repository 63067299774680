<template>
  <div style="background: rgba(245, 245, 245, 1);">
    <div class="gongao" style="border-radius: 20px 20px 0 0;">
    
    
 <div class="g_li" v-for="item in pagelist" @click="goxiangqing(item.group_id)">
  <van-icon name="arrow" class="arrow"/>
   <span class="span1">{{item.title}}</span>
   <span class="span2">{{ dateFormat(new Date(item.create_at),'yyyy-MM-dd')}}</span>
   <div class="line"></div>
   <span class="span3">{{ item.author }}</span>
 </div>
  </div>
  </div>

</template>

<script>
import { mapState } from 'vuex';
import { basic } from '../../api';
import { dateFormat } from '../../utils/format';
export default {
  name: '',
  components: {

  },
  components: {  },
  data() {
    return {
      page:1,
      pageSize:10,
      pagelist:[]
    }
  },
 
  created() {
    this.init();
   
  },
  watch: {
    account () {
      this.init();
      // this.finished = false;
    },
    lang (){
      this.init();
    }
  },
  computed: {
    ...mapState(['account','lang']),
  },
  methods: {
    dateFormat,
    init(){
     this.getlist()
    },
    getlist (){
      let data = {
        page: this.page,
        page_size: this.pageSize
      }
      basic.getNoticeList(data,this.account).then(res =>{
         console.log(res.data.list);
         this.pagelist =res.data.list
       
      })
    },
    goxiangqing(index){
      this.$router.push({ name: 'xiangqin', query: { id: index } });
    }
  },
}
</script>

<style lang="less" scoped>
.g_title{
    color: #fff;
 font-size: 20px;
 font-weight: 500;
 font-family: "Poppins";
  }
.gongao{
  width: 100%;
min-height: 100vh;
  padding: 20px ;
  
  .g_li{
    margin-bottom: 14px;
    width: 100%;
    height: 119px;
border-radius: 20px;
box-sizing: border-box;
padding: 15px 16px;
background: #fff;
border: 1px solid #F0F0F0;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 position: relative;
 .arrow{
     position: absolute;
     color: #000;
     right: 20px;
     top: 20px;
 }
    .line{
      width: 100%;
height: 0;
opacity: 1;
border: 0.5px solid #F0F0F0;
    }
    .span1{
      color: #000;
 font-size: 14px;
 font-weight: 700;
 font-family: "PingFang";
    }
    .span2{
      color: #9395a4ff;
 font-size: 12px;
 font-weight: 700;
 font-family: "PingFang";
    }
    .span3{
      color: #000;
 font-size: 12px;
 font-weight: 500;
 font-family: "Poppins";
    }
  }
}
</style>