<template>
  <div style="padding: 20px ;">
    <div class="notice">
      <div class="title">
        <img src="@/assets/img/notice.png"
             class="notice_img" />
        <span>{{ $t("lang.swap128") }}</span>
      </div>

      <div class="notice_list">
        <div class="list"
             v-for="(item,index) in list"
             :key="index">
          <div class="list_title">
            <span>{{item.title}}</span>
          </div>
          <div class="content">
            <span v-html="item.remark"></span>
          </div>
          <div class="see">
            <span>{{dateFormat(new Date(item.create_at),'yyyy-MM-dd')}}</span>
            <div class="button"
                 @click="toDesrciption(item)">
              <span>{{ $t("lang.swap131") }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <van-pull-refresh v-model="refreshing"
                        @refresh="onRefresh">
        <van-list v-model="languageShow"
                  :finished="finished"
                  finished-text="no more"
                  @load="onLoad"> -->
      <!-- <div class="notice_list">
            <div class="list"
                 v-for="(item,index) in list"
                 :key="index">
              <div class="list_title">
                <span>{{item.title}}</span>
              </div>
              <div class="content">
                <span>{{item.description}}</span>
              </div>
              <div class="see">
                <span>{{dateFormat(new Date(item.create_at),'yyyy-MM-dd')}}</span>
                <div class="button"
                     @click="toDesrciption(item)">
                  <span>{{ $t("lang.swap131") }}</span>
                </div>
              </div>
            </div>
          </div> -->
      <!-- </van-list>
      </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import { basic } from '../../api/index';
import { mapState } from 'vuex';
import { dateFormat } from '../../utils/format';
export default {
  data () {
    return {
      afds: 'false',
      list: [],
      pageNum: 1, // 页码
      pageSize: 10,
      totalPage: 0, // 总页数（需要前端自己算）
      hasMore: true, // 是否有下一页
      language: '',
      languageShow: false,
      finished: false, //是否已加载完成，加载完成后不再触发load事件
      refreshing: false,
    };
  },
  async created () {
    this.getBasicList();
    // this.language = await localStorage.getItem('lang');
    this.watchLang();
  },
  watch: {
    language () {
      // this.getBasicList();
      // this.finished = false;
    },
  },
  methods: {
    watchLang () {
      let _this = this;
      window.addEventListener('setItemEvent', function (e) {
        if (e.key == 'lang') {
          _this.getBasicList()
        }
      });
    },
    async getBasicList () {
      this.language = await localStorage.getItem('lang');
      if (this.language == null) {
        this.language = 'en-US'
      }
      // this.loadstatus = true
      if (this.language) {
        this.language = localStorage.getItem('lang');
        let data = {
          page: this.pageNum * 1,
          page_size: this.pageSize * 1,
        };
        basic.getNoticeList(data, this.account, this.language).then(res => {
          if (res.code == 200) {
            this.list = res.data.list;
            // console.log(this.list, 'this.list');
            // this.loadstatus = false;
            // if (!this.hasNext(res.data.total_count, this.pageNum, this.pageSize)) {
            //   this.hasMore = false;
            //   this.finished = true;
            // }
            // this.pageNum++;
          }
        });
      }
    },
    toDesrciption (item) {
      this.$router.push({
        name: 'Notice',
        query: {
          basic: JSON.stringify(item),
        },
      });
    },
    hasNext (total, pageNum, pageSize) {
      // 是否有下一页
      this.totalPage = Math.ceil(total / pageSize);
      return pageNum !== this.totalPage && this.totalPage !== 0;
    },
    reset () {
      this.pageNum = 1;
      this.totalPage = 0;
      this.hasMore = true;
      this.loadstatus = 'loading';
      this.rewardList = [];
    },
    onLoad () {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = []
          this.refreshing = false;
        }
        this.loading = false;
        this.getBasicList()
      }, 1000);
    },
    onRefresh () {
      this.$router.go(0)
    },
    dateFormat,
  },
  components: {},
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.notice {
  background: #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  // border: 1px solid #ffd245;
  padding: 30px 23px 20px;
  .title {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: Arial-Bold, Arial;
    font-weight: bold;
    color: #333333;
    .notice_img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .notice_list {
    .list {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      background: #f8f7f7;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.4);
      border-radius: 10px;
      padding: 18px 32px 16px;
      margin-top: 20px;
      .list_title {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
      }
      .content {
        font-size: 10px;
        font-weight: 400;
        color: #666666;
        word-wrap: break-word;
        word-break: break-all;
        margin: 10px 0 16px;
      }
      .see {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        font-weight: 400;
        color: #999999;
        .button {
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          border-radius: 16px;
          border: 1px solid #ffd245;
          padding: 4px 16px;
        }
      }
    }
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  /deep/ .van-pull-refresh {
    overflow: visible;
  }
}
</style>