<template>
  <div class="mall">
    <div v-for="(item, index) of mallList"
         @click="notOpen(item.router)"
         :key="index"
         class="mall_li">
      <img class="li_img"
           :src="item.img" />
      <span class="good">{{$t(item.good) }}</span>
      <span>{{ $t(item.text) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mallList: [
        { img: require('@/assets/img/mall_img3.png'), good: 'lang.swap387', text: 'lang.swap389', router: 'Good', },
        { img: require('@/assets/img/mall_img1.png'), good: 'lang.swap388', text: 'lang.swap265', router: '', },
        { img: require('@/assets/img/mall_img2.png'), good: 'lang.swap388', text: 'lang.swap266', router: '', },
      ],
    };
  },
  methods: {
    notOpen (router) {
      if (router) {
        this.$router.push({ name: router });

      } else {
        this.$toast(this.$t('lang.swap267'));

      }
    },
  },
};
</script>

<style lang="less" scoped>
.mall {
  padding: 0 16px;
  .mall_li {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #666666;
    border-radius: 20px;
    border: 2px solid #2893e582;
    background: #ffffffff;
    padding: 20px 10px;
    margin-top: 20px;
    .li_img {
      width: 100%;
      margin-bottom: 16px;
    }
    .good {
      font-size: 20px;
      font-weight: bold;
      color: #222222;
      margin-bottom: 6px;
    }
  }
}
</style>