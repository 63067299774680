<template>
  <div id="app">
    <Header></Header>
    <router-view class="router" />
    <!-- <BottomNavigation></BottomNavigation> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { simpleRpcProvider } from '@/utils/provider';
import Header from '@/components/Header.vue';
import Web3 from 'web3';
import  {basic} from '@/api'
// import BottomNavigation from '@/components/BottomNavigation.vue';
export default {
  name: 'App',
  components: {
    Header
    // BottomNavigation
  },
  beforeCreate() {
    // setTimeout(() => {
    //   this.$store.dispatch('setWebProvider');
    // }, 1000);
    // this.$store.dispatch('setWebProvider');
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'transactions', 'block', 'expert', 'netWorkConfig',"gasPriceType"]),
  },

  async created() {
    this.$store.dispatch('setWallet')
    this.Setimerblock()
   this.getGas()
    
    // let href = window.location.href
    // if(href.indexOf('?ChainToken')!=-1) return;
    //   let index = href.indexOf('?')
    //   let starthref = index != -1 ? href.substring(0, index) : href;
    //   window.location.replace (starthref + `?ChainToken=${this.netWorkConfig.TokenBNB.name.toLowerCase()}`) ;
    

  },
  watch: {
    block() {
      this.handleUpdateTransactions();
    },
   
    netWorkConfig(newd, old) {

      window.location.reload();
 
    }
  },
  beforeDestroy() {
    window.clearInterval(this.timer);
  },
  methods: {
    async Setimerblock() {
      this.$store.dispatch('setWebProvider');
      setTimeout(() => {
        this.$store.dispatch('setWebProvider');
      }, 3000);

      const blockNumber = await simpleRpcProvider.eth.getBlockNumber();
      this.$store.commit('SETBLOCK', blockNumber);
      this.timer = setInterval(async () => {
        const blockNumber = await simpleRpcProvider.eth.getBlockNumber();
        this.$store.commit('SETBLOCK', blockNumber);
      }, 6000);
    },
    async getGas(){
      const web3js = new Web3(window.ethereum); //
    let gasPrice = await  web3js.eth.getGasPrice()
    if(this.gasPriceType == 1){
      gasPrice = gasPrice * 1.5;
      }else if(this.gasPriceType == 2){
        gasPrice = gasPrice * 2;
      }else if(this.gasPriceType == 3){
        gasPrice = gasPrice * 2.5;
      }
      this.$store.commit('SET_GASPRICE', parseFloat(gasPrice));
    
    },
    
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });

    },
    async handleUpdateTransactions() {
      if (!this.account) {
        return;
      }
      const allTransactions = Object.values(this.transactions);
      const pendTransactions = allTransactions.filter(item => {
        return !item.receipt && item.from.toLowerCase() == this.account.toLowerCase();
      });
      pendTransactions.forEach((item, index) => {
        simpleRpcProvider.eth.getTransactionReceipt(item.hash).then(receipt => {
          if (receipt) {
            this.$store.commit('SETTRANSACTIONSRECEIPT', {
              hash: item.hash,
              receipt,
            });
            if (document.documentElement.clientWidth < 400) {
              // if (receipt.state) {
              this.$message({ type: 'success', title: item.title, message: item.message, hash: receipt.hash });
              // } else {
              //   this.$message({ type: 'error', title: item.title, message: item.message, hash: receipt.hash });
              // }
            } else {
              // if (receipt.state) {
              this.$message({
                position: 'top-right',
                type: 'success',
                title: item.title,
                message: item.message,
                hash: receipt.hash,
              });
              // } else {
              //   this.$message({
              //     position: 'top-right',
              //     type: 'error',
              //     title: item.title,
              //     message: item.message,
              //     hash: receipt.hash,
              //   });
              // }
            }
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  font-family: Roboto, Segoe UI, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666;
  background: #fff;
  height: 100%;
  image-rendering: -webkit-optimize-contrast;

  .router {
      padding-bottom: 76px;
      background: rgba(255, 255, 255, 1);
  }
}
</style>
