import request from '../utils/request';
export default {
      //配置
      pledgeConfigs(address) {
        return request.request({
            url: '/vgb-pledge/configs',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    },

    //持有信息
    pledgeHoldList(data, address) {
        return request.request({
            url: '/vgb-pledge/hold-list',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    },

    //gdb钱包
    pledgeGdbWallet(address) {
        return request.request({
            url: '/vgb-pledge/vgb-wallet',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    },

    //质押
    pledgeDo(data, address) {
        return request.request({
            url: '/vgb-pledge/do',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    },

   

    //是否已购买
    pledgeIsBuy(address) {
        return request.request({
            url: '/vgb-pledge/is-buy',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    },


   
}
