import request from '../utils/request';
export default {
		//根据币Key获取钱包
		getWallet (data,address) {
			return request.request({
				url: '/reward/wallet',
				method: "get",
				headers: {
					Authorization: address,
					'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
				},
				params:data
			})
		},
				//奖励记录
				getRecordList (data,address) {
					return request.request({
						url: '/reward/record-list',
						method: "get",
						headers: {
							Authorization: address,
							'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
						},
						params:data
					})
				},
}