export const lang = {
    swap1: '閃兌',
    swap2: '立刻開始閃兌',
    swap3: '從',
    swap4: '餘額:',
    swap5: '選擇幣種',
    swap6: '最大',
    swap7: '價格',
    swap8: '滑點容差',
    swap9: '連接錢包',
    swap10: '輸入數量',
    swap11: '餘額不足',
    swap12: '此交易的流動性不足',
    swap13: '選擇代幣',
    swap14: '授權',
    swap15: '包裝',
    swap16: '解包',
    swap17: '閃兌',
    swap18: '交易收據',
    swap19: '正在等待確認',
    swap20: '正在將',
    swap21: '交換為',
    swap22: '在你的錢包種確認此交易',
    swap23: '已提交交易',
    swap24: '在區塊瀏覽器上查看',
    swap25: '關閉',
    swap26: '交易拒絕',
    swap27: '確認交換',
    swap28: '輸出為估值,您將獲得至少{inputOutMin} {symbol},或者交易將被撤',
    swap29: '輸入為估值,您將出售最多{inputInMax} {symbol},或者交易將被撤回',
    swap30: '最小獲得量',
    swap31: '最大出售量',
    swap32: '到',
    swap33: '管理代幣',
    swap34: '管理代幣',
    swap35: '導入',
    swap36: '管理Tokens',
    swap37: '自定義代幣',
    swap38: '全部清除',
    swap39: '搜索名稱或粘貼地址',
    swap40: '設定',
    swap41: '默認交易速度(GWEI)',
    swap42: '標準',
    swap43: '快速',
    swap44: '即時',
    swap46: '輸入有效的滑點百分比',
    swap47: '你的交易可能會失敗',
    swap48: '你的交易可能會被超前',
    swap49: '交易截止時間(分鐘)',
    swap50: '估計',
    swap51: '禁用多跳',
    swap52: '最近交易',
    swap53: '最近沒有交易',
    swap54: '全部清除',
    swap55: '未連接',
    swap56: '加入董事會',
    swap57: '捐贈',
    swap58: '權益贈送',
    swap59: '董事會成員列表',
    swap60: '邀請',
    swap61: '複製',
    swap62: '我的邀請人:',
    swap63: '邀請記錄',
    swap64: '出錯啦,請稍後再試',
    swap65: '功能暫未開放',
    swap66: '領取',
    swap67: '取出成功',
    swap68: '捐贈中，待確認',
    swap69: '已捐贈',
    swap70: '非白名單用戶',
    swap71: '捐贈無效',
    swap72: '當前餘額不足',
    swap73: '授權中，請稍等',
    swap74: '授權失敗',
    swap75: '捐贈中，請稍等',
    swap76: '捐贈失敗',
    swap77: '兌換',
    swap78: '流動性',
    swap79: '董事會',
    swap80:'邀请',
    swap81: '權益池',
    swap82: '資產橋',
    swap83: '池子',
    swap84: '農場',
    swap85: '首頁',
    swap86: '市場',
    swap87: '兌換',
    swap88: '交易',
    swap89:'賺取',
    swap90:'確認',
    swap91:'價格影響',
    swap92:'最小獲得量',
    swap93:'流動性供應商費用',
    swap94:'增加流動性',
    swap95:'增加流動性以接收 LP 代幣',
    swap96:'流動性池中的份額',
    swap97:'兌換率和流動池份額',
    swap98:'您的流動性',
    swap99:'移除流動性以收回代幣',
    swap100:'未找到流動性',
    swap101:'未看到您加入的流動池？',
    swap102:'查看其他LP代幣',
    swap103:'已入池',
    swap104:'移除',
    swap105:'供應',
    swap106:'導入池',
    swap107:'導入現有流動性池',
    swap108:'選擇代幣以查找您的流動性',
    swap109:'您在該流動性池中還沒有提供流動性',
    swap110:'您的錢包中的LP代幣',
    swap111:'接收',
    swap112:'和', 
    swap113:'金額',
    swap114:'你將獲得',
    swap115:'輸出為估值。如果價格變化超過 {slippage}%，則您的交易將被撤回',
    swap116:'已焚毀',
    swap117:'已入金',
    swap118:'確認供應',
    swap119:'匯率',
    swap120:'您是第一個流動性供應商。',
    swap121:'您添加代幣的比列，將决定該流動性池的初始價格。',
    swap122:'如果您對匯率滿意，請點擊“供應”以進行檢查。',
    swap123:'正在移除',
    swap124:'正在供應',
    swap125:'確認供應',
    swap126:'白皮書',
    swap127:'稽核',
    swap128:'公告',
    swap129:'提示',
    swap130:'PHO 1:1兌換',
    swap131:'更多',
    swap132:'正在加載',
    swap133:'信息',
    swap134:'捐贈名額已滿',
    swap135:'我的PHO',
    swap136:'錢包中的PHO',
    swap137:'可收割的PHO',
    swap138:'PHO價格',
    swap139:'PHO總量',
    swap140:'PHO市值',
    swap141:'PHO信息'
};
