<template>
  <div class="asset_pack">
    <div class="choose_box">
      <div class="heading">
        <span>{{ $t('lang.swap321') }}</span>
        <span @click="open(1)"
              class="heading_gdt">{{ $t('lang.swap77') }}GDT</span>

      </div>
      <div @click="open(0)"
           class="choose">
        <span v-show="quantity">{{quantity}}</span>
        <span v-show="!quantity"
              style="color:#999; font-size: 14px;">{{ $t('lang.swap322') }}</span>
      </div>
      <div class="thorough_li">
        <span>{{ $t('lang.swap323') }}：</span>
        <span style=" color: #000;">{{choosePledge.static_rate * 100 || 0}}%</span>
      </div>
      <div class="thorough_li">
        <span>{{ $t('lang.swap324') }}:</span>
        <span style=" color: #000;">{{choosePledge.profit_rate * 100|| 0}}%</span>
      </div>
      <div class="thorough_li">
        <span>{{ $t('lang.swap325') }}:</span>
        <span style=" color: #000;">{{choosePledge.profit_rate * choosePledge.pledge_amount || 0}}</span>
      </div>
      <!-- <div class="button" @click="submit" v-if="showApprove">
        <span>{{ $t('lang.swap326') }}</span>
      </div> -->

      <van-button type="primary"
                  class="confirmBtn"
                  loading-text="Waiting..."
                  @click="submit"
                  :loading="isApprove">
        <span>{{ $t('lang.swap326') }}</span>
      </van-button>
      <!-- <div class="button" @click="approve" v-else>
        <span>{{ $t('lang.swap14') }}</span>
      </div>-->
      <div class="balance">
        <span>{{ $t('lang.swap327') }}：{{wallet.usable.toFixed(2)}}</span>
      </div>
    </div>

    <div class="my_box">
      <div class="heading">
        <span>{{ $t('lang.swap328') }}</span>
      </div>
      <div v-if="holdList.length == 0"
           class="nothing"
           :finished="finished"
           finished-text
           @load="getHoldList">
        <img src="@/assets/img/nothingBlank.png"
             class="nothing_img" />
      </div>
      <van-list class="property"
                v-else
                v-for="(item,index) of holdList"
                :key="index">
        <div class="asset">
          <img src="@/assets/img/asset_pack.png"
               class="pack_img" />
          <span>{{item.pledge_amount}}GDT{{ $t('lang.swap305') }}</span>
        </div>
        <div class="progress">
          <span class="schedule_text">{{(item.rewarded / item.out_amoun).toFixed(0) * 100 || 0}}%</span>
          <van-progress :percentage="(item.rewarded / item.out_amoun).toFixed(0) * 100 || 0"
                        stroke-width="20"
                        color="#4cdafe"
                        track-color="#4e19a5"
                        :show-pivot="false" />
        </div>
        <div class="property_li">
          <span>{{ $t('lang.swap329') }}</span>
          <span style="color:#000">{{item.today_static_reward + item.today_dynamic_reward}}GDT</span>
        </div>
        <div class="property_li">
          <span>{{ $t('lang.swap330') }}</span>
          <span style="color:#000">{{item.today_static_reward}}GDT</span>
        </div>
        <div class="property_li">
          <span>{{ $t('lang.swap331') }}</span>
          <span style="color:#000">{{item.today_dynamic_reward}}GDT</span>
        </div>
        <div class="property_li">
          <span>{{ $t('lang.swap332') }}</span>
          <span style="color:#000">{{item.rewarded}}GDT</span>
        </div>
        <div class="property_li">
          <span>{{ $t('lang.swap333') }}</span>
          <span style="color:#000">{{item.out_amount}}GDT</span>
        </div>
      </van-list>
    </div>
    <van-popup v-model="show"
               round
               position="bottom">
      <div v-show="stateShow ==0"
           class="select_box">
        <div class="select_title">
          <img style="opacity: 0;"
               src="@/assets/img/cancel.png"
               class="cancel_img" />
          <span>{{ $t('lang.swap334') }}</span>
          <img @click="closure"
               src="@/assets/img/cancel.png"
               class="cancel_img" />
        </div>
        <div v-for="(item,index) of list"
             :key="index"
             class="select_li"
             @click="quantityClick(item)">
          <span>{{item.pledge_amount}}</span>
        </div>
      </div>
      <div v-show="stateShow ==1"
           class="popup_box">
        <div class="popup_title">
          <span>{{ $t('lang.swap77') }}</span>
          <img @click="closure"
               src="@/assets/img/cancel.png"
               class="cancel_img" />
        </div>
        <div class="number">
          <span>{{ $t('lang.swap337') }}</span>
          <span class="gdt">{{ $t('lang.swap88') }}GDT：{{swapBalance.toFixed(2)}}</span>
        </div>
        <div class="quantity_field">
          <van-field type="number"
                     v-model="quantityField"
                     :placeholder="$t('lang.swap338')"
                     class="van-field" />
        </div>
        <div class="percentage">
          <span class="percentage_li"
                style="background: #00baad;"
                @click="handleToMax(0.25)">25%</span>
          <span class="percentage_li"
                style="background: #356EF1;"
                @click="handleToMax(0.5)">50%</span>
          <span class="percentage_li"
                style="background: #F87F18;"
                @click="handleToMax(0.75)">75%</span>
          <span class="percentage_li"
                style="background: #D1352A;"
                @click="handleToMax(1)">100%</span>
        </div>
        <!-- <div class="button_swap">
          <span>{{ $t('lang.swap77') }}</span>
        </div> -->
        <van-button type="primary"
                  class="confirmGdtBtn"
                  v-if="showGdtApprove"
                  loading-text="Waiting..."
                  @click="gdtApprove"
                  :loading="isGdtApprove">
        <span>{{ $t('lang.swap14') }}</span>
      </van-button>
      <van-button type="primary"
                  class="confirmGdtBtn"
                  v-else
                  loading-text="Waiting..."
                  @click="exchange"
                  :loading="isGdtApprove">
        <span>{{ $t('lang.swap77') }}</span>
      </van-button>
        <div class="airdrop">
          <span>{{ $t('lang.swap339') }}GDT: {{dropBalance.toFixed(2)}}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { coin } from '@/api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
import packApi from '@/methods/pack.js';
import gdtExchangeApi from '@/methods/gdtExchange.js'
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import { getErc20Contract, getJackPairContract } from '@/utils/contractHelp';
import { accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing, formatAmount, parseAmount } from '@/utils/format.js';
export default {
  data () {
    return {
      quantityField: 0,
      stateShow: 0,//0:购买, 1:兑换
      quantity: '',
      schedule: 60,
      show: false,
      list: [],
      choosePledge: {},
      wallet: {},
      holdList: [],
      page: 1,
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
      contract: undefined,
      payToken: undefined,
      walletBalance: 0,
      showApprove: false,
      showGdtApprove:false,
      isApprove: false,
      isGdtApprove:false,
      isBuy: false,
      swapBalance:0,
      dropBalance:0,
    };
  },
  created () {
    this.init();
  },
  watch: {
    account () {
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
  methods: {
    init () {
      if (this.account) {
        this.getList();
        this.getGdbWallet();
        this.getHoldList();
        this.getPayToken();
        this.handleApproveBtnShow();
        this.handleApproveGdtBtnShow()
        this.getIsBuy();
        this.getSwapBalance();
        this.getDropBalance()
      }
    },
    //是否购买
    getIsBuy () {
      coin.pledgeIsBuy(this.account).then(res => {
        if (res.code == 200) {
          this.isBuy = res.data.is_buy
        }
      })
    },
    async handleApproveBtnShow () {
      if (this.account) {
        const erc20ContractV2 = getErc20Contract(this.payToken);
        const toV2 = '0xa68fb248269e01e15cd1f9687064df4b5ee71bcc';
        const allowanceV2 = await erc20ContractV2.methods.allowance(this.account, toV2).call();
        if (allowanceV2 == 0) {
          this.showApprove = true;
        } else {
          this.showApprove = false;
        }
      }
    },

    //交易gdt兑换
    async handleApproveGdtBtnShow () {
      if (this.account) {
        const erc20ContractV2 = getErc20Contract('0x7d528254D11ef5992A71563604eAD0F075aBef34');
        const toV2 = '0x40d9b6295A2d3213C0126e8B71D082A4d4dA3092';
        const allowanceV2 = await erc20ContractV2.methods.allowance(this.account, toV2).call();
        if (allowanceV2 == 0) {
          this.showGdtApprove = true;
        } else {
          this.showGdtApprove = false;
        }
      }
    },

    //gdt授权
    gdtApprove(){
      this.isGdtApprove = true
      const erc20Contract = useErc20Contract('0x7d528254D11ef5992A71563604eAD0F075aBef34');
      const amo = MaxUint256.toString();
      erc20Contract.methods
        .approve('0x40d9b6295A2d3213C0126e8B71D082A4d4dA3092', amo)
        .send({
          from: this.account,
        })
        .on('confirmation', res => {
          this.showGdtApprove = false;
          this.isGdtApprove = false
        })
        .on('error', (error, receipt) => {
          this.isGdtApprove = false
          this.$toast(this.$t('lang.swap74'));
        });
    },

    approve () {
      this.isApprove = true
      const erc20Contract = useErc20Contract(this.payToken);
      const amo = MaxUint256.toString();
      erc20Contract.methods
        .approve('0xa68fb248269e01e15cd1f9687064df4b5ee71bcc', amo)
        .send({
          from: this.account,
        })
        .on('confirmation', res => {
          this.showApprove = false;
          this.isApprove = false
        })
        .on('error', (error, receipt) => {
          this.isApprove = false
          this.$toast(this.$t('lang.swap74'));
        });
    },
    getPayToken () {
      packApi.getPayToken(this.account).then(res => {
        this.payToken = res;
        this.handleApproveBtnShow();
        this.getWalletBalance();
      });
    },
    async getWalletBalance () {
      const erc20Contract = getErc20Contract(this.payToken);
      let bal = await erc20Contract.methods.balanceOf(this.account).call();
      this.walletBalance = (bal * 1) / Math.pow(10, 18).toFixed(2);
    },
    async getSwapBalance(){
      const erc20Contract = getErc20Contract('0x7d528254D11ef5992A71563604eAD0F075aBef34');
      let bal = await erc20Contract.methods.balanceOf(this.account).call();
      this.swapBalance = (bal * 1) / Math.pow(10, 18).toFixed(2);
    },
    async getDropBalance(){
      const erc20Contract = getErc20Contract('0x86c254dcfC2bd208d534Ec9B640f74fD60AF8EaB');
      let bal = await erc20Contract.methods.balanceOf(this.account).call();
      this.dropBalance = (bal * 1) / Math.pow(10, 18).toFixed(2);
    },
    getList () {
      coin.pledgeConfigs(this.account).then(res => {
        this.list = res.data;
      });
    },
    getGdbWallet () {
      coin.pledgeGdbWallet(this.account).then(res => {
        this.wallet = res.data;
      });
    },
    getHoldList () {
      let data = {
        page: this.page,
        page_size: this.pageSize,
      };
      coin.pledgeHoldList(data, this.account).then(res => {
        if (res.code == 200) {
          this.holdList = this.holdList.concat(res.data.list);
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    submit () {
      if (this.wallet.usable < this.choosePledge.pledge_amount) {
        this.$toast(this.$t('lang.swap11'));
        return;
      } else if (!this.choosePledge.pledge_amount) {
        this.$toast(this.$t('lang.swap10'));
        return;
      }
      this.isApprove = true
      let web3 = new Web3(window.ethereum);
      web3.eth.personal.sign(web3.utils.fromUtf8('Pledge Do'), this.account, (err, res) => {
        let da = {
          config_id: this.choosePledge.id,
          signature: res,
        };
        this.showWithDraw = true; 
        coin
          .pledgeDo(da, this.account)
          .then(res => {
            this.isApprove = false;
            if (res.code == 200) {
              this.$toast(this.$t('lang.swap336'));
              this.holdList=[],
              this.page = 1;
              this.finished = false
              this.init();
            } else {
              this.$toast(res.msg);
            }
          })
          .catch(e => {
            this.isApprove = false;
          });
      });
      // packApi.pledge(this.account, accMul(this.choosePledge.pledge_amount, Math.pow(10, 18))).then(res => {
      //   let data = {
      //     tx_hash: res,
      //     config_id: this.choosePledge.id
      //   };
      //   coin.pledgeMark(data, this.account).then(res => {
      //     this.isApprove = false
      //     if (res.code == 200) {
      //       this.$toast(this.$t('lang.swap336'));
      //       this.holdList = [];
      //       this.page = 1;
      //       (this.loading = false), (this.finished = false)
      //       this.quantity = '';
      //       this.choosePledge = {};
      //       this.init()
      //     }
      //   });
      // })
    },
    exchange(){
      if(this.quantityField > this.swapBalance){
        this.$toast(this.$t('lang.swap11'));
        return
      }
      this.isGdtApprove = true
      gdtExchangeApi.dochange( accMul(this.quantityField, Math.pow(10, 18)),this.account).then(res => {
        this.$toast(this.$t('lang.swap340'));
        this.init();
        this.isGdtApprove = false
      }).catch(e => {
        this.isGdtApprove = false
      })
    },
    open (i) {
      this.show = true;
      this.stateShow = i
    },
    closure () {
      this.show = false;
    },
    quantityClick (item) {
      this.choosePledge = item;
      this.quantity = item.pledge_amount;
      this.show = false;
    },
    handleToMax (num) {
      this.quantityField = this.swapBalance * num
    }
  },
};
</script>

<style lang="less" scoped>
.asset_pack {
  padding: 30px 0 0;
  .confirmBtn {
    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #fff;
    width: 100%;
    border-radius: 16px;
    padding: 20px 110px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .confirmGdtBtn{
    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #fff;
    background: #6c6aea;
    width: 100%;
    border-radius: 16px;
    padding: 20px 110px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    .heading_gdt {
      font-size: 12px;
      color: #3c7cc8;
    }
  }
  .choose_box {
    border-radius: 30px 30px 0px 0px;
    background: rgba(255, 255, 255, 1);
    padding: 20px;
    .choose {
      font-size: 16px;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      border-radius: 10px;
      background: rgba(241, 244, 249, 1);
      padding: 14px 24px;
      margin-top: 16px;
    }
    .thorough_li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: bold;
      color: #999;
      margin-top: 20px;
    }
    .button {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      border-radius: 40px;
      background: rgba(108, 106, 235, 1);
      box-shadow: 0px 20px 30px 0px rgba(25, 28, 50, 0.1);
      padding: 16px 0;
      margin: 46px 0 26px;
    }
    .balance {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      font-weight: bold;
      color: #999;
    }
  }
  .my_box {
    background: rgba(255, 255, 255, 1);
    padding: 20px;
    margin-top: 10px;
    .nothing {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #fff;

      padding: 60px 0 50px;
      .nothing_img {
        width: 200px;
      }
    }
    .asset {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      color: #000;
      .pack_img {
        display: block;
        width: 80px;
        height: 90px;
        margin-right: 10px;
      }
    }
    .progress {
      position: relative;
      overflow: hidden;
      border-radius: 20px;
      border: 2px solid rgba(255, 255, 255, 1);
      box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.15);
      margin: 20px 0 46px;
      .schedule_text {
        position: absolute;
        left: 10px;
        top: 50%;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        z-index: 333;
        transform: translate(0, -50%);
      }
    }
    .property {
      border-radius: 10px;
      background: rgba(241, 244, 249, 1);
      padding: 20px;
      margin-top: 26px;
      .property_li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: bold;
        color: #666;
        margin-top: 20px;
      }
    }
  }
  .select_box {
    .select_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      color: #000;
      font-weight: bold;
      padding: 16px;

      .cancel_img {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
    .select_li {
      font-size: 18px;
      color: #000;
      font-weight: bold;
      border-top: 1px solid rgba(241, 244, 249, 1);
      text-align: center;
      padding: 20px 0;
    }
  }
  .popup_box {
    padding: 10px 20px 30px;
    .popup_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      color: #000;
      font-weight: bold;
      // padding: 16px;

      .cancel_img {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
    .number {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #000;
      font-weight: bold;
      padding: 20px 0 10px;
      .gdt {
        font-size: 12px;
        color: #999;
      }
    }
    .quantity_field {
      background: #f0f4f9;
      border-radius: 10px;
      padding: 12px 16px;
      .van-field {
        background: rgba(0, 0, 0, 0);
      }
    }
    .percentage {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px 20px;
      .percentage_li {
        display: block;
        font-size: 12px;
        color: #ffffff;
        border-radius: 8px;
        background: #000;
        padding: 6px 16px;
      }
    }
    .button_swap {
      text-align: center;
      background: #6c6aea;
      border-radius: 30px;
      color: #fff;
      padding: 16px 0;
      margin: 40px 0 20px;
    }
    .airdrop {
      font-size: 14px;
      text-align: center;
      color: #999;
    }
  }
}
</style>