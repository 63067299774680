import Vue from 'vue';
import VueRouter from 'vue-router';
import SwapIndex from '../views/swap/Index.vue';

import Swap from '../views/swap/Swap.vue';
import FlashExchange from '../views/swap/FlashExchange.vue';
import VisualChart from '../views/swap/VisualChart.vue';
import Home from '../views/Home.vue';
import LiquidityIndex from '../views/liquidity/Index.vue';
import Liquidity from '../views/liquidity/Liquidity.vue';
import importMining from '../views/liquidity/importMining.vue';
import Remove from '../views/liquidity/Remove.vue';
import boardOfDirectors from '../views/boardOfDirectors/Index.vue';
import NoticeIndex from '../views/notice/Index.vue';
import Notice from '../views/notice/notice.vue';
import Invitation from '../views/invitation/Index.vue';
// import Index from '../views/index/index.vue';
import HomeIndex from '../views/index/HomeIndex.vue';

import MiningOKT from '../views/index/MiningOKT.vue';
import MiningPOR from '../views/index/MiningPOR.vue';
import Lianchuang from '../views/index/lianchuang.vue'; 
import MiningsIndex from '../views/index/MiningsIndex.vue'

import EquityPool from '../views/market/EquityPool.vue';
import Congress from '../views/market/Congress.vue';
import Member from '../views/market/Member.vue';
import GdtReward from '../views/market/GdtReward.vue';
import Business from '../views/market/Business.vue';
import Community from '../views/community/index.vue';
import lianchuang from '../views/index/lianchuang.vue'//联创
import vgtLianchuang from '../views/index/vgtLianchuang.vue'
import Mine from '../views/mine/index.vue'
import AssetPack from '../views/mine/assetPack.vue'
import AssetPackV from '../views/mine/assetPackV.vue'



import Mall from '../views/index/mall.vue'
import gamesMall from '../views/index/gamesmall.vue'

import Recharge from '../views/recharge/index.vue' //充值提现
import RechargeBill from '../views/recharge/bill.vue' //账单
import RechargeWallet from '../views/recharge/wallet.vue' //充值提现

import shopinggood from '../views/shopinggood/Index.vue' //good和VGT
import Good from '../views/shopinggood/good/index.vue' //good
import GoodBill from '../views/shopinggood/good/bill.vue' //good账单
import GoodCenter from '../views/shopinggood/good/center.vue' //good个人中心

import VGT from '../views/shopinggood/VGT/index.vue' //VGT
import VGTBill from '../views/shopinggood/VGT/bill.vue' //VGT账单
import VGTCenter from '../views/shopinggood/VGT/center.vue' //VGT个人中心

import DropIndex from '../views/drop/Index.vue'
import InviteIndex from '../views/invite/Index.vue'

import HomeNext from '../views/HomeNext.vue';

import bill from '../views/replenish/bill.vue';
import replenishIndex from '../views/replenish/Index.vue';
import recharge from '../views/replenish/recharge.vue';
import withdraw from '../views/replenish/withdraw.vue';
import transfer from '../views/replenish/transfer.vue';

import gongao from '../views/gongao/Index.vue'
import xiangqin from '../views/gongao/xiangqing.vue'
// import boardOfDirectors from '../views/boardOfDirectors/Index.vue'
Vue.use(VueRouter);
const routes = [

  {
    path: '/',
    redirect: 'HomeIndex',
    component: Home,
    children: [
      // {
      //   path: 'Index',
      //   name: 'Index',
      //   component: Index,
      // },
      {
        path: 'HomeIndex',
        name: 'HomeIndex',
        component: HomeIndex,
      },
      {
        path: 'SwapIndex',
        name: 'SwapIndex',
        component: SwapIndex,
        children: [
          {
            path: 'Swap',
            name: 'Swap',
            component: Swap,
          },
          {
            path: 'LiquidityIndex',
            name: 'LiquidityIndex',
            component: LiquidityIndex,
          },
          {
            path: 'Liquidity',
            name: 'Liquidity',
            component: Liquidity,
          },
          {
            path: 'importMining',
            name: 'importMining',
            component: importMining,
          },
          {
            path: 'Remove',
            name: 'Remove',
            component: Remove,
          },
        ],
      },
    
      {
        path: 'FlashExchange',
        name: 'FlashExchange',
        component: FlashExchange,
      },
      {
        path: 'NoticeIndex',
        name: 'NoticeIndex',
        component: NoticeIndex,
      },
      {
        path: 'Notice',
        name: 'Notice',
        component: Notice,
      },
      {
        path: 'DropIndex',
        name: 'DropIndex',
        component: DropIndex,
      },
      {
        path: 'Invitation',
        name: 'Invitation',
        component: Invitation,
      },
      {
        path: 'InviteIndex',
        name: 'InviteIndex',
        component: InviteIndex,
      },

      {
        path: 'MiningsIndex',
        name: 'MiningsIndex',
        component: MiningsIndex,
        redirect:'MiningsIndex/MiningOKT',
        children:[
          {
            path: 'MiningOKT',
            name: 'MiningOKT',
            component: MiningOKT,
          },
          {
            path: 'MiningPOR',
            name: 'MiningPOR',
            component: MiningPOR,
          },
        ]
      },
    
     
   
    ],
  },
  {
    path: '/HomeNext',
    name: 'HomeNext',
    component: HomeNext,
    children: [
      {
        path: '/boardOfDirectors',
        name: 'boardOfDirectors',
        component: boardOfDirectors,
      },
      {
        path: 'gamesMall',
        name: 'gamesMall',
        meta:{
          title:'娱乐游戏'
        },
        component: gamesMall,
      },
      {
        path: 'Community',
        name: 'Community',
        meta:{
          title:'社区联盟'
        },
        component: Community,
      },
      {
        path: '/Mine',
        name: 'Mine',
        meta:{
          title:'权益中心'
        },
        component: Mine,
      },
      {
        path: '/gongao',
        name: 'gongao',
        meta:{
          title:'公告列表'
        },
        component: gongao,
      },
      {
        path: '/xiangqin',
        name: 'xiangqin',
        meta:{
          title:'公告详情'
        },
        component: xiangqin,
      },
      {
        path: 'bill',
        name: 'bill',
        meta:{
          title:'账单'
        },
        component: bill,
      },
      {
        path:'/replenishIndex',
        name: 'replenishIndex',
        component: replenishIndex,
        children:[
          // {
          //   path: '/recharge',
          //   name: 'recharge',
          //   meta:{
          //    title:'充币'
          //  },
          //   component: recharge,
          // },
          {
            path: '/withdraw',
            name: 'withdraw',
            meta:{
             title:'提币'
           },
            component: withdraw,
          },
          {
            path: '/transfer',
            name: 'transfer',
            meta:{
             title:'转账'
           },
            component: transfer,
          },
        ]
      },
      {
        path:'/shopinggood',
        name: 'shopinggood',
        component: shopinggood,
        children:[
         {
           path: '/Good',
           name: 'Good',
           meta:{
            title:'GDT中心'
          },
           component: Good,
         },
         {
           path: '/GoodBill',
           name: 'GoodBill',
           meta:{
            title:'GDT账单'
          },
           component: GoodBill,
         },
         {
           path: '/GoodCenter',
           name: 'GoodCenter',
           meta:{
            title:'GDT个人中心'
          },
           component: GoodCenter,
         },
         {
           path: '/VGT',
           name: 'VGT',
           meta:{
            title:'VGT中心'
          },
           component: VGT,
         },
         {
           path: '/VGTBill',
           name: 'VGTBill',
           meta:{
            title:'VGT账单'
          },
           component: VGTBill,
         },
         {
           path: '/VGTCenter',
           name: 'VGTCenter',
           meta:{
            title:'VGT个人中心'
          },
           component: VGTCenter,
         }
        ]
     },
      {
        path: '/VisualChart',
        name: 'VisualChart',
        component: VisualChart,
      },
      {
        path: 'Lianchuang',
        name: 'Lianchuang',
        component: Lianchuang,
      },
      {
        path: 'EquityPool',
        name: 'EquityPool',
        component: EquityPool,
      },
      {
        path: 'Congress',
        name: 'Congress',
        component: Congress,
      },
      {
        path: 'Member',
        name: 'Member',
        component: Member,
      },
      {
        path: 'GdtReward',
        name: 'GdtReward',
        component: GdtReward,
      },
      {
        path: 'Business',
        name: 'Business',
        component: Business,
      },
    
      {
        path: 'Mall',
        name: 'Mall',
        component: Mall,
      },
    
      {
        path: '/vgtLianchuang',
        name: 'vgtLianchuang',
        component: vgtLianchuang,
      },
     
      {
        path: '/AssetPack',
        name: 'AssetPack',
        meta:{
          title:'GDT资产包'
        },
        component: AssetPack,
      },
      {
        path: '/AssetPackV',
        name: 'AssetPackV',
        meta:{
          title:'VGT资产包'
        },
        component: AssetPackV,
      },
      {
        path: '/Recharge',
        name: 'Recharge',
        component: Recharge,
      },
      {
        path: '/RechargeBill',
        name: 'RechargeBill',
        component: RechargeBill,
      },
      {
        path: '/RechargeWallet',
        name: 'RechargeWallet',
        component: RechargeWallet,
      },
    ]
  },
 
  // {
  //   path: '/lianchuang',
  //   name: 'lianchuang',
  //   component: lianchuang,
  // }
  
];

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  // base: './',
  routes,
});

export default router;
