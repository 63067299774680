import packpAbi from '../config/abi/pack.json'

import Web3 from 'web3'
import BigNumber from 'bignumber.js'

//获取支付币种信息
function getPayToken(myaddress) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(packpAbi, '0xa68fb248269e01e15cd1f9687064df4b5ee71bcc');
        crossingOkContractInstance.methods.payToken().call().then(res => {
            resolve(res)
        })
    })
}

function pledge(myaddress,amount){
    console.log(myaddress,amount);
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(packpAbi, '0xa68fb248269e01e15cd1f9687064df4b5ee71bcc');
        const gas = await crossingOkContractInstance.methods.pledge(amount).estimateGas({ from: myaddress });
        let hash = ''
        crossingOkContractInstance.methods.pledge(amount).send({ from: myaddress,gas: gas })
            .on('transactionHash', res => {
                hash = res
            }).on('confirmation', res => {
                resolve(hash)
            }).on('error', res => {
                reject(res)
            })
    })
}

export default {
    getPayToken,
    pledge
}
