import request from '../utils/request';
export default {
    //验证hash
    validHash(data, address) {
        return request.request({
            url: '/dex/valid-hash',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    },

    //入账
    dexEntry(data,address){
        return request.request({
            url: '/dex/entry',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    }
}