import store from "@/store";


const OKTbaselsit = [


  {
    name: 'OKT',
    symbol: 'OKT',
    address: '0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15',
    chainId: 66,
    decimals: 18,
    balance: '',
    logoURI: require('@/assets/img/OKT.png'),
  },
  {
    "name": "USDT Token",
    "symbol": "USDT",
    "address": "0x382bb369d343125bfb2117af9c149795c6c65c50",
    "chainId": 66,
    "decimals": 18,
    "logoURI":  require('@/assets/img/usdt-new.png')
  },

  {
    "name": "Phoenix Community Token",
    "symbol": "PHO",
    "address": "0xC6894a787A41271271662d9553AF627B8A0717B7",
    "chainId": 66,
    "decimals": 18,
    "logoURI": require('@/assets/img/logo.jpg')
  },

 
];
const Polygonbaselsit = [
  {
    name: 'MATIC',
    symbol: 'MATIC',
    address: '0xcF1e5136A17b61E12220d3CD4665a707A2690d0a',
    chainId: 137,
    decimals: 18,
    balance: '',
    logoURI:require('@/assets/tokenlogo/polygon.png'),
  },
  {
    "chainId": 137,
    "address": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    "name": "(PoS) Tether USD",
    "symbol": "USDT",
    "decimals": 6,
    "logoURI": require('@/assets/tokenlogo/USDT.png')
  },
  {
    "chainId": 137,
    "address": "0xb3718900e261abaa78a00995c5a061731a5eb53c",
    "name": "PHO",
    "symbol": "PHO",
    "decimals": 18,
    "logoURI": require('@/assets/img/logo.jpg')
  },

 
];
const BNBbaselsit = [
  {
    name: 'BNB',
    symbol: 'BNB',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    chainId: 56,
    decimals: 18,
    balance: '',
    logoURI: require('@/assets/tokenlogo/BNB.png'),
  },
  {
    "chainId": 56,
    "address": "0x55d398326f99059fF775485246999027B3197955",
    "name": "USDT",
    "symbol": "USDT",
    "decimals": 18,
    "logoURI": require('@/assets/tokenlogo/USDT.png')
  },

  {
    "chainId": 56,
    "address": "0x86cb49f8Bc766E883Db3da18d213770205314101",
    "name": "PHO",
    "symbol": "PHO",
    "decimals": 18,
    "logoURI": require('@/assets/img/logo.jpg')
  },

 
];
const KADbaselsit = [
  {
    name: 'KAD',
    symbol: 'KAD',
    address: '0xAa8FBD654000E9Fa5c2eDC3179C3C3100ebd53bB',
    chainId: 878,
    decimals: 18,
    balance: '',
    logoURI: require('@/assets/tokenlogo/KAD.png'),
  },
  {
    "chainId": 879,
    "address": "0x448bCE7d787104000873e2C0fFd2fF5121556f16",
    "name": "USDT",
    "symbol": "USDT",
    "decimals": 18,
    "logoURI": require('@/assets/tokenlogo/USDT.png')
  },

 
];
export function getAllPair(curA, curB) {
  let lpPair = [];
  let base = [];
  let NETChainId = store.state.netWorkConfig.chainId

  if (NETChainId == "0x42") {
    base = OKTbaselsit
  } else if (NETChainId == "0x38") {
    base = BNBbaselsit
  } else if (NETChainId == "0x89") {
    base = Polygonbaselsit
  }else if(NETChainId == "0x36E"){
    base = KADbaselsit
  }
  for (let i = 0; i < base.length; i++) {
    if (base[i].address != curA.address) {
      lpPair.push({ toCur: base[i], fromCur: curA });
    }

    if (base[i].address != curB.address) {
      lpPair.push({ toCur: curB, fromCur: base[i] });
    }

    lpPair.push({ toCur: curB, fromCur: curA });
  }
  return lpPair;
}
