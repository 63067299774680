<template>
  <div class="center">
    <div class="content">
      <div class="content-top">
        <div class="caption-two">
          <span>{{ $t('lang.swap394') }}</span>
        </div>
        <div class="content-left">
          <van-rate
            v-model="user_level.level"
            :count="user_level.max_level"
            color="#2A82E4"
            void-icon="star"
            void-color="#eee"
            readonly
          />
        </div>
        <div class="caption-two">
          <span>{{ $t('lang.swap395') }}（{{ $t('lang.swap396') }} {{user_level.times}}/{{next_level.times}}）</span>
        </div>
        <div class="content-left-per">
          <van-progress
            :percentage="(user_level.times / next_level.times * 100).toFixed(0)"
            stroke-width="8"
            color="linear-gradient(to right, #2A82E4, #6BC1FF)"
          />
        </div>
      </div>

      <div class="caption">
        <span>{{ $t('lang.swap369') }}</span>
      </div>
      <div class="have delegation-bg">
        <div>{{ $t('lang.swap370') }}</div>
        <div class="amount">{{votesData.ticket_num}}</div>
      </div>
      <div class="have commodity-bg">
        <div>{{ $t('lang.swap371') }}</div>
        <div class="amount">{{votesData.shop_ticket_num}}</div>
      </div>
      <div class="caption">
        <span>{{ $t('lang.swap372') }}</span>
      </div>
      <div class="spell-box">
        <div v-if="spellList.length == 0" class="nothing">
          <img src="@/assets/img/nothingBlank.png" class="nothing_img" />
          <span>{{ $t('lang.swap352') }}</span>
        </div>
        <van-list
          v-else
          v-model="loading"
          :finished="finished"
          finished-text
          :immediate-check="false"
          @load="getJoinList"
        >
          <div
            v-for="(item,index) of spellList"
            :key="index"
            class="spell-li"
            :style="{'border-bottom':spellList.length-1 ==index?'0':''}"
          >
            <div>
              <div class="serial" @click="copy" :data-clipboard-text="item.ticket_id">
                <span>{{ $t('lang.swap368') }}：{{item.ticket_id}}</span>
                <img src="@/assets/img/ranking.png" class="ranking_img" />
              </div>
              <div class="people">
                <span>{{ $t('lang.swap373') }}：{{item.is_leader == 1? $t('lang.swap382'): $t('lang.swap374') }}</span>
                <span style="margin-left: 20px;">{{ $t('lang.swap356') }}：{{item.join_num}}/10</span>
              </div>
            </div>
            <div>
              <van-button
                v-if="item.status ==0"
                class="participate-button"
                type="primary"
              >{{item.status_txt}}</van-button>
              <van-button
                v-else-if="item.status ==1"
                class="participate-button success"
                type="primary"
              >{{ item.status_txt }}</van-button>
              <van-button
                v-else
                class="participate-button fail"
                type="primary"
              >{{ item.status_txt }}</van-button>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import { goodApi } from '@/api/index';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      votesData: {}, //票数
      spellList: [],
      page: 1,
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
      rateValue: 1,
      next_level: undefined,
      user_level: undefined,
    };
  },
  methods: {
    init() {
      if (this.account) {
        this.getJoinList();
        this.goodSummary();
        this.getLevelInfo();
      }
    },
    getJoinList() {
      let data = {
        page: this.page,
      };
      goodApi.getJoinList(data, this.account).then(res => {
        // console.log(res);
        this.spellList = this.spellList.concat(res.data.list);
        this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
        this.page++;
        this.loading = false;
        if (this.page > this.totalPage) return (this.finished = true);
      });
    },
    goodSummary() {
      goodApi.goodSummary(this.account).then(res => {
        this.votesData = res.data;
        // console.log(res);
      });
    },
    getLevelInfo() {
      goodApi.levelInfo(this.account).then(res => {
        this.next_level = res.data.next_level;
        this.user_level = res.data.user_level;
      });
    },
    copy() {
      var clipboard = new Clipboard('.serial');
      clipboard.on('success', e => {
        this.$toast(this.$t('lang.swap300'));
        clipboard.destroy(); // 释放内存
      });
      clipboard.on('error', e => {
        this.$toast(this.$t('lang.swap301'));
        clipboard.destroy(); // 释放内存
      });
    },
  },
  watch: {
    account() {
      //获取账号
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
  created() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.center {
  display: flex;
  flex-direction: column;
  height: 100%;

  padding-top: 20px;
  .content {
    flex: 1;
    border-radius: 30px 30px 0 0;
    background: #ffffffff;
    padding: 20px;
  }

  .content-top{
    background: url('~@/assets/img/center-top.png') no-repeat;
    background-size: 100% 100%;
    padding: 20px 20px 20px 0px;
  }
  .caption {
    font-size: 18px;
    font-weight: bold;
    color: #222222;
    margin-bottom: 20px;
  }

  .caption-two {
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
  }
  .content-left {
    margin-bottom: 20px;
  }
  .content-left-per {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .have {
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
    border-radius: 20px;
    padding: 20px;
    .amount {
      text-align: right;
      font-weight: normal;
      margin-top: 36px;
    }
  }
  .delegation-bg {
    background: url('~@/assets/img/delegation_bg.png') no-repeat;
    background-size: 100% 100%;
    margin-bottom: 12px;
  }
  .commodity-bg {
    background: url('~@/assets/img/commodity_bg.png') no-repeat;
    background-size: 100% 100%;
    margin-bottom: 30px;
  }
  .spell-box {
    // min-height: 240px;
    border-radius: 30px;
    background: #f1f4f9ff;
    padding: 0 18px;
    .spell-li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dfe2e8;
      padding: 20px 0;
      .serial {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: #222222;
        margin-bottom: 6px;
        .ranking_img {
          display: block;
          width: 16px;
          height: 16px;
          margin-left: 10px;
        }
      }
      .people {
        font-size: 12px;
        color: #666f8a;
      }
      .participate-button {
        max-width: 90px;
        height: auto;
        font-size: 12px;
        color: #ffffff;
        border-radius: 10px;
        background: #00baadff;
        text-align: center;
        border: 0;
        padding: 8px 16px;

        /deep/ .van-button__text {
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
      }
      .fail {
        background: #ccccccff;
      }
      .success {
        background: #126bff;
      }
    }
    .nothing {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-weight: bold;
      color: #000;

      padding: 30px 0 30px;
      .nothing_img {
        width: 200px;
      }
    }
  }
}
</style>