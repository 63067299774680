<template>
  <div style="padding: 0 16px;">
    <div class="community_banner">
      <img src="@/assets/img/community_banner.png"
           class="community_banner" />
    </div>
    <div class="help">
      <div class="help_li">
        <span>{{ $t('lang.swap237') }}</span>
        <img src="@/assets/img/control.png"
             class="help_img" />
      </div>
      <div class="help_li">
        <span>{{ $t('lang.swap238') }}</span>
        <img src="@/assets/img/win.png"
             class="help_img" />
      </div>
    </div>
    <div class="community"
         v-for="(item, index) of MiningData"
         :key="index">
      <div class="community_head">
        <div class="head_left">
          <img class="head_img"
               src="@/assets/img/logo.jpg" />
          <span>PHO</span>
        </div>
      </div>
      <div class="content">
        <div class="earned">
          <div class="earned_left income">
            <div class="earned_title">
              <span>{{ $t('lang.swap233') }}</span>
            </div>
            <div>
              <span>{{self_lock}}</span>
            </div>
          </div>
        </div>
        <div class="earned"
             v-if="!item.allowanceToRouter">
          <div class="earned_left">
            <div class="earned_title"
                 style="margin-bottom: 10px">
              <span>{{ $t('lang.swap143') }}</span>
            </div>
            <div>
              <span>{{item.myNumber}}</span>
            </div>
          </div>
          <div class="button">
            <!-- <img class="button_img"
                 src="@/assets/img/reduce.png"
                 @click="selectV2(false)" /> -->

            <img class="button_img"
                 src="@/assets/img/plus.png"
                 @click="selectV2(true)"
                 v-if="item.myNumber == 0" />
            <img class="button_img"
                 src="@/assets/img/errplus.png"
                 v-else />
          </div>
        </div>

        <!-- 授权 -->
        <div class="staked"
             v-else>
          <div class="staked_title">
            <span>{{ $t('lang.swap234') }}</span>
          </div>
          <div>
            <van-button type="primary"
                        class="confirmBtn"
                        @click="confirmRemovalV2"
                        loading-text="Waiting..."
                        :loading="item.showApprove">
              <span>{{ $t('lang.swap14') }}</span>
            </van-button>
          </div>
        </div>

        <div class="specific_data">
          <div class="specific">
            <span>{{ $t('lang.swap231') }}</span>
            <div>
              <span class="special">{{total_consensus}}</span>
              <span class="company"
                    style="color:rgba(34, 34, 34, 1)">PHO</span>
            </div>
          </div>
          <div class="specific">
            <span>{{ $t('lang.swap232') }}</span>
            <div>
              <span class="special">{{consensus_num}}</span>
              <span class="company"
                    style="color:rgba(34, 34, 34, 1)">PHO</span>
            </div>
          </div>
          <!-- <div class="specific">
              <span>{{ $t('lang.swap149') }}</span>
              <div>
                <span class="special">{{item.pledge_num}}</span>
              </div>
            </div>-->
        </div>
      </div>
    </div>

    <div class="community">
      <div class="community_head">
        <div class="head_left">
          <img class="head_img"
               src="@/assets/img/logo.jpg" />
          <span>PHO</span>
        </div>
      </div>
      <div class="content">
        <div class="earned">
          <div class="earned_left income">
            <div class="earned_title">
              <span>{{ $t('lang.swap235') }}</span>
            </div>
            <div>
              <span>{{self_release}}</span>
            </div>
          </div>
        </div>
        <div class="earned">
          <div class="earned_left income">
            <div class="earned_title">
              <span>{{ $t('lang.swap401') }}</span>
            </div>
            <div>
              <span>{{self_release}}</span>
            </div>
          </div>
        </div>
        <div>
          <van-button disabled
                      class="income_button">
            <span>{{ $t('lang.swap66') }}</span>
          </van-button>
        </div>
      </div>
    </div>

    <van-popup v-model="MiningPopupShowTwo"
               round>
      <Popup @handleConfirm="MiningPopupfirmTwo"
             @getMyAddress="getBalanceTwo"
             :selectShow="selectShow"
             :max="max"></Popup>
    </van-popup>

    <!-- <van-popup v-model="MiningPopupShowTwo"
               round>
      <Popup @handleConfirm="MiningPopupfirmTwo"
             @getMyAddress="getBalanceTwo"
             :selectShow="selectShowTwo"
             :max="max"
             currencyCharacter="pho"></Popup>
    </van-popup> -->
  </div>
</template>

<script>
import Popup from '@/views/community/popup.vue';
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import lp from '../../methods/lp.js';
import lpOld from '../../methods/lpOld.js';
import phoLp from '../../methods/phoLp.js';
import community from '../../methods/community';
import { coin, market, conmunityApi } from '../../api/index';
import { significantDigits } from '@/utils/format.js';
export default {
  data () {
    return {
      MiningData: [
        {
          confirmBtnShow: false, //是否授权
          showApprove: false, //是否授权
          allowanceToRouter: false,
          myNumber: 0,
          profit: 0,
          balance: 0,
          max: 0,
          allReward: 0,
          todayReward: 0,
          userTvl: 0,
          maxTVL: 0,
          OtherTVL: 0,
          power: 0,
          pledge_num: 0,
        },
      ],
      selectShow: false, //true:质押,false:赎回
      selectShowTwo: false, //true:质押,false:赎回
      MiningPopupShow: false,
      MiningPopupShowTwo: false,
      max: 0,
      consensus_num: 0, //已共识数量
      total_consensus: 0, //总共识数量
      self_lock: 0, //个人锁仓
      self_release: 0, //个人可领取
    };
  },
  async created () {
    if (this.account) {
      this.handleApproveBtnShow();
      this.getBalanceOf();
      this.getDeposit();
      this.getWalletInfo()
      // this.getReward();
      // this.getpoolReward();
    }
  },

  methods: {
    //获取钱包信息
    getWalletInfo () {
      conmunityApi.communityWallet(this.account).then(res => {
        this.consensus_num = res.data.consensus_num
        this.total_consensus = res.data.total_consensus
        this.self_lock = res.data.self_lock
        this.self_release = res.data.self_release
      })
    },

    // 是否需要显示v1 v2授权按钮
    async handleApproveBtnShow () {
      if (this.account) {
        const erc20ContractV2 = getErc20Contract('0xC6894a787A41271271662d9553AF627B8A0717B7');
        const toV2 = '0x950B8706Cb876689CBacabEEF14f4385cD991C50';
        const allowanceV2 = await erc20ContractV2.methods.allowance(this.account, toV2).call();
        if (allowanceV2 == 0) {
          this.MiningData[0].allowanceToRouter = true;
        } else {
          this.MiningData[0].allowanceToRouter = false;
        }
      }
    },

    //获取v1
    async getBalanceOf () {
      if (this.account) {
        const erc20ContractV2 = getErc20Contract('0xC6894a787A41271271662d9553AF627B8A0717B7');
        const balanceV2 = await erc20ContractV2.methods.balanceOf(this.account).call();
        this.MiningData[0].balance = balanceV2 / Math.pow(10, 18);
      }
    },

    MiningPopupfirm () {
      this.MiningPopupShow = !this.MiningPopupShow;
    },

    MiningPopupfirmTwo () {
      this.MiningPopupShowTwo = !this.MiningPopupShowTwo;
    },

    //v2授权按钮
    confirmRemovalV2 () {
      this.MiningData[0].showApprove = true;
      const erc20Contract = useErc20Contract('0xC6894a787A41271271662d9553AF627B8A0717B7');
      const amo = MaxUint256.toString();
      erc20Contract.methods
        .approve('0x950B8706Cb876689CBacabEEF14f4385cD991C50', amo)
        .send({
          from: this.account,
        })
        .on('confirmation', res => {
          this.MiningData[0].showApprove = false;
          this.MiningData[0].allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.MiningData[0].showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },

    //质押
    getBalanceTwo (res) {
      let amount = res * 1 * Math.pow(10, 18) + '';
      this.MiningPopupShowTwo = false;
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap240')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };
      community.stake(this.account, amount, transactionsDetail).then(res => {
        let data = {
          tx_hash: res,
        };
        conmunityApi.communityEntryMark(data, this.account).then(res => {
          if (res.code == 200) {
            this.$toast(this.$t('lang.swap241'));
            this.getDeposit();
            this.getBalanceOf();
          }
        });
      });
    },

    // v1质押
    getBalance (res) {
      let amount = res * 1 * Math.pow(10, 18) + '';
      this.MiningPopupShow = false;
      if (this.selectShow) {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap158')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };
      }
      // if (this.selectShow) {
      //   const transactionsDetail = {
      //     title: '',
      //     message: `${this.$t('lang.swap158')} `,
      //     addedTime: new Date().getTime(),
      //     from: this.account,
      //   };
      //   if (this.choiceIndex == 0) {
      //     market.starCardUpdate(this.account).then(res => { });
      //     lp.deposit(this.account, 0, amount, transactionsDetail)
      //       .then(res => {
      //         this.getDeposit();
      //         this.getBalanceOf();
      //         market.starCardUpdate(this.account).then(res => { });
      //       })
      //       .catch(e => { });
      //   } else {
      //     lpOld
      //       .deposit(this.account, 0, amount, transactionsDetail)
      //       .then(res => {
      //         this.getDeposit();
      //         this.getAllBalance();
      //       })
      //       .catch(e => { });
      //   }

      //   // }
      // } else {
      //   const transactionsDetail = {
      //     title: '',
      //     message: `${this.$t('lang.swap159')} `,
      //     addedTime: new Date().getTime(),
      //     from: this.account,
      //   };
      //   if (this.choiceIndex == 0) {
      //     lp.withdraw(this.account, 0, amount, transactionsDetail)
      //       .then(res => {
      //         this.getDeposit();
      //         this.getBalanceOf();
      //       })
      //       .catch(e => { });
      //   } else {
      //     lpOld
      //       .withdraw(this.account, 0, amount, transactionsDetail)
      //       .then(res => {
      //         this.getDeposit();
      //         this.getAllBalance();
      //       })
      //       .catch(e => { });
      //   }
      // }
    },

    //v1 v2收割
    harvest (i) {
      if (this.MiningData[0].profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap146')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };
      lp.harvest(this.account, 0, transactionsDetail)
        .then(res => {
          // this.getReward();
        })
        .catch(e => { });
    },

    phoHarvest () {
      if (this.phoData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap146')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      phoLp.claimReward(this.account, transactionsDetail).then(res => {
        // this.getReward();
      });
    },

    //v1 v2获取质押数量
    getDeposit () {
      community.stakeInfo(this.account).then(res => {
        this.MiningData[0].myNumber = res / Math.pow(10, 18);
      });
    },

    //v1 v2获取收益
    // getReward () {
    //   lp.pendingReward('0', this.account).then(res => {
    //     this.MiningData[0].profit = res / Math.pow(10, 18);
    //   });

    //   lpOld.pendingReward('0', this.account).then(res => {
    //     this.hashData[0].myNumber = res.amount / Math.pow(10, 18);
    //   });

    //   phoLp.earned(this.account).then(res => {
    //     console.log('获取当前收益', res);
    //     this.phoData.profit = res / Math.pow(10, 18);
    //   });
    // },

    //v1 v2激励
    // getpoolReward () {
    //   lp.poolReward().then(res => {
    //     this.MiningData[0].allReward = res[0];
    //     this.MiningData[0].todayReward = res[1];
    //   });
    // },

    //判断当前是取出还是增加
    selectV2 (state) {
      this.selectShow = state;
      if (state) {
        this.max = significantDigits(this.MiningData[0].balance);
      } else {
        this.max = significantDigits(this.MiningData[0].myNumber);
      }
      this.MiningPopupfirmTwo();
    },
    significantDigits,
  },

  components: {
    Popup,
  },
  watch: {
    account () {
      //获取账号
      if (this.account) {
        this.handleApproveBtnShow();
        this.getBalanceOf();
        this.getDeposit();
        // this.getReward();
        // this.getpoolReward();
      }
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.community_banner {
  width: 100%;
  margin: 20px 0 0;
}
.help {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .help_li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 46%;
    // font-size: 14px;
    font-weight: bold;
    color: #000;
    background: #ffffff;
    // box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 20px;
    .help_img {
      width: 100px;
      height: 70px;
      display: block;
      margin-top: 6px;
    }
  }
}
.community {
  background: #ffffff;
  border-radius: 8px;
  padding: 0 0 10px;
  overflow: hidden;
  margin: 10px 0 20px;

  .community_head {
    display: flex;
    justify-content: space-between;
    // box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.3);
    padding: 20px 14px 0px;
    .head_left {
      display: flex;
      // align-items: center;
      font-size: 18px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      padding: 10px 0;
      .head_img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin-right: 10px;
        // transform: translate(0%, -10%);
      }
    }
    .head_right {
      display: flex;
      padding-top: 10px;
      .exhibition {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        color: #666666;
        margin-right: 40px;
        .value {
          color: #333333;
          margin-top: 6px;
        }
      }
    }
  }
  .content {
    padding: 4px 24px 14px;
    .earned {
      display: flex;
      justify-content: space-between;
      background: #f1f4f9;
      border-radius: 10px;
      padding: 20px;
      margin-top: 20px;

      .earned_left {
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        .earned_title {
          font-size: 14px;
          color: #666666;
          // margin-bottom: 10px;
        }
      }
      .income {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .button {
        display: flex;
        align-items: flex-end;
        .confirmBtn {
          height: auto;
          font-size: 16px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          border-radius: 16px;
          padding: 4px 20px;
        }
        .button_img {
          width: 26px;
          height: 26px;
          margin-left: 10px;
        }
      }
    }
    .income_button {
      width: 100%;
      height: auto;
      font-size: 16px;
      font-weight: bold;
      border-radius: 16px;
      padding: 20px 0;
      margin-top: 20px;
    }
    .staked {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #f1f4f9;
      border-radius: 10px;
      padding: 10px 20px;
      margin-top: 20px;
      .staked_title {
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .confirmBtn {
        height: auto;
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #fff;
        border-radius: 16px;
        padding: 20px 110px;
        margin-top: 20px;
      }
    }
    .tvl {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-family: Segoe UI-Bold, Segoe UI;
      font-weight: bold;
      color: #333333;
      background: #ffffff;
      border: 1px solid #3399ff;
      // box-shadow: 0px 1px 2px 1px #3399ff;
      border-radius: 10px;
      padding: 6px 12px;
      margin-top: 12px;
      div {
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
      .money_img {
        width: 16px;
        height: 16px;
      }
    }

    .specific_data {
      background: #ffffff;
      border-radius: 10px;
      margin-top: 16px;
      .specific {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: Segoe UI-Bold, Segoe UI;
        font-weight: bold;
        color: rgba(153, 153, 153, 1);
        margin-top: 10px;
        .special {
          color: #3399ff;
        }
        .company {
          margin-left: 6px;
        }
      }
    }
  }
  .down {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 20px;
  }
}
</style>