<template>
      <div class="footer">
        <div class="f_li" v-for="(item, index) in footerlist" @click="bNav(item.router, item.i, index)" :key="index">
          <img :src="item.image" alt="" v-if="active != index" />
          <img :src="item.activeimage" alt="" v-else />
          <span v-if="active != index">{{ $t(item.name) }}</span>
          <span v-else style="color: rgba(3, 3, 3, 1);">{{ $t(item.name) }} </span>
        </div>
      </div>
  

  </template>
    
  <script>
  import { mapState } from 'vuex';
  import { user } from '@/api/index';
  
  export default {
    data() {
      return {
        active: 0,
        gonshow: false,
        dianshow: false,
        footerlist: [
          {
            image: require('@/assets/Footer/Footer_home_false.png'),
            activeimage: require('@/assets/Footer/Footer_home_true.png'),
            name: '首页',
            router: 'HomeIndex',
            i: '',
          },
          {
            image: require('@/assets/Footer/Footer_swap_false.png'),
            activeimage: require('@/assets/Footer/Footer_swap_true.png'),
            name: '交易',
            router: 'Swap',
            i: '',
          },
          {
            image: require('@/assets/Footer/Footer_pool_false.png'),
            activeimage: require('@/assets/Footer/Footer_pool_true.png'),
            name: '矿池',
            router: 'MiningOKT',
            i: '',
          },
          {
            image: require('@/assets/Footer/Footer_drop_false.png'),
            activeimage: require('@/assets/Footer/Footer_drop_true.png'),
            name: '空投',
            router: 'DropIndex',
            i: '',
          },
          {
            image: require('@/assets/Footer/Footer_inivite_false.png'),
            activeimage: require('@/assets/Footer/Footer_inivite_true.png'),
            name: '俱乐部',
            router: 'InviteIndex',
            i: '',
          },
        ],
      
      };
    },
    watch: {
      $route(to, from) {
        console.log('$route', to, from);
        this.listRouteActive(to.name);
      },
      account() {
      
      },
    },
    computed: {
      ...mapState(['account', 'lang']),
    },
    created() {
      this.listRouteActive(this.$route.name);
    
    },
    mounted() {},
    methods: {
      
      gonshowfun() {
        this.gonshow = !this.gonshow;
      },
      listRouteActive(router) {
        console.log(router);
        for (var i in this.footerlist) {
          if (this.footerlist[i].router == router) {
            this.active = i;
            this.$forceUpdate();
            console.log(i, 'i');
          }
        }
      },
      bNav(router, i, index) {
        this.active = index;
  
        if (router != '') {
          this.$router.push({ name: router });
        } else {
        }
      },
     
    },
   
  };
  </script>
    
  <style lang="less" scoped>
 
  
  .footer {
    z-index: 99;
    position: fixed;
    width: 100%;
    height: 76px;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 15px;
    box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.12);
    img {
      width: 24px;
      height: 24px;
      margin-bottom: 5px;
    }
  
    div {
      display: flex;
      flex-direction: column;
  
      align-items: center;
      height: 100%;
      color: #6f7d96ff;
      font-size: 10px;
      font-weight: 500;
      font-family: 'PingFang';
    }
  }
  
  .gonju {
    width: 345px;
    height: 266px;
    border-radius: 30px 0 0 0;
    opacity: 1;
    background: var( --text-color);
    position: fixed;
    right: 0;
    bottom: 76px;
    padding: 25px 0px 0px 20px;
    z-index: 99;
  
    .g_li {
      width: 100%;
      height: 25%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(217, 217, 217, 1);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 22.4px;
      color: rgba(34, 34, 34, 1);
    }
  
    .zhezhao {
      width: 100%;
      height: 400px;
      background: #ddd;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
    }
  }
  .dian {
    height: 134px;
    bottom: 76px;
    .g_li {
      height: 50%;
    }
  }
  </style>