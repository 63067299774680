<template>
    <div class="home">
      <div class="content">
        <NavBarVue></NavBarVue>
      <router-view class="router" />
      </div>
    </div>
  </template>
  <script>
import NavBarVue from '@/components/NavBar.vue';
  
  export default {
    name: 'Home',
    data () {
      return {
        VisualChartShow: false
      };
    },
    methods: {
      
    },
    // mounted () {
    //   this.$eventBus.$on("DisplayChart", (val) => {
    //     console.log('收到的数据：', val)
    //   });
    // },
    components : {
        NavBarVue
    },
    
    // destroyed () {
    //   this.$eventBus.$off("DisplayChart");
    // }
  };
  </script>
  
  <style lang="less" scoped>
  .home {
    // height: 100%;
    display: flex;
    flex-direction: column;
    background: #f0f4f9;
  
    // background: url('../assets/img/logo.jpg') no-repeat;
    // background-size: 255px 240px;
    .nav {
      display: flex;
      padding-left: 30px;
      height: 50px;
      .navItem {
        font-size: 18px;
        margin-right: 25px;
        line-height: 50px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 700;
      }
      .selectd {
        color: @green;
        position: relative;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          height: 4px;
          width: 100%;
          background: @green;
          border-radius: 2px 2px 0 0;
        }
      }
    }
    .content {
      flex: 1;
      // width: 100%;
      overflow: auto;
  
      // height: 100%;
  
      // background: #6cf;
      // background-size: 255px 240px;
      // padding: 0 16px;
      .router {
        height: 100%;
        margin: 0 auto;
        max-width: 375px;
        // padding-top: 24px;
        // padding-bottom: 20px;
      }
    }
  }
  @media screen and (min-width: 700px) {
    .home {
      .content {
        padding: 50px 0 80px;
      }
    }
  }
  </style>
  <style lang="less">
  .van-button--primary {
    color: #fff !important;
  }
  </style>
  