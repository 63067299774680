<template>
  <div class="recharge">
    <div class="choose-box">
      <span @click="chooseClick(0)" :class="chooseIndx == 0 ? 'selected' : ''">{{ $t('lang.swap341') }}</span>
      <span @click="chooseClick(1)" :class="chooseIndx == 1 ? 'selected' : ''">{{ $t('lang.swap342') }}</span>
    </div>

    <!-- 充值 -->
    <div class="content" v-show="chooseIndx == 0">
    
      <div @click="open" class="general-box">
        <div v-if="rechargeCur.coin_en">{{ rechargeCur.coin_en }}</div>
        <div v-else style="color: #666666">{{ $t('lang.swap343') }}</div>
        <img class="down-arrow" src="@/assets/img/down_arrow.png" mode="widthFix" />
      </div>
      <div class="subject">
        <span>{{ $t('lang.swap344') }}</span>
      </div>
      <div class="input-box">
        <van-field class="input" v-model="rechargeAmount" :placeholder="$t('lang.swap345')" />
      </div>
      <div class="balance-text">
        <span>{{ $t('lang.swap346') }}：{{ walletBalance }}</span>
      </div>
      <!-- <div class="balance-text">
        <span>{{ $t('lang.swap347') }}：0</span>
      </div> -->
      <div class="button" @click="transfer">
        <span>{{ $t('lang.swap309') }}</span>
      </div>
    </div>

    <!-- 提现 -->
    <div class="content" v-show="chooseIndx == 1">
      <div @click="open" class="general-box">
        <div v-if="withdrawCur.coin_en">{{ withdrawCur.coin_en }}</div>
        <div v-else style="color: #666666">{{ $t('lang.swap343') }}</div>
        <img class="down-arrow" src="@/assets/img/down_arrow.png" mode="widthFix" />
      </div>
      <div class="subject">
        <span>{{ $t('lang.swap348') }}</span>
      </div>
      <div class="input-box">
        <van-field class="input" v-model="withdrawAmount" :placeholder="$t('lang.swap349')" />
      </div>
      <!-- <div class="balance-text">
        <span>{{ $t('lang.swap346') }}：0</span>
      </div> -->
      <div class="balance-text">
        <span>{{ $t('lang.swap347') }}：{{ withdrawBalance }}</span>
      </div>
      <div class="button" @click="withdrawSubmit">
        <span>{{ $t('lang.swap309') }}</span>
      </div>
    </div>

    <!-- 弹窗 -->
    <van-popup v-model="show" position="bottom" round>
      <div class="popup-box">
        <div class="cancel">
          <span>{{ $t('lang.swap350') }}</span>
          <img @click="close" class="cancel-img" src="@/assets/img/cancel.png" mode="widthFix" />
        </div>
        <div v-for="(item, index) of currencyList" :key="index" @click="currencyClick(item)" class="popup-li">
          <img class="currency-img" :src="image_domain + item.logo" mode="widthFix" />
          <span>{{ item.coin_en }}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { goodApi, rewardApi } from '../../api/index';
import { mapState } from 'vuex';
import { getErc20Contract, getJackPairContract } from '@/utils/contractHelp';
import usdt from '../../methods/usdt.js';
import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, removal, gasProcessing } from '@/utils/format.js';
import Web3 from 'web3';
export default {
  data() {
    return {
      chooseIndx: 0, //0:充值, 1:提现
      rechargeAmount: '', //充值数量
      withdrawAmount: 0, //提现数量
      currencyList: [],
      rechargeCur: {},
      withdrawCur: {},
      show: false,
      image_domain: '',
      receiver: undefined,
      walletBalance: 0,
      withdrawList: [],
      rechargeList: [],
      walletWithdrawBalance: 0, //提现的钱包余额
      withdrawBalance: 0, //平台余额
    };
  },
  created() {
    // if (this.$route.query.id) {
    //   this.chooseIndx = this.$route.query.id;
    // }
    this.init();
  },
  watch: {
    account() {
      //获取账号
      this.init();
    },
  },
  computed: {
    ...mapState(['account','netWorkConfig']),
  },
  methods: {
    init() {
      if (this.account) {
        this.getConfig();
      }
    },
    //充值
    transfer() {
      if (this.rechargeAmount > this.walletBalance) {
        this.$toast(this.$t('lang.swap11'));
        return;
      }
      usdt
        .transfer(this.account, this.receiver, accMul(this.rechargeAmount, Math.pow(10, 18)) + '', this.rechargeCur.contract)
        .then(res => {
       
          let data = {
            chain_id:this.rechargeCur.chain_id,
            tx_hash: res,
          };
          goodApi.entryMark(data, this.account).then(res => {
            if (res.code == 200) {
              this.init();
              this.$toast(this.$t('lang.swap391'));
            } else {
            }
          });
        })
        .catch(e => {});
    },
    getConfig() {
      goodApi.config(this.account).then(res => {
        this.image_domain = res.data.image_domain;
        this.getCurrencyList();
        this.getWithdrawList();
       
      });
    },
    getCurrencyList() {
      goodApi.rechargeConfigs(this.account).then(res => {
        let key = ''
         if(this.netWorkConfig.key == 'OKT'){
          key = 'OKC'
         }else if(this.netWorkConfig.key == 'Polygon'){
          key ='Polygon'
         }else if(this.netWorkConfig.key == 'BNB'){
          key ='BNB'
         }
         let list = res.data.configs
         for(let i in list){
          if(list[i].chan_name == key){
            
                    this.rechargeList =list[i].list;
                   }
         }
       
     
        this.receiver = res.data.receiver;
      });
    },
 
    getWithdrawList() {
      goodApi.withdrawConfigs(this.account).then(res => {
        let key = ''
         if(this.netWorkConfig.key == 'OKT'){
          key = 'OKC'
         }else if(this.netWorkConfig.key == 'Polygon'){
          key ='Polygon'
         }else if(this.netWorkConfig.key == 'BNB'){
          key ='BNB'
         }
         let list = res.data.configs
         for(let i in list){
          if(list[i].chan_name == key){
            this.withdrawList =list[i].list;
                   }
         }
     
      });
    },

    withdrawSubmit() {
      if (this.withdrawAmount > this.withdrawBalance) {
        this.$toast(this.$t('lang.swap11'));
        return;
      }
      let web3 = new Web3(window.ethereum);
      if(this.netWorkConfig.key == 'Polygon'){
        this.withdrawCur.chain_id = 11
      }
      web3.eth.personal.sign(web3.utils.fromUtf8('Withdraw'), this.account, (err, res) => {
        let da = {
          chain_id:this.withdrawCur.chain_id,
          coin_id: this.withdrawCur.coin_id,
          amount: this.withdrawAmount * 1,
          signature: res,
        };
        goodApi.withdraw(da, this.account).then(res => {
          if (res.code == 200) {
            this.$toast(this.$t('lang.swap393'));
            this.withdrawAmount = 0;
            rewardApi.getWallet({ coin_key: this.withdrawCur.coin_en }, this.account).then(res => {
              this.withdrawBalance = res.data.usable;
            });
          } else {
            this.$toast(res.msg);
          }
        });
      });
    },

    chooseClick(i) {
      this.chooseIndx = i;
    },
    currencyClick(item) {
      if (this.chooseIndx == 0) {
        this.rechargeCur = item;
        this.getWalletBalance();
      } else {
        this.withdrawCur = item;
        this.getWithdrawWalletBalance();
      }
      this.close();
    },
    async getWalletBalance() {
      const erc20Contract = getErc20Contract(this.rechargeCur.contract);
      let bal = await erc20Contract.methods.balanceOf(this.account).call();
      this.walletBalance = (bal * 1) / Math.pow(10, 18).toFixed(2);
    },
    async getWithdrawWalletBalance() {
      // const erc20Contract = getErc20Contract(this.withdrawCur.contract);
      // let bal = await erc20Contract.methods.balanceOf(this.account).call();
      // this.walletWithdrawBalance = (bal * 1) / Math.pow(10, 18).toFixed(2);

      rewardApi.getWallet({ coin_key: this.withdrawCur.platform_key }, this.account).then(res => {
        this.withdrawBalance = res.data.usable;
      });
    },
    open() {
      if (this.chooseIndx == 0) {
        this.currencyList = this.rechargeList;
      } else {
        this.currencyList = this.withdrawList;
      }
      console.log(this.currencyList, 'aaaaaaaaaa');
      this.show = true;
    },
    openChanId(){
           
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.recharge {
  display: flex;
  flex-direction: column;
  height: 100%;
  .choose-box {
    padding: 4px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    background: #ffffff;
    border-radius: 30px;
    margin: 20px;
    border: 1px solid rgba(60, 114, 241, 1);
    span {
      width: 50%;
      text-align: center;
      border-radius: 30px;
      padding: 16px 0;
    }
    .selected {
      color: #ffffff;
      background: #346ff1;
    }
  }
  .content {
    flex: 1;
    background: #ffffff;
    border-radius: 30px;
    padding:  20px;
    margin-top: 20px;
    margin: 0 20px;
    border: 1px solid rgba(242, 242, 242, 1) !important;
     
  }
  .general-box {
   
    display: flex;  
    align-items: center;
    justify-content: space-between;
    // height: 110px;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    border-radius: 16px;
    background: #f0f4f9;
    padding: 20px;
    margin: 20px 0 30px;
    .down-arrow {
      width: 14px;
      height: 10px;
    }
  }
  .subject {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 12px;
  }
  .input-box {
    border-radius: 16px;
    background: #f0f4f9;
    padding: 16px;
    margin-bottom: 20px;
    .input {
      font-size: 14px;
      background: #00000000;
    }
  }
  .balance-text {
    font-size: 14px;
    font-weight: bold;
    color: #999999;
    margin-bottom: 12px;
  }
  .button {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    border-radius: 16px;
    background: #356ef1;
    text-align: center;
    padding: 20px 0;
    margin-top: 30px;
  }
}

.popup-box {
  background: #fff;
  border-radius: 30px 30px 0 0;
  .cancel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: #222222;
    border-bottom: 1px solid #ededed;
    padding: 16px 20px 16px;
    .cancel-img {
      width: 14px;
      height: 14px;
    }
  }
  .popup-li {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    padding: 16px 20px;
    .currency-img {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
}
</style>