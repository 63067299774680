<template>
  <div class="settingsModal">
    <div class="van-dialog__header">
      <div class="text">{{ $t('lang.swap40') }}</div>
      <div class="cancel"
           @click="handleClose"></div>
    </div>
    <div class="van-hairline--top van-dialog__footer"></div>
    <div class="van-content">
      <div class="van-content-top">
        <div class="hint">{{ $t('lang.swap275') }}</div>
        <div class="van-content-title">{{ $t('lang.swap41') }}</div>
        <div class="van-content-top-tab">
          <van-button :type="gasPriceType == 1 ? 'primary' : 'info'"
                      class="gasPrice"
                      @click="handlePrice(1)">{{ $t('lang.swap42') }}</van-button>
          <van-button :type="gasPriceType == 2 ? 'primary' : 'info'"
                      class="gasPrice"
                      @click="handlePrice(2)">{{ $t('lang.swap43') }}</van-button>
          <van-button :type="gasPriceType == 3 ? 'primary' : 'info'"
                      class="gasPrice"
                      @click="handlePrice(3)">{{ $t('lang.swap44') }}</van-button>
        </div>
      </div>

      <div class="van-content-bottom">
        <div class="hint">{{ $t('lang.swap276') }}</div>
        <div class="van-content-title">{{ $t('lang.swap8') }}</div>
        <div class="van-content-bottom-tab">
          <van-button :type="slippage == 0.1 ? 'primary' : 'info'"
                      class="cur"
                      @click="handleSlippage(0.1)">0.1%</van-button>
          <van-button :type="slippage == 0.5 ? 'primary' : 'info'"
                      class="cur"
                      @click="handleSlippage(0.5)">0.5%</van-button>
          <van-button :type="slippage == 1 ? 'primary' : 'info'"
                      class="cur"
                      @click="handleSlippage(1)">1.0%</van-button>
          <div class="inputDiv1">
            <van-field type="number"
                       class="slipInput"
                       input-align="center"
                       @input="onInputDeadlineSlippage"
                       v-model="slippage" />
            <div class="uint">%</div>
          </div>
        </div>
        <div class="tip1"
             v-if="slippage >= 50">{{ $t('lang.swap46') }}</div>
        <div class="tip1"
             v-else-if="slippage == 0">{{ $t('lang.swap47') }}</div>
        <div class="tip1"
             v-else-if="slippage > 5">{{ $t('lang.swap48') }}</div>
        <div class="van-content-bottom-select">
          <div class="select-list">
            <div class="label">{{ $t('lang.swap49') }}</div>
            <van-field type="number"
                       class="deadInput"
                       input-align="center"
                       @input="onInputDeadline"
                       v-model="deadline" />
          </div>
          <!-- <div class="select-list">
            <div class="label">专家模式</div>
            <div class="select"><van-switch v-model="checked" size="25px" /></div>
          </div> -->
          <div class="select-list">
            <div class="label">{{ $t('lang.swap51') }}</div>
            <div class="select">
              <van-switch v-model="multipath"
                          size="25px"
                          @change="handleMultipath" />
            </div>
          </div>
          <div class="select-list">
            <div class="label">{{ $t('lang.swap184') }}</div>
            <div class="select">
              <van-switch v-model="expert"
                          size="25px"
                          @change="handleExpert" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Web3 from 'web3';
export default {
  data () {
    return {
      gasPrice: '',
      gasPriceType:1,
      slippage: '',
      deadline: 20,
      multipath: false,
      expert: false,
    };
  },
  computed: {
    ...mapState('ModalStore', {
      settingsModal: state => state.settingsModal,
    }),
  },
  created () {
    console.log('this.$store.state.gasPriceType',this.$store.state.gasPriceType);
    this.gasPriceType =this.$store.state.gasPriceType;
    this.getGas()
    this.slippage = this.$store.state.slippage;
    this.deadline = this.$store.state.deadline;
    this.multipath = this.$store.state.multipath;
  },
  methods: {
    async getGas(){
      const web3js = new Web3(window.ethereum); //
    let gasPrice = await  web3js.eth.getGasPrice()
    this.gasPrice = gasPrice
   
    
    },
    handleClose () {
      this.$emit('listenClose');
    },
    handlePrice (type) {
      this.gasPriceType =type
      let gasa = 1;
      if(type == 1){
        gasa = this.gasPrice * 1.5;
      }else if(type == 2){
        gasa = this.gasPrice * 2;
      }else if(type == 3){
        gasa = this.gasPrice * 2.5;
      }
      
      localStorage.setItem('gasPrice', gasa);
      this.$store.commit('SETGASTYPE',type);
      this.$store.commit('SET_GASPRICE', gasa);
    },
    handleSlippage (slippage) {
      this.slippage = slippage;
      localStorage.setItem('slippage', slippage);
      this.$store.commit('SET_SLIPPAGE', slippage);
    },
    handleMultipath (multipath) {
      this.multipath = multipath;
      localStorage.setItem('multipath', multipath);
      this.$store.commit('SET_MULTIPATH', multipath);
    },
    onInputDeadline (deadLine) {
      this.deadLine = deadLine;
      localStorage.setItem('deadLine', deadLine);
      this.$store.commit('SET_DEADLINE', deadLine);
    },
    onInputDeadlineSlippage (slippage) {
      if (this.slippage >= 50) {
        this.slippage = slippage;
        return;
      }
      this.slippage = slippage;
      localStorage.setItem('slippage', slippage);
      this.$store.commit('SET_SLIPPAGE', slippage);
    },
    //专家模式
    handleExpert (ex) {
      this.expert = ex;
      localStorage.setItem('expert', this.expert);
      this.$store.commit('SET_EXPORT', this.expert);
    },
  },
};
</script>

<style lang="less" scoped>
.settingsModal {
  // width: 350px;
  .van-dialog {
    height: 500px;
    overflow: auto;
  }
  .van-dialog__header {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #222222;
    border-bottom: 1px solid #f1f4f9;
    padding: 20px 0;
    margin: 0 20px;
    .cancel {
      width: 16px;
      height: 16px;
      background-image: url('~@/assets/img/cancel.png');
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  .van-content {
    padding: 20px;
  }
  .hint {
    font-size: 14px;
    color: #356ef1;
    margin-bottom: 20px;
  }
  .van-content-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  .van-content-top-tab {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    .gasPrice {
      margin-right: 15px;
      width: 94px;
      font-size: 16px;
      text-align: center;
      height: 40px;
      line-height: 32px;
      color: #333333;
      background: #f1f4f9;
      border-radius: 8px;
      cursor: pointer;
      // padding: 8px 18px;
    }
    /deep/ .van-button--primary {
      color: #fff;
      background-color: #356ef1;
      border: 1px solid #356ef1;
    }
  }
  .active {
    background-color: #12cdb7;
    color: #ffffff;
  }
  //bottom
  .van-content-bottom {
    margin-top: 30px;

    .van-content-bottom-title {
      font-size: 14px;
    }
  }
  .van-content-bottom-tab {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .cur {
      margin-right: 15px;
      width: 64px;
      font-size: 12px;
      text-align: center;
      height: 40px;
      line-height: 32px;
      border-radius: 8px;
      color: #333333;
      background: #f1f4f9;
      cursor: pointer;
    }
    /deep/ .van-button--primary {
      color: #fff;
      background-color: #3399ff;
      border: 1px solid #3399ff;
    }
    .uint {
      color: #12cdb7;
    }
    .inputDiv1 {
      display: flex;
      color: #12cdb7;
      width: 84px;
      align-items: center;
      .slipInput {
        font-size: 12px;
        border-radius: 4px;
        height: 40px;
        line-height: 40px;
        background: #f1f4f9;
      }

      .uint {
        color: #356ef1;
        margin-left: 4px;
      }
    }
  }
  //select
  .van-content-bottom-select {
    margin-top: 30px;
    .select-list {
      display: flex;
      font-size: 12px;
      margin-top: 20px;
      align-items: center;
      justify-content: space-between;
      .label {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
      }
      .deadInput {
        width: 64px;
        height: 30px;
        line-height: 30px;
        border-radius: 4px;
        background: #f1f4f9;
        border: 0px;
        padding-left: 10px;
        color: #12cdb7;
      }
      .van-cell {
        padding: 0;
      }
      .van-cell::after {
        content: none;
      }
    }
  }
  .tip1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    color: #cc9300;
  }
}
</style>
