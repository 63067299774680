<template>
    <div style=" background:rgba(255, 255, 255, 1); ">
   
      <div class="shoyi">
     
     
        <div class="s_boxs">
          <div class="b_title">
            <span>{{ $t('时间') }}</span>
            <span style="margin-left: 30px;">{{ $t('类型') }}</span>
            <span>{{ $t('数量') }}</span>
            <span>{{ $t('币种') }}</span>
          </div>
          <div class="c_wlist" v-if="shoyilist.length == 0">
        <img src="@/assets/homelogo/wujilu.png" alt="">
        <p>{{ $t('暂未记录~') }}</p>
     </div>
     <div v-else class="boxx">
      <van-list
    v-model="loading"
    :finished="finished"
    :finished-text="`${$t('lang.d145')}`"
    @load="onLoad"
    class="boxx"
  >
  <div v-for="item in shoyilist" class="boxs" >
            <div style="width: 30%; flex-shrink: 0;">{{ item.created_time }}</div>
            <div style="color: #fff;">{{item.behavior_txt}}</div>
            <div style="color: #39dba3ff;" v-if="item.usable_change>=0">＋{{ item.usable_change }}</div>
            <div style="color: red;" v-else>{{ item.usable_change }}</div>
            <div style="color: #999999;">{{ item.token_name}}</div>
            
          </div>
  </van-list>
      <!-- <div v-for="item in shoyilist" class="boxs" >
            <div style="width: 30%; flex-shrink: 0;">{{ item.created_time }}</div>
            <div style="color: #fff;">{{item.behavior_txt}}</div>
            <div style="color: #39dba3ff;" v-if="item.usable_change>=0">＋{{ item.usable_change }}</div>
            <div style="color: red;" v-else>{{ item.usable_change }}</div>
            <div style="color: #999999;">{{ item.token_name}}</div>
            
          </div> -->
     </div>
        
        </div>
    </div>
    </div>
    
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import {moneymask} from '@/api/index';
  export default {
    name: '',
    components: {
      
    },
    data() {
      return {
        shoyilist :[ ],
        loading:false,
        finished:false,
        page:1,
        page_size:10,
        isrun :0
      }
    },
    computed: {
      ...mapState(['account','lang']),
    },
    created() {
      this.getshoyi()
    },
    watch: {
      account () {
        //获取账号
        this.getshoyi();
      },
      lang (){
        this.getshoyi();
      }
    },
    methods: {
        getshoyi (){
        
          let data ={
                  page:1,
                  page_size:this.page_size,
                  behavior_arr:'[-1]',
                  token_id:0
              }
              moneymask.getjiluliebiao(data,this.account).then(res =>{
                  console.log(res);
                  this.shoyilist =res.data.list
                  
              })
        },
        onLoad() {
        // 异步更新数据
        // setTimeout 仅做示例，真实场景中一般为 ajax 请求
        setTimeout(() => {
          this.isrun = this.shoyilist.length;
          this.page_size+=10;
         
        let data ={
                  page:1,
                  page_size:this.page_size,
                  behavior_arr:'[-1]',
                  token_id:0
              }
              moneymask.getjiluliebiao(data,this.account).then(res =>{
                  console.log(res);
                  this.shoyilist =res.data.list
                   if(this.isrun ==this.shoyilist.length){
                    this.finished =true
                   }
              })
          // 加载状态结束
          this.loading = false;
  
          // 数据全部加载完成
          // if (this.shoyilist.length >= 40) {
          //   this.finished = true;
          // }
        }, 1000);
      
      },
    },
  }
  </script>
  
  <style lang="less" scoped>
  .shoyi {
    width: 100%;
  background: #fff;
    padding: 20px;
    height: 100%;
    border-radius: 20px 20px 0 0;
  }
  
  .s_title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    font-family: "PingFang";
  
  
  }
  
  .s_boxs {
    width: 100%;
  
    border-radius: 20px;
    box-sizing: border-box;
    padding: 16px;
  
    background: #fff;
    border: 1px solid #E8E8E8;
  
    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  
    .b_title {
      color: #999999ff;
      font-size: 14px;
      font-weight: 500;
      font-family: "PingFang";
    }
  
    .b_cont {
      color: #999999ff;
      font-size: 14px;
      font-weight: 700;
      font-family: "PingFang";
    }
  }
  
  .boxx {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    max-height: 400px;
    overflow-y: scroll;
  }
  
  .c_wlist {
    width: 100%;
    height: 219px;
    border-radius: 20px;
    opacity: 1;
   
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    img {
      width: 122px;
  height: 92px;
  
    }
  
    p {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      font-family: "PingFang";
    }
  }</style>