<template>
  <div class="wallet">
    <div class="function-box">
      <div @click="jump(0)"
           class="function-li">
        <img class="function-img"
             src="@/assets/img/recharge_img.png"
             mode="widthFix" />
        <span>{{ $t('lang.swap341') }}</span>
      </div>
      <div @click="jump(1)"
           class="function-li">
        <img class="function-img"
             src="@/assets/img/withdraw_img.png"
             mode="widthFix" />
        <span>{{ $t('lang.swap342') }}</span>
      </div>
      <div class="function-li"
           @click="gonavigate"
           style="  border-bottom: 0;">
        <img class="function-img"
             src="@/assets/img/bill_img.png"
             mode="widthFix" />
        <span>{{ $t('lang.swap351') }}</span>
      </div>
    </div>

    <div class="property-box">
      <div @click="showClick"
           class="property-left">
        <img v-show="show"
             class="show_img"
             src="@/assets/img/hide_img.png"
             mode="widthFix" />
        <img v-show="!show"
             class="show_img"
             src="@/assets/img/illustrate_img.png"
             mode="widthFix" />
        <span>{{ $t('lang.swap379') }}</span>
      </div>
      <div class="input-box">
        <img class="input_img"
             src="@/assets/img/search_img.png"
             mode="widthFix" />
        <van-field class="input"
                   v-model="search"
                   :placeholder="$t('lang.swap354')" />
      </div>
    </div>
    <div class="currency-box">
      <div class="caption">
        <span>{{ $t('lang.swap380') }}</span>
        <span>{{ $t('lang.swap381') }}</span>
      </div>
      <div v-for="(item,index) of currencyList"
           :key="index"
           class="currency-li">
        <div class="li-left">
          <img class="currency-img"
               :src=" image_domain + item.logo"
               mode="widthFix" />
          <span>{{item.coin_en}}</span>
        </div>
        <div class="li-right">
          <span>{{item.usable}}</span>
          <!-- <span class="worth">${{item.worth}}</span> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { goodApi } from '../../api/index';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      show: false,//是否隐藏
      search: '',
      currencyList: [
      ],
      image_domain: ''
    }
  },
  created () {
    this.init();
  },
  watch: {
    account () {
      //获取账号
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
  methods: {
    init () {
      if (this.account) {

        this.getConfig()
      }
    },
    getConfig () {
      goodApi.config(this.account).then(res => {
        this.image_domain = res.data.image_domain
        this.getCurrencyList();
      })
    },
    getCurrencyList () {
      goodApi.walletList(this.account).then(res => {
        this.currencyList = res.data
      });
    },
    jump (id) {
      // this.$toast(this.$t('lang.swap65'));
      // return
      this.$router.push({ name: 'Recharge', query: { id } });
    },
    goMine () {
      this.$router.push({ name: 'Mine', query: { id: 0 } });
    },
    gonavigate () {
      // this.$toast(this.$t('lang.swap65'));
      // return
      this.$router.push({ name: 'RechargeBill' });

    },
    showClick () {
      this.show = !this.show
    }
  }
}
</script>

<style lang="less" scoped>
.wallet {
  display: flex;
  flex-direction: column;
  height: 100%;
  .property-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 20px;
    .property-left {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #383838;
      .show_img {
        display: block;
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }
    }
    .input-box {
      width: 60%;
      display: flex;
      align-items: center;
      background: #f0f4f9;
      border-radius: 20px;
      padding: 4px 12px;
      .input_img {
        display: block;
        width: 12px;
        height: 14px;
        margin-right: 6px;
      }
      .input {
        flex: 1;
        font-size: 12px;
        background: #f0f4f900;
      }
    }
  }
  .currency-box {
    flex: 1;
    background: #fff;
    padding: 0 20px 20px;
    .caption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: bold;
      color: #3838389a;
    }
    .currency-li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 14px;
      .li-left {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        .currency-img {
          display: block;
          width: 24px;
          height: 24px;
          margin-right: 6px;
        }
      }
      .li-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        .worth {
          font-size: 10px;
          font-weight: normal;
          color: #00000093;

          transform: scale(0.9, 0.9);
        }
      }
    }
  }
  .function-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-radius: 30px 30px 0 0;
    padding: 30px 50px 20px;
    margin: 20px 0 14px;
    .function-li {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #000000;
      border-bottom: 1px solid #f9f9f9;
      padding: 30rpx 0;
      .function-img {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>