<template>
    <div class="index">
      <div class="functional_region">
        <div class="region">
          <div v-for="(item,index) of functionOption"
               :key="index"
               @click="switchovers(index)"
               :class="index ==regionIndex?'special':''">
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
  
      <div class="box">
        <router-view />
      </div>
    </div>
  </template>
  
  <script>
  import { basic } from '../../api/index';
  export default {
    data () {
      return {
        // 功能列表
        functionOption: [{ name: 'GDT' }, { name: 'VGT' }],
        regionIndex: '0',
      };
    },
    created () {
      this.getBasicList();
      this.regionIndex = this.$route.name == 'Good' ? '0' : '1';
    },
    mounted () {
  
    },
    methods: {
      switchovers (index) {
        this.regionIndex = index;
        if (this.regionIndex == '0') {
            console.log("good");
          this.$router.push({ name: 'Good' });
        } else {
            console.log("VGT");
          this.$router.push({ name: 'VGT' });
        }
      },
  
      getBasicList () {
  
        let data = {
          page: 1,
          page_size: 20,
        };
        basic.getNoticeList(data, '0x3D12Bd39bB936a73575ea97dFbf308b08b84e76B', "zh-cn").then(res => {
          if (res.code === 200) {
          }
        });
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .index {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .box {
    flex: 1;
  }
  .functional_region {
    padding: 20px 20px 0;
  
    .region {
      display: flex;
      width: 100%;
      line-height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: rgba(158, 156, 163, 1);
      font-weight: 600;
      background: #ffffff;
      border-radius: 34px;
      padding: 3px;
      border: 1px solid rgba(60, 114, 241, 1);
      margin-bottom: 20px;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        // width: 192rpx;
        padding: 16px 0;
      }
      .special {
        // width: 50%;
        color: #fff;
        background: #356ef1ff;
        // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
        border-radius: 34px;
      }
    }
  }
  /deep/ .router {
    background: #000 !important;
  }
  </style>