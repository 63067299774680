import request from '../utils/request';
export default {
    	//配置
	getConfig(address) {
		return request.request({
			url: '/vg/config',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},
    //统计
	goodSummary(address) {
		return request.request({
			url: '/vg/summary',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},
     //等级
     levelInfo(address) {
		return request.request({
			url: '/vg/level-info',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},
      //是否已参与拼团
	getisjoin(data,address) {
		return request.request({
			url: '/vg/is-join',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
            params:data
		})
	},
     //持有信息
     getHoldList(data,address) {
		return request.request({
			url: '/vg/hold-list',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
            params:data
		})
	},
     //参与拼团列表
     getJoinList(data,address) {
		return request.request({
			url: '/vg/join-list',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
            params:data
		})
	},
     //账单
     getBillList(data,address) {
		return request.request({
			url: '/vg/bill-list',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
            params:data
		})
	},
       //可拼团列表
       getMarketList(data,address) {
		return request.request({
			url: '/vg/market',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
            params:data
		})
	},
       //发起拼团
       postInitiate(data,address) {
		return request.request({
			url: '/vg/initiate',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
            data:data
		})
	},
      //拼团
      postJoin(data,address) {
		return request.request({
			url: '/vg/join',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
            data:data
		})
	},
     //团长券兑换VG8
     postExchangeGdb(data,address) {
		return request.request({
			url: '/vg/exchange-vgb',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
            data:data
		})
	},
    	//钱包列表
	walletList(address) {
		return request.request({
			url: '/reward/wallet-list',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},

	//获取平台配置
	config(address) {
		return request.request({
			url: '/index/config',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},

	//充值配置
	rechargeConfigs(address){
		return request.request({
			url: '/reward/recharge/configs',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},

    //入账标记
	entryMark(data, address) {
		return request.request({
			url: '/reward/entry-mark',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
			data
		})
	},

	//提币配置
	withdrawConfigs(address){
		return request.request({
			url: '/order/withdraw/configs',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},

	//提币
	withdraw(data,address){
		return request.request({
			url: '/order/withdraw',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
			data
		})
	},

	//等级
	levelInfo(address){
		return request.request({
			url: '/vg/level-info',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			}
		})
	},
}