
import exchangeAbi from '../config/abi/gdtExchange.json'
import Vue from 'vue';
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import store from '../store';
function dochange(amount, address) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance;
        crossingOkContractInstance = new web3.eth.Contract(exchangeAbi, '0x40d9b6295A2d3213C0126e8B71D082A4d4dA3092');
        crossingOkContractInstance.methods.doExchange(0, amount).send({ from: address })
            .on('transactionHash', res => {
            }).on('confirmation', res => {
                resolve(res)
            }).on('error', res => {
                reject(res)  //失败
            })
    })
}

export default {
    dochange
}

