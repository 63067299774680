import request from '../utils/request';

export default {
    inviteInfo(address, language) {
        return request.request({
            url: '/user-center/invite-info',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': language
            },
        })
    },
    inviteEdit(data, address, language) {
        return request.request({
            url: '/user-center/invite-edit',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': language
            },
            data
        })
    },

    userBind(data, address, language) {
        return request.request({
            url: '/user-center/bind',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': language
            },
            data
        })
    },

    //邀请好友列表
    inviteChildrenList(address, language) {
        return request.request({
            url: '/user-center/children-list',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': language
            },
        })
    },
    //邀请算力区
    inviteZoneCp(address, language) {
        return request.request({
            url: '/reward/zone-cp',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': language
            },
        })
    },

    activityJoin(data) {
        return request.request({
            url: '/activity/join',
            method: "post",
            headers: {
                'Show-Language':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    },
    usercenterrecommender(address, language) {
        return request.request({
            url: '/user-center/recommender',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': language
            },
        })
    },
}