import request from '../utils/request';

export default {
    //购买条件
    buyComdition(data, address) {
        return request.request({
            url: '/user-center/member/buy-condition',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    },

    //董事长成员
    memberList(address) {
        return request.request({
            url: '/user-center/member/list',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    },

    //奖励列表
    rewardList(address) {
        return request.request({
            url: '/reward/list',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },

        })
    },

    //购买会员
    buyMember(data, address) {
        return request.request({
            url: '/user-center/member/buy',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    },

    //获取tvl
    userTvl(address) {
        return request.request({
            url: '/user-center/user-tvl',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    },

    //添加tvl记录
    userTvlLog(address) {
        return request.request({
            url: '/user-center/lp-log',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    },

    //取出奖励
    rewardOut(data, address) {
        return request.request({
            url: '/order/reward-out',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    },

    //注册用户
    getAccount(data, address) {
        return request.request({
            url: '/index/get-account',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    },

    //联创   /v1/union/reward-info
    rewardInfo(address) {
        return request.request({
            url: '/union/reward-info',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    },

    rewardVgtInfo(address) {
        return request.request({
            url: '/union/vgt-info',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    },

    //配置
    pledgeConfigs(address) {
        return request.request({
            url: '/pledge/configs',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    },

    //持有信息
    pledgeHoldList(data, address) {
        return request.request({
            url: '/pledge/hold-list',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    },

    //gdb钱包
    pledgeGdbWallet(address) {
        return request.request({
            url: '/pledge/gdb-wallet',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    },

    //质押
    pledgeDo(data, address) {
        return request.request({
            url: '/pledge/do',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    },

    //入账
    pledgeMark(data, address) {
        return request.request({
            url: '/pledge/entry-mark',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    },

    //是否已购买
    pledgeIsBuy(address) {
        return request.request({
            url: '/pledge/is-buy',
            method: "get",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    },


    //质押
    pledgeDo(data,address){
        return request.request({
            url: '/pledge/do',
            method: "post",
            headers: {
                Authorization: address,
                'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            data
        })
    }
}