import request from '../utils/request';
export default {
    //公告列表
    getNoticeList(data,address,language) {
        return request.request({
            url: '/basic/notice/list',
            method: "get",
            headers:{
                Authorization : address,
                'Show-Language':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            params:data
            
        })
    },

    //获取公告详情
    getNoticeDetail(data,address,language){
        return request.request({
            url: '/basic/notice/detail',
            method: "get",
            headers:{
                Authorization : address,
                'Show-Language':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
            params:data
        })
    },
   
    basicnoticelatest(address,language){
        return request.request({
            url: '/basic/notice/latest',
            method: "get",
            headers:{
                Authorization : address,
                'Show-Language':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
            },
        })
    }
}