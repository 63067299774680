export const lang = {
  swap1: '閃兌',
  swap2: '立刻開始閃兌',
  swap3: '從',
  swap4: '餘額:',
  swap5: '選擇幣種',
  swap6: '最大',
  swap7: '價格',
  swap8: '滑點容差',
  swap9: '連接錢包',
  swap10: '輸入數量',
  swap11: '餘額不足',
  swap12: '此交易的流動性不足',
  swap13: '選擇代幣',
  swap14: '授權',
  swap15: '包裝',
  swap16: '解包',
  swap17: '閃兌',
  swap18: '交易收據',
  swap19: '正在等待確認',
  swap20: '正在將',
  swap21: '交換為',
  swap22: '在你的錢包種確認此交易',
  swap23: '已提交交易',
  swap24: '在區塊瀏覽器上查看',
  swap25: '關閉',
  swap26: '交易拒絕',
  swap27: '確認交換',
  swap28: '輸出為估值,您將獲得至少{inputOutMin} {symbol},或者交易將被撤',
  swap29: '輸入為估值,您將出售最多{inputInMax} {symbol},或者交易將被撤回',
  swap30: '最小獲得量',
  swap31: '最大出售量',
  swap32: '到',
  swap33: '管理代幣',
  swap34: '管理代幣',
  swap35: '導入',
  swap36: '管理Tokens',
  swap37: '自定義代幣',
  swap38: '全部清除',
  swap39: '搜索名稱或粘貼地址',
  swap40: '設定',
  swap41: '默認交易速度(GWEI)',
  swap42: '標準',
  swap43: '快速',
  swap44: '即時',
  swap46: '輸入有效的滑點百分比',
  swap47: '你的交易可能會失敗',
  swap48: '你的交易可能會被超前',
  swap49: '交易截止時間(分鐘)',
  swap50: '估計',
  swap51: '禁用多跳',
  swap52: '最近交易',
  swap53: '最近沒有交易',
  swap54: '全部清除',
  swap55: '未連接',
  swap56: '加入董事會',
  swap57: '捐贈',
  swap58: '權益贈送',
  swap59: '董事會成員列表',
  swap60: '邀請',
  swap61: '複製',
  swap62: '我的DAPP邀請人:',
  swap63: '邀請記錄',
  swap64: '出錯啦,請稍後再試',
  swap65: '功能暫未開放',
  swap66: '領取',
  swap67: '取出成功',
  swap68: '捐贈中，待確認',
  swap69: '已捐贈',
  swap70: '非白名單用戶',
  swap71: '捐贈無效',
  swap72: '當前餘額不足',
  swap73: '授權中，請稍等',
  swap74: '授權失敗',
  swap75: '捐贈中，請稍等',
  swap76: '捐贈失敗',
  swap77: '兌換',
  swap78: '流動性',
  swap79: '會員',
  swap80: '邀请',
  swap81: '權益池',
  swap82: '資產橋',
  swap83: '池子',
  swap84: '農場',
  swap85: '首頁',
  swap86: '市場',
  swap87: '兌換',
  swap88: '交易',
  swap89: '賺取',
  swap90: '確認',
  swap91: '價格影響',
  swap92: '最小獲得量',
  swap93: '流動性供應商費用',
  swap94: '增加流動性',
  swap95: '增加流動性以接收 LP 代幣',
  swap96: '流動性池中的份額',
  swap97: '兌換率和流動池份額',
  swap98: '您的流動性',
  swap99: '移除流動性以收回代幣',
  swap100: '未找到流動性',
  swap101: '未看到您加入的流動池？',
  swap102: '查看其他LP代幣',
  swap103: '已入池',
  swap104: '移除',
  swap105: '供應',
  swap106: '導入池',
  swap107: '導入現有流動性池',
  swap108: '選擇代幣以查找您的流動性',
  swap109: '您在該流動性池中還沒有提供流動性',
  swap110: '您的錢包中的LP代幣',
  swap111: '接收',
  swap112: '和',
  swap113: '金額',
  swap114: '你將獲得',
  swap115: '輸出為估值。如果價格變化超過 {slippage}%，則您的交易將被撤回',
  swap116: '已焚毀',
  swap117: '已入金',
  swap118: '確認供應',
  swap119: '匯率',
  swap120: '您是第一個流動性供應商。',
  swap121: '您添加代幣的比列，將决定該流動性池的初始價格。',
  swap122: '如果您對匯率滿意，請點擊“供應”以進行檢查。',
  swap123: '正在移除',
  swap124: '正在供應',
  swap125: '確認供應',
  swap126: '白皮書',
  swap127: '稽核',
  swap128: '公告',
  swap129: '提示',
  swap130: 'PHO 1:1兌換',
  swap131: '更多',
  swap132: '正在加載',
  swap133: '信息',
  swap134: '捐贈名額已滿',
  swap135: '我的PHO',
  swap136: '錢包中的PHO',
  swap137: '可收割的PHO',
  swap138: 'PHO價格',
  swap139: 'PHO總量',
  swap140: 'PHO市值',
  swap141: 'PHO信息',
  swap142: '已賺取',
  swap143: '質押',
  swap144: '個人TVL',
  swap145: '社區TVL',
  swap146: '收割',
  swap147: '累計激勵 ',
  swap148: '今日激勵',
  swap149: '參與市商地址數',
  swap150: '我的邀请链接',
  swap151: '最大算力區',
  swap152: '其他算力區',
  swap153: '我的好友邀請',
  swap154: '取消',
  swap155: '修改邀請人',
  swap156: '邀請人不能為空',
  swap157: '修改成功',
  swap158: '質押LP代幣',
  swap159: '贖回LP代幣',
  swap160: '贖回',
  swap161: '獲取',
  swap162: '當前暫無收益',
  swap163: '路由',
  swap164: '審計機構',
  swap165: '合作夥伴',
  swap166: '發行',
  swap167: '活動',
  swap168: '單幣池',
  swap169: 'LP池',
  swap170: '國會',
  swap171: '卡牌',
  swap172: '盲盒',
  swap173: '遊戲',
  swap174: '權益池已分配總額',
  swap175: '待領取數額',
  swap176: '我已獲取數額',
  swap177: '取出',
  swap178: '超級節點',
  swap179: '權益卡已領取份額',
  swap180: '國庫',
  swap181: '國庫累計金額',
  swap182: '國庫已分配金額',
  swap183: '國庫已回購金額',
  swap184: '專家模式',
  swap185: 'GoodSwap是基於OKExChain研發的全網首個股權稅收制Defi平臺，逐步推出Swap、NFT、Gamefi、Lend、機槍池等板塊。 問世以來通過領先的科技和公平公開的市場理念，提供更加廣泛的資產增值方案和前所未有的體驗感，深受廣大用戶喜愛。',
  swap186: '一鍵取出',
  swap187: '價格影響過高',
  swap188: 'FAQ',
  swap189: 'NFT',
  swap190: '賺幣',
  swap191: '進行中',
  swap192: '已結束',

  swap193: '說明',
  swap194: '股權權益生態卡，全球限量1680張',
  swap195: '點亮股權權益生態卡的要求及收益如下',
  swap196: '一星卡：個人地址擁有價值1000USDT流動性，團隊1000USDT流動性，收益共分交易稅收0.1%',
  swap197: '二星卡：個人擁有價值2000USDT流動性，團隊1萬USDT流動性，收益共分交易稅收0.1%',
  swap198: '三星卡：個人3000USDT流動性，團隊10萬USDT流動性，設立工作室，收益共分交易稅收0.1%',
  swap199: '四星卡：個人擁有價值4000USDT流動性，團隊100萬USDT流動性，設立工作室，收益共分交易稅收0.1%',
  swap200: '五星卡：個人5000USDT流動性，團隊1000萬USDT流動性，設立工作室，收益共分交易稅收0.1%',
  swap201: '備註：所有權益生態卡收益全部鏈上自動分配，權益卡享受交易所所有生態分紅，權益卡可傳承可交易可轉讓，滿足要求自動升級，不滿足要求自動降級及自動退出。',
  swap202: '權益卡',
  swap203: '發展路線圖：',
  swap204: '2022年7月2.0版上線',
  swap205: '2022年9月開通持幣多挖',
  swap206: '2022年12月NFT交易平臺上線',
  swap207: '2023年6月Goodbaby遊戲上線',
  swap208: '2023年9月Goodlend上線',
  swap209: 'GoodSwap介紹：',
  swap210: '滑點：',
  swap211: '分配：',
  swap212: '買入',
  swap213: '賣出',
  swap214: '0.5% LP分紅',
  swap215: '0.5%生態權益卡分紅',
  swap216: '0.25%回流底池銷毀LP',
  swap217: '0.25%生態建設',
  swap218: '暫無獎勵領取',
  swap219: '個人TVL',
  swap220: '團隊TVL',
  swap221: 'LP數量',
  swap222: '錢包中的pho',
  swap223: 'GDT',
  swap224: 'GDT空投累計獎勵',
  swap225: '星級用戶累計獎勵',
  swap226: '免手續費倒計時',
  swap227: '可取出獎勵',
  swap228: 'lp挖礦獎勵',
  swap229: '質押pho',
  swap230: '贖回pho',
  swap231: '目標共識',
  swap232: '已達共識',
  swap233: '已共識',
  swap234: '共識',
  swap235: '已解鎖',
  swap236: '可領取',
  swap237: '社區治理',
  swap238: '聯盟共識',
  swap239: '社區聯盟',
  swap240: '質押代幣',
  swap241: '質押中，請稍等',
  swap242: '交易挖礦',
  swap243: '昨日新增',
  swap244: '社區白名單累計獎勵',
  swap245: '股權星卡累計獎勵',
  swap246: 'LP挖礦累計獎勵',
  swap247: '當前可領取',
  swap248: '當前可領取金額',
  swap249: '交易挖礦',
  swap250: '我的分享獎勵累計',
  swap251: '我的交易獎勵累計',
  swap252: '請輸入您的交易雜湊',
  swap253: '校驗',
  swap254: '請輸入您的雜湊',
  swap255: '您的交易地址',
  swap256: '您將獲得',
  swap257: '僅買入GDT及創建流動性的交易可獲得獎勵',
  swap258: '質押GDT-PHO',
  swap259: '贖回GDT-PHO',
  swap260: '全網手續費累計',
  swap261: '待領取分紅',
  swap262: 'GDT聯合股東',
  swap263: '社交',
  swap264: '商城',
  swap265: '精選好物',
  swap266: '優選小店',
  swap267: '即將上線',
  swap268: 'VGT聯創股東',
  swap271: '推薦鏈接',
  swap272: '請先添加邀請人',
  swap273: '詳細',
  swap274: '元宇宙',
  swap275: '全局',
  swap276: '交換和流動性',
  swap277: '是基於OKExChain研發的全網首個股權稅',
  swap278: '收制Defi平台，逐步推出',
  swap279: '機槍池等板塊。面世以來通過領先的技術和公平公開的市場理念，提供更加廣泛的資產增值方案和前所未有的體驗感，深受廣大用戶喜愛。 ',
  swap280: '發展路線圖',
  swap281: '2022年7月 2.0版上線',
  swap282: '2022年9月 開通持幣多挖',
  swap283: '2022年12月 NFT交易平台上線',
  swap284: '2023年6月 Goodbaby遊戲上線',
  swap285: '2023年9月 Goodlend上線',
  swap286: '了解更多',
  swap287: '您已持有',
  swap288: '在',
  swap289: '交易加密數字貨幣',
  swap290: '質押流動性和代幣',
  swap291: '賺取分紅獎勵',
  swap292: '發射台',
  swap293: '認證',
  swap294: '優質加密數字資產',
  swap295: '了解',
  swap296: '藝術品',
  swap297: '交易市場',
  swap298: '瀏覽',
  swap299: '即時兌換代幣',
  swap300: '複製成功',
  swap301: '不支持複製，該瀏覽器不支持自動複製',
  swap302: '聯創累計獎勵',
  swap303: '累計分享獎勵',
  swap304: '累計交易獎勵',
  swap305: '資產包',
  swap306: '交易賺幣',
  swap307: '可取出餘額',
  swap308: '請輸入交易雜湊',
  swap309: '確定',
  swap310: '請輸入hash值',
  swap311: '入帳成功',

  swap312:'幣安',
  swap313:'平台',
  swap314:'PHO專區',
  swap315:'GDT專區',
  swap316:'VGT專區',
swap317:'PHO星級卡',
swap318:'個人',
swap319:'團隊',
swap320:'錢包中的',
swap321:'選擇資產包',
swap322:'請選擇',
swap323:'日收益率',
swap324:'收益比例',
swap325:'到期總數',
swap326:'購買',
swap327:'您的錢包餘額',
swap328:'我的資產包',
swap329:'今日總收益',
swap330:'今日靜態收益',
swap331:'今日動態收益',
swap332:'累計收益',
swap333:'到期數量',
swap334:'選擇',
swap335:'加密貨幣專區',
swap336:'購買成功',
swap337:'兌換數量',
swap338:'請輸入',
swap339:'空投',
swap340:'兌換成功',

swap341:'充值',
swap342:'提現',
swap343:'選擇通行',
swap344:'充值數量',
swap345:'請輸入充值數量',
swap346:'錢包餘額',
swap347:'可用餘額',
swap348:'提現數量',
swap349:'請輸入提現數量',
swap350:'選擇通證',
swap351:'賬單',
swap352:'暫無數據',
swap353:'GOOD購',
swap354:'搜索',
swap355:'編號',
swap356:'人數',
swap357:'操作',
swap358:'拼團',
swap359:'發團',
swap360:'個人中心',
swap361:'發團將消耗一張團長券，發團後無法撤銷，拼團成功後您將獲得豐厚獎勵',
swap362:'已持有團長券：{quantity}張',
swap363:'兌換資產包',
swap364:'餘額',
swap365:'兌換消耗團長票',
swap366:'餘額{quantity}張',
swap367:'拼團消耗',
swap368:'拼團編號',
swap369:'背包',
swap370:'已擁有團長票',
swap371:'已擁有商品票',
swap372:'我參與的拼團',
swap373:'角色',
swap374:'團員',
swap375:'拼團中',
swap376:'拼團失敗',
swap377:'拼團成功',
swap378:'資產',
swap379:'隱藏零資產',
swap380:'名稱',
swap381:'可用',
swap382:'團長',
swap383:'您持有{quantity}張',
swap384:'您沒有團長券',
swap385:'發團成功',
swap386:'拼團成功',
swap387:'Good購',
swap388:'Good Swap',
swap389:'拼團拿大獎',
swap390:'兌換數量不能為0',
swap391:'入帳中，請稍後',
swap392:'不足',
swap393:'提幣成功',
swap394:'我的拼團星級',
swap395:'拼單次數',
swap396:'下一等級',
swap397:'拼團消耗',
swap398:'不發團直接兌換',
swap399:'链游',
swap400:'更多板塊陸續登入...',
swap401:'已發放',
swap402:'選擇網路'
};
