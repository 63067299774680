<template>
  <div class="notice">
    <div class="title">
      <image src="@/static/game.png"
             class="notice_img" />
      <span>{{ obj.title }}</span>
    </div>
    <div class="time">
      <span>{{ dateFormat(new Date(obj.create_at), 'yyyy-MM-dd') }}</span>
    </div>
    <div class="notice_list">
      <div class="list">
        <div class="list_title">
          <span v-html="obj.description"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from '../../utils/format';
import { basic } from '../../api/index';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      obj: Object,
      language: '',
      newObj: Object,
    };
  },
  created () {
    this.obj = JSON.parse(this.$route.query.basic);
    // this.language = localStorage.getItem('lang');
    this.getBasic();
    this.watchLang();
  },
  watch: {
    language () {
      this.getBasic();
      // this.finished = false;
    },
  },
  components: {},
  methods: {
    watchLang () {
      let _this = this;
      window.addEventListener('setItemEvent', function (e) {
        if (e.key == 'lang') {
          // console.log("跑了几次");
          // _this.language = e.value
          _this.getBasic()
        }
      });
    },
    async getBasic () {
      this.language = await localStorage.getItem('lang');
      let data = {
        id: this.obj.id,
        group_id: this.obj.group_id,
      };
      if (this.language) {
        basic.getNoticeDetail(data, this.account, this.language).then(res => {
          if (res.code === 200) {
            this.obj = res.data;
          }
        });
      }
      // console.log(this.language,'this.language');

      // console.log(this.language,"当前语言我是详情");
    },
    dateFormat,
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.notice {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);
  margin: 18px 20px 16px;
  padding: 30px 10px 20px;
  .title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: Arial-Bold, Arial;
    font-weight: bold;
    color: #333333;
    .notice_img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .time {
    font-size: 10px;
    font-weight: 400;
    color: #999999;
    padding: 10px 0 0 26px;
  }
  .notice_list {
    .list {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      background: #f8f7f7;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.4);
      border-radius: 10px;
      padding: 12px 20px 16px;
      margin-top: 20px;
      .list_title {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        word-wrap: break-word;
        word-break: break-all;
      }
      .content {
        font-size: 10px;
        font-weight: 400;
        color: #666666;
        margin: 10px 0 16px;
      }
      .see {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        font-weight: 400;
        color: #999999;
        .button {
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          border-radius: 16px;
          border: 1px solid #3399ff;
          padding: 4px 16px;
        }
      }
    }
  }
}
</style>